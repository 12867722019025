import { Avatar, Box, Chip, Container, Paper, Toolbar } from '@mui/material';
import axios from "axios";
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InvoiceDataTable from './invoicestable';
import SecondaryAppBar from '../../common/secondary-app-bar';


function Invoices() {
    const { vendorIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/' + 17).then((res) => {
            console.log('console create user post api data is ', res.data)
            if (res.data) {
                enqueueSnackbar('Corporate Overview tab');
            }
        })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar("Error Message", error.response.data.error);
                    // alert("Error Message",error.response.data.error);
                }
            });
    }, []);


    return (

        <Container maxWidth="false" sx={{ bgcolor: 'rgb(243, 246, 249)', pt: 2, pb: 5 }}>
            {vendorIds.length > 0 ? (
                <React.Fragment>
                                <SecondaryAppBar>

                    <Toolbar disableGutters>
                        {vendorIds.map(vendor => <Chip key={'dba-' + vendor.dbaId} avatar={<Avatar>V</Avatar>} label={vendor.dbaName} sx={{ mr: 2 }} />)}
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </Toolbar>
                    <InvoiceDataTable />
                    </SecondaryAppBar>
                </React.Fragment>
            ) : (
                <Paper elevation={1}>Select a vendor to view dashboard</Paper>
            )}
        </Container>
    );
}

export default Invoices;