import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_QUARTER } from '../../../middleware/filter';

const quarters = [1, 2, 3, 4];

export default function FilterQuarter() {
    const dispatch = useDispatch();
    const selectedQuarter = useSelector(state => state.filter.quarter);
    const handleChange = (event) => dispatch({ type: SET_QUARTER, payload: parseInt(event.target.value) });
    return (
        <FormControl sx={{ minWidth: 120 }} size="small" variant="filled">
            <InputLabel id="quarter-select-small">Quarter</InputLabel>
            <Select
                labelId="quarter-select-small"
                id="quarter-select-small"
                value={selectedQuarter}
                label="Quarter"
                onChange={handleChange}
            >
                {quarters.map(quarter => <MenuItem key={'qtr' + quarter} value={quarter}>{'QTR ' + quarter}</MenuItem>)}
            </Select>
        </FormControl>
    );
}
