import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Grid, IconButton, Paper, Stack, Typography
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from "axios";
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { VectorMap } from 'react-jvectormap';
import { useSelector } from "react-redux";
import { modifyFiltersToOldFormat } from "../../../middleware/helper";
import stateRegionMapping from '../../../middleware/state-region-mapping.json';
import { FormatCurrency } from "../../common/formatter";
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const colorScale = ['#0D47A1', '#1565C0', '#1976D2', '#1E88E5', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB'];


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ float: 'right' }}>
            <GridToolbarExport
                printOptions={{ disableToolbarButton: true }}
                sx={{
                    backgroundColor: '#556cd6', '&:hover': {
                        backgroundColor: "#556cd6",
                    },
                    top: '-61px', right: '-25px', position: 'absolute'
                    , fontSize: '14px', color: '#fff', padding: '6px 12px', marginRight: '20px'
                }}
            />
        </GridToolbarContainer>
    );
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CategoryDetailsDialog({ open, handleClose }) {
    return (
        ''
    );
}
const handleCategoryDialogClose = (e) => {
}

// let stateRegionMapping1 = {
//   co: 'Central Region',
//   il: 'Central Region',
//   ks: 'Central Region',
//   mn: 'Central Region',
//   mo: 'Central Region',
//   ne: 'Central Region',
//   nd: 'Central Region',
//   sd: 'Central Region',
//   wi: 'Central Region',
//   wy: 'Central Region',
//   ia: 'Central Region',
//   in: 'Great Lakes Region',
//   ky: 'Great Lakes Region',
//   mi: 'Great Lakes Region',
//   oh: 'Great Lakes Region',
//   wv: 'Great Lakes Region',
//   // "western pa":"Great Lakes Region",
//   de: 'Mid-Atlantic Region',
//   md: 'Mid-Atlantic Region',
//   nj: 'Mid-Atlantic Region',
//   // "metropolitan ny":"Mid-Atlantic Region",
//   // "eastern pa":"Mid-Atlantic Region",
//   pa: 'Mid-Atlantic Region',
//   ct: 'Northeastern Region',
//   ma: 'Northeastern Region',
//   nh: 'Northeastern Region',
//   // "non-metropolitan ny":"Northeastern Region",
//   ny: 'Northeastern Region',
//   me: 'Northeastern Region',
//   ri: 'Northeastern Region',
//   vt: 'Northeastern Region',
//   al: 'Southeastern Region',
//   fl: 'Southeastern Region',
//   ga: 'Southeastern Region',
//   ms: 'Southeastern Region',
//   nc: 'Southeastern Region',
//   sc: 'Southeastern Region',
//   tn: 'Southeastern Region',
//   va: 'Southeastern Region',
//   tx: 'Southwestern Region',
//   ar: 'Southwestern Region',
//   la: 'Southwestern Region',
//   nm: 'Southwestern Region',
//   ok: 'Southwestern Region',
//   ak: 'Western Region',
//   az: 'Western Region',
//   hi: 'Western Region',
//   id: 'Western Region',
//   mt: 'Western Region',
//   nv: 'Western Region',
//   or: 'Western Region',
//   ca: 'Western Region',
//   wa: 'Western Region',
//   ut: 'Western Region',
// };


// let stateRegionMapping = {
//   Oregon: 'or',
//   Idaho: 'id',
//   Wyoming: 'wy',
//   Montana: 'mt',
//   Washington: 'wa',

//   'New Mexico': 'nm',
//   Alaska: 'ak',
//   Arizona: 'az',
//   Colorado: 'co',
//   Utah: 'ut',
//   Nevada: 'nv',
//   California: 'ca',

//   Louisiana: 'la',
//   Arkansas: 'ar',
//   Kansas: 'ks',
//   Oklahoma: 'ok',
//   Texas: 'tx',
//   Hawaii: 'hi',

//   Virginia: 'va',
//   'North Carolina': 'nc',
//   'South Carolina': 'sc',
//   Tennessee: 'tn',
//   Mississippi: 'ms',
//   Alabama: 'al',
//   Georgia: 'ga',
//   Florida: 'fl',

//   Maryland: 'md',
//   Delaware: 'de',
//   'New Jersey': 'nj',
//   Pennsylvania: 'pa',
//   'New York': 'ny',
//   Massachusetts: 'ma',
//   Connecticut: 'ct',
//   'New Hampshire': 'nh',
//   Vermont: 'vt',
//   Maine: 'me',
//   'Rhode Island': 'ri',

//   'North Dakota': 'nd',
//   Minnesota: 'mn',
//   'South Dakota': 'sd',
//   Nebraska: 'ne',
//   Iowa: 'ia',
//   Missouri: 'mo',
//   Kentucky: 'ky',
//   'West Virginia': 'wv',
//   Ohio: 'oh',
//   Michigan: 'mi',
//   Illinois: 'il',
//   Wisconsin: 'wi',
//   Indiana: 'in',
// };




const AdminOpportunitySummary = () => {
    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);
    const [summaryCard, setSummaryCard] = useState([])
    const [unapprovedSup, setUnapprovedSup] = useState([])
    const [vpvData, setVpvData] = useState([])
    const [unapprovedSupVol, setUnapprovedSupVol] = useState([])
    const [missedOpp, setMissedOpp] = useState([])
    const [volumeChartData, setVolumeChartData] = useState([])
    const [reabteChartData, setReabteChartData] = useState([])
    const [open, setOpen] = React.useState(false);
    const [volaxis1, setVolAxis1] = React.useState([]);
    const [volaxis2, setVolAxis2] = React.useState([]);
    const [rebaxis1, setRebAxis1] = React.useState([]);
    const [rebaxis2, setRebAxis2] = React.useState([]);
    const vectorMapRef = useRef(null);
    const [hardiRegionData, setHardiRegionData] = React.useState([
        { salesOpportunity: 26846695.28, state: "TX" }
    ]);
    const textInput = React.useRef(null);
    const textInput1 = React.useRef(null);
    const textInput2 = React.useRef(null);
    const textInput3 = React.useRef(null);
    const textInputfeedback = React.useRef(null);

    const [search, setSearch] = useState('');
    const [parentName, setParentName] = useState('');
    const [vendorName, setVendorName] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [openError, setOpenError] = useState(false);
    const [memberNameData] = useState();
    const [unapprovedSupplier] = useState();
    const [feedbacksuccessful, setFeedbackSuccessful] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageSize1, setPageSize1] = React.useState(10);
    const [pageSize2, setPageSize2] = React.useState(10);
    const [pageSize3, setPageSize3] = React.useState(10);
    const { enqueueSnackbar } = useSnackbar();




    const handleClickOpen = (vendorId, vendorName) => {
        console.log(vendorId, vendorName)
        setOpen(true);
        setVendorName(vendorName)
        setVendorId(vendorId)

    }
    const handleClose = () => { setOpen(false) };

    const volumedata = {
        x: 'month',
        columns: [
            ['month', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            ['axis1', ...volaxis1],
            ['axis2', ...volaxis2]
        ],
        types: {
            axis1: "line",
            axis2: "line",
        },
        names: {
            axis1: ' Volume($)',
            axis2: 'Volume Opportunity ($)',
        },
    }

    const rebatedata = {
        x: 'month',
        columns: [
            ['month', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            ['axis1', ...rebaxis1],
            ['axis2', ...rebaxis2]
        ],
        types: {
            axis1: "line",
            axis2: "line",
        },
        names: {
            axis1: ' Rebate($)',
            axis2: 'Rebate Opportunity ($)',
        },
    }

    const vpvDataColumn = [
        {
            field: 'vendorId',
            hide: 'true'

        },
        {
            field: 'vendorName',
            headerName: 'VP NAME',
            width: 350,

        },
        {
            field: 'volume',
            headerName: 'ACTUAL VOL',
            width: 150,
            renderCell: (params) => <FormatCurrency value={params.value} />

        },
        {
            field: 'salesOpportunity',
            headerName: 'VOL OPPORTUNITY',
            width: 150,
            renderCell: (params) => <FormatCurrency value={params.value} />
        }

    ]
    const unapprovedSupVolColumn = [
        {
            field: 'vendorId',
            headerName: '',
            width: 70,
            align: "left",
            renderCell: (params) => (
                <React.Fragment>
                    <IconButton sx={{ ml: 0.5 }} size="small"
                        onClick={() => handleClickOpen(params.row.vendorId, params.row.vendorName)}><OpenInNewIcon fontSize='inherit' /></IconButton>
                </React.Fragment>
            ),
        },
        {
            field: 'vendorName',
            headerName: 'UNAPPROVED SUPPLIER',
            width: 340,

        },
        {
            field: 'volume',
            headerName: 'VOLUME',
            width: 140,
            renderCell: (params) => <FormatCurrency value={params.value} />

        },
        {
            field: 'rebateOpportunity',
            headerName: 'OPPORTUNITY',
            width: 120,
            renderCell: (params) => <FormatCurrency value={params.value} />
        },


    ]
    const missedOppColumn = [
        {
            field: 'categoryName',
            headerName: 'CATEGORY',
            width: "400",
        },
        {
            field: 'rebateOpportunity',
            headerName: 'OPPORTUNITY',
            flex: 1,
            renderCell: (params) => <FormatCurrency value={params.value} />

        },
        {
            field: 'volume',
            headerName: 'VOLUME',
            flex: 1,
            renderCell: (params) => <FormatCurrency value={params.value} />
        },
        {
            field: 'marketShare',
            headerName: 'MARKET SHARE',
            flex: 1,
            renderCell: (cellValues) => {
                return "Coming Soon";
            }
        },


    ]
    const unapprovedSupColumn = [
        {
            field: 'memberId',
            hide: true

        },
        {
            field: 'memberName',
            headerName: 'MEMBER NAME',
            width: 290,

        },
        {
            field: 'state',
            headerName: 'STATE',
            width: 120,

        },
        {
            field: 'salesOpportunity',
            headerName: 'VOLUME',
            width: 150,
            renderCell: (params) => <FormatCurrency value={params.value} />
        },
        {
            field: 'rebateOpportunity',
            headerName: 'OPPORTUNITY',
            width: 150,
            renderCell: (params) => <FormatCurrency value={params.value} />
        },
    ]

    const summaryParams = modifyFiltersToOldFormat({
        year,
        month,
        period,
        quarter,
        dropDown: [],
        region: ''
    });
    const SummaryCardData = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesCards", summaryParams)
            .then((res) => {
                console.log('after scrch call')
                console.log('srchres', res)
                setSummaryCard(res.data)
            })
            .catch((err) => console.log('err', err));
    }
    const MemberSupplierData = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolumeByMember", summaryParams)
            .then((res) => {
                console.log('after scrch call')
                console.log('MemberSupplierData', res)
                setUnapprovedSup(res.data)
            })
            .catch((err) => console.log('err', err));
    }

    let summaryParams1 = modifyFiltersToOldFormat({
        year,
        month,
        period,
        quarter,
    });

    let params = modifyFiltersToOldFormat({
        year,
        month,
        period,
        quarter,
    });


    const onClickMemberData = (e) => {
        let memberNameData = e.row.memberName;

        summaryParams1 = { ...summaryParams1, dropDown: [{ "memberName": [memberNameData] }], region: "" };

        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolume", summaryParams1)
            .then(response => setUnapprovedSupVol(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post("/api/corporate/opportunity/getOpportunitiesMissedOpportunityByBhCategory", summaryParams1)
            .then(response => setMissedOpp(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post('/api/corporate/opportunity/getOpportunitiesMemberByHardiRegion', summaryParams1)
            .then(response => {
                return response.data.map(row => ({ stateAbb: `US-${String(row.stateAbb).toUpperCase()}`, salesOpportunity: row.salesOpportunity }));
            }).then(setHardiRegionData);
        axios.post("/api/corporate/opportunity/getOpportunitiesVendorPartnerVolume", summaryParams)
            .then(response => setVpvData(response.data.map((row, idx) => ({ ...row, id: idx }))))

    }

    const onClickUnapprovedSupplier = (e) => {
        let unapprovedSupplier = e.row.vendorName;

        let summaryParams2 = { ...summaryParams1, dropDown: [{ "unapprovedSupplier": [unapprovedSupplier] }], region: "" };

        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolumeByMember", summaryParams2)
            .then(response => setUnapprovedSup(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post("/api/corporate/opportunity/getOpportunitiesMissedOpportunityByBhCategory", summaryParams2)
            .then(response => setMissedOpp(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post('/api/corporate/opportunity/getOpportunitiesMemberByHardiRegion', summaryParams2)
            .then(response => {
                return response.data.map(row => ({ stateAbb: `US-${String(row.stateAbb).toUpperCase()}`, salesOpportunity: row.salesOpportunity }));
            }).then(setHardiRegionData);
        axios.post("/api/corporate/opportunity/getOpportunitiesVendorPartnerVolume", summaryParams)
            .then(response => setVpvData(response.data.map((row, idx) => ({ ...row, id: idx }))))

    }

    const onClickVendorPartnerVolume = (e) => {
        const vendorId = e.row.vendorId;
        const summaryParams2 = { ...summaryParams1, vendorId, region: "" };

        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolume", summaryParams2)
            .then(response => setUnapprovedSupVol(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolumeByMember", summaryParams2)
            .then(response => setUnapprovedSup(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post("/api/corporate/opportunity/getOpportunitiesMissedOpportunityByBhCategory", summaryParams2)
            .then(response => setMissedOpp(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post('/api/corporate/opportunity/getOpportunitiesMemberByHardiRegion', summaryParams2).then(response => {
            return response.data.map(row => ({ stateAbb: `US-${String(row.stateAbb).toUpperCase()}`, salesOpportunity: row.salesOpportunity }));
        }).then(setHardiRegionData);
    }

    const VpvData = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesVendorPartnerVolume", summaryParams)
            .then(response => setVpvData(response.data.map((row, idx) => ({ ...row, id: idx }))))
    }

    const unapprovedSupVolume = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolume", summaryParams)
            .then(response => setUnapprovedSupVol(response.data.map((row, idx) => ({ ...row, id: idx }))))
    }
    const missedOpportunityCat = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesMissedOpportunityByBhCategory", summaryParams)
            .then(response => setMissedOpp(response.data.map((row, idx) => ({ ...row, id: idx }))))
    }

    const volVsVolOppChart = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesVolVsVolOpp", summaryParams)
            .then((response) => {
                console.log('volume chart data', response.data)
                setVolumeChartData(response.data)
                const x = [];
                const y = [];
                response.data.forEach(item => {
                    x.push(item.axis1)
                    y.push(item.axis2)
                })
                setVolAxis1(x);
                setVolAxis2(y);
            })
    }


    const reabteVsRebateOppChart = async () => {
        await axios.post("/api/corporate/opportunity/getOpportunitiesRebateVsRebateOpp", summaryParams)
            .then((response) => {
                console.log('rebate chart data', response.data)
                setReabteChartData(response.data)
                const x1 = [];
                const y1 = [];
                response.data.forEach(item => {
                    x1.push(item.axis1)
                    y1.push(item.axis2)
                })
                setRebAxis1(x1);
                setRebAxis2(y1);
            })
    }

    // Changes for Hardi region 

    useEffect(() => {
        const params = modifyFiltersToOldFormat({ period, year, month, quarter });
        axios.post('/api/corporate/opportunity/getOpportunitiesMemberByHardiRegion', { ...params })
            .then(response => {
                return response.data.map(row => ({ stateAbb: `US-${String(row.stateAbb).toUpperCase()}`, salesOpportunity: row.salesOpportunity }));
            }).then(setHardiRegionData);
    }, [period, year, month, quarter]);

    let regionSales = {};
    hardiRegionData.forEach(row => {
        const regionName = stateRegionMapping[row.stateAbb];
        if (regionSales[regionName]) {
            const existingOpportunity = regionSales[regionName] + row.salesOpportunity;
            regionSales[regionName] = existingOpportunity;
        } else {
            regionSales[regionName] = row.salesOpportunity;
        }
    });

    let mapRegion = { scale: {}, values: {}, attribute: 'fill', normalizeFunction: 'linear' };
    Object.keys(stateRegionMapping).forEach(stateCode => {
        mapRegion.values[stateCode] = regionSales[stateRegionMapping[stateCode]];
    });
    Object.values(regionSales).sort((a, b) => b - a).forEach((value, index) => mapRegion.scale[value] = colorScale[index]);
    const series = { regions: [mapRegion] };

    const handleMouseEvent = (e, el, code) => {
        console.log(stateRegionMapping[code], e, el, code, "hiiiiiiiiiiiiiiiiiiiiiii")
        e.stopPropagation();

        el.html(`
            <div>${el.html()} - ${stateRegionMapping[code]}</div>
            <hr>
            <div>Opportunity: ${mapRegion.values[code] ? FormatCurrency({ value: mapRegion.values[code] }) : '-NA-'}</div>
        `);
    }

    const handleRegionClick = (e, el, code,) => {

        console.log(stateRegionMapping[el], e, el, code, "stateRegionMapping[code]stateRegionMapping[code]")

        let summaryParams3 = { ...params, region: stateRegionMapping[el] };
        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolume", summaryParams3)
            .then(response => setUnapprovedSupVol(response.data.map((row, idx) => ({ ...row, id: idx }))))

        axios.post("/api/corporate/opportunity/getOpportunitiesUnapprovedSupplierVolumeByMember", summaryParams3)
            .then(response => setUnapprovedSup(response.data.map((row, idx) => ({ ...row, id: idx }))))
        axios.post("/api/corporate/opportunity/getOpportunitiesMissedOpportunityByBhCategory", summaryParams3)
            .then(response => setMissedOpp(response.data.map((row, idx) => ({ ...row, id: idx }))))
    }


    //Hradi region chnages ended 
    useEffect(() => {
        SummaryCardData();
        MemberSupplierData();
        VpvData();
        unapprovedSupVolume();
        missedOpportunityCat();
        volVsVolOppChart();
        reabteVsRebateOppChart();
    }, [year, month, period, quarter]);

    const requestUnapprovedSupDataSearch = (e) => {
        const dataFilter = unapprovedSup.filter((val) => {
            if (e.target.value === "") {
                return val;
            } else if (
                val.memberName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return val;
            }
        });
        console.log("dataFilter", dataFilter);
        setUnapprovedSup(dataFilter);
    };

    const requestunappSupVolDataSearch = (e) => {
        const dataFilter = unapprovedSupVol.filter((val) => {
            if (e.target.value === "") {
                return val;
            } else if (
                val.vendorName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return val;
            }
        });
        console.log("dataFilter", dataFilter);
        setUnapprovedSupVol(dataFilter);
    }
    const requestMissedOppDataSearch = (e) => {
        const dataFilter = missedOpp.filter((val) => {
            if (e.target.value === "") {
                return val;
            } else if (
                val.categoryName.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
                return val;
            }
        });
        console.log("dataFilter", dataFilter);
        setMissedOpp(dataFilter);
    }

    const clearAll = () => {
        textInput.current.value = '';
        textInput1.current.value = '';
        textInput2.current.value = '';
        MemberSupplierData();
        VpvData();
        unapprovedSupVolume();
    }

    const clearAll1 = () => {

        missedOpportunityCat();
        textInput3.current.value = '';

    }

    const visibleRows = search ? vpvData.filter(row => String(row.vendorName).toLowerCase().includes(search.toLowerCase())) : vpvData;

    const handleFormSubmit = () => {
        // e.preventDefault();

        let data = {
            comments: "",
            source: "CORPORATE",
            suggestedSupplierId: null,
            suggestedSupplierName: parentName,
            supplier: "",
            supplierType: "PARENT",
            unapprovedSupplierId: null,
            unapprovedSupplierName: vendorName,

        }
        axios.post('api/corporate/feedback/insertFeedbackUnapprovedSupplierFeedback', JSON.stringify(data))
            .then(res => {
                if (res.status == 200) {
                    setFeedbackSuccessful(true);
                    enqueueSnackbar(res.data.message);
                    setOpen(false);
                    // return (<>

                    //   {/* <Snackbar open={setFeedbackSuccessful(true)} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setFeedbackSuccessful(false)}>
                    //     <Alert onClose={() => setFeedbackSuccessful(false)} severity="success" sx={{ width: '100%' }}>Feedback submitted Successfuly !!</Alert>
                    //   </Snackbar> */}

                    // </>)
                }
            })
            .catch(err => {

                enqueueSnackbar('Error while submitting feedback');
                setOpen(false)
            })


    }

    const handleClick = () => {
        enqueueSnackbar('I love snacks.');
    }

    const generateExportUnapprovedSuppVolByMember=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("unapprovedSuppVolByMember", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${unapprovedSup.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false,  showRowColHeaders:false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Unapproved Supplier Volume by Member";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["MEMBER NAME","STATE","VOLUME","OPPORTUNITY"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        worksheet.addImage(insertedLogo, "E1:F3");
        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
        const filterData= unapprovedSup.filter((row) => {
            if((row.rebateOpportunity>0 && row.salesOpportunity>0)) {
            return unapprovedSup
        }
          });
        
          filterData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.memberName, obj.state, 
                 '$'+Math.round(obj.salesOpportunity) ,'$'+Math.round(obj.rebateOpportunity)]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 25;
        worksheet.getColumn(3).width = 25;
        worksheet.getColumn(4).width = 25;
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Unapproved Supplier Volume by Member';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "unapprovedSuppVolByMember.xlsx");
        });
      
    }

    const generateExportVendorPartnerVolume=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("vendorPartnerVolume", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${visibleRows.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Vendor Partner Volume";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["VP NAME","ACTUAL VOLUME","VOL OPPORTUNITY"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");

        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
         
         const filterData= visibleRows.filter((row) => {
            if((row.volume>0 && row.salesOpportunity>0)) {
            return visibleRows
        }
          });
        
          filterData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.vendorName ,
                 '$'+Math.round(obj.volume) ,'$'+Math.round(obj.salesOpportunity)]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 25;
        worksheet.getColumn(3).width = 25;
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Vendor Partner Volume';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "vendorPartnerVolume.xlsx");
        });
    }

    const generateExportUnapprovedSuppVolume=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("unapprovedSuppVolume", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${unapprovedSupVol.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Unapproved Supplier Volume";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["UNAPPROVED SUPPLIER","VOLUME","OPPORTUNITY"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");
        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
      

        const filterData= unapprovedSupVol.filter((row) => {
            if((row.volume>0 && row.rebateOpportunity>0)) {
            return unapprovedSupVol
        }
          });
        
          filterData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.vendorName ,
                 '$'+Math.round(obj.volume) ,'$'+Math.round(obj.rebateOpportunity)]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Unapproved Supplier Volume';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "unapprovedSuppVolume.xlsx");
        });
    }

    const generateExportMissedOppByCategory=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("MissedOppByCategory", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${missedOpp.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Missed Opportunity by Category";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["CATEGORY","OPPORTUNITY","VOLUME","MARKET SHARE"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");
        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
         

        const filterData= missedOpp.filter((row) => {
            if((row.volume>0 && row.rebateOpportunity>0)) {
            return missedOpp
        }
          });
        
          filterData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.categoryName ,
                 '$'+Math.round(obj.rebateOpportunity) ,'$'+Math.round(obj.volume),(obj.marketShare == 'null' ?obj.marketShare:'Coming Soon')]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

               
            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(3).width = 50;
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Missed Opportunity by Category';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "MissedOppByCategory.xlsx");
        });
    }

    return (
        <><React.Fragment>
            {/* <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
        <Toolbar >
          <Typography variant="h5" sx={{ mr: 3 }}>
            Opportunity
          </Typography>
        </Toolbar>
        <Grid container spacing={2}>
          {summaryCard.map((item) => (
            <Grid item xs={3} md={3}>
              <OverviewCard
                key={item}
                title={item.cardName}
                value={item.total}
                yoy={item.yoy}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: '400px', padding: '20px' }} >
              <Typography variant="h6">Volume vs Volume Opportunity</Typography>
              <div>
                <C3Chart data={volumedata} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: '400px', padding: '20px' }} >
              <Typography variant="h6">Rebate vs Rebate Opportunity</Typography>
              <C3Chart data={rebatedata} />
            </Paper>
          </Grid>
        </Grid>
      </Paper> */}

            <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ height: '490px', padding: '2px' }} >
                            <Typography variant="h6">Missed Opportunity By HARDI Region</Typography>

                            <div style={{ height: 420 }}>
                                <VectorMap
                                    map={'us_aea'}
                                    ref={vectorMapRef}
                                    containerClassName="us-map"
                                    zoomButtons={false}
                                    zoomOnScroll={false}
                                    backgroundColor='transparent'
                                    series={series}
                                    regionsSelectable={false}
                                    regionStyle={{
                                        initial: {
                                            'fill': '#e4e4e4',
                                            'fill-opacity': 1,
                                            'stroke': 'none',
                                            'stroke-width': 0,
                                            'stroke-opacity': 0
                                        },
                                        hover: { 'fill-opacity': 0.2, 'cursor': 'pointer' }
                                    }}
                                    // onRegionTipShow={(e, el, code) => handleMouseEvent(e, el, code)}
                                    onRegionTipShow={function (e, el, code) {
                                        el.html(el.html());
                                    }
                                    }

                                    onRegionClick={(e, el, code) => handleRegionClick(e, el, code)}
                                    containerStyle={{ width: '100%', height: '100%' }}
                                />
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ height: 'auto', width: '100%', padding: '2px' }} >
                            <Typography variant="h6" style={{ marginRight: '5px', display: 'inline-block' }}>Unapproved Supplier Volume by Member</Typography>
                            <TextField inputRef={textInput} id="standard-basic" variant="standard" placeholder="Search" onChange={requestUnapprovedSupDataSearch} />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"><SearchIcon /></IconButton>
                            <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportUnapprovedSuppVolByMember()}><FileDownloadOutlinedIcon/>Export</Button>
                            <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
                            <Box sx={{ mt: 2, height: '420px' }}>
                                <DataGrid
                                    rows={unapprovedSup}
                                    columns={unapprovedSupColumn}
                                    onRowClick={onClickMemberData}
                                    getRowId={(row) => row.memberId}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    // components={{
                                    //     Toolbar: CustomToolbar,
                                    // }}
                                    // localeText={{
                                    //     toolbarExport: 'export'
                                    // }}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

            <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} >
                            <Typography variant="h6" style={{ marginRight: '30px', display: 'inline-block' }}>Vendor Partner Volume</Typography>
                            <TextField inputRef={textInput1} id="standard-basic" variant="standard" placeholder="Search" onChange={event => setSearch(event.target.value)} />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"><SearchIcon /></IconButton>
                            <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportVendorPartnerVolume()}><FileDownloadOutlinedIcon/>Export</Button>
                            <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
                            <Box sx={{ mt: 2, height: '400px' }}>
                                <DataGrid
                                    rows={visibleRows}
                                    onRowClick={onClickVendorPartnerVolume}
                                    columns={vpvDataColumn}
                                    pageSize={pageSize1}
                                    onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    disableSelectionOnClick
                                    density='compact'
                                    // components={{
                                    //     Toolbar: CustomToolbar,
                                    // }}
                                    // localeText={{
                                    //     toolbarExport: 'export'
                                    // }}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} >
                            <Typography variant="h6" style={{ marginRight: '5px', display: 'inline-block' }}>Unapproved Supplier Volume</Typography>
                            <TextField inputRef={textInput2} id="standard-basic" variant="standard" placeholder="Search" onChange={requestunappSupVolDataSearch} />
                            <IconButton type="submit" aria-label="search"><SearchIcon /></IconButton>  
                            <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportUnapprovedSuppVolume()}><FileDownloadOutlinedIcon/>Export</Button>
                            <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
                            <Box sx={{ mt: 2, height: '400px' }}>
                                <DataGrid
                                    rows={unapprovedSupVol}
                                    columns={unapprovedSupVolColumn}
                                    onRowClick={onClickUnapprovedSupplier}
                                    // getRowId={(row) => row.dbaId}
                                    // pageSize={10}
                                    // rowsPerPageOptions={[10]}
                                    pageSize={pageSize2}
                                    onPageSizeChange={(newPageSize) => setPageSize2(newPageSize)}
                                    rowsPerPageOptions={[10, 50, 100]}


                                    // checkboxSelection
                                    disableSelectionOnClick
                                    density='compact'
                                    // components={{
                                    //     Toolbar: CustomToolbar,
                                    // }}
                                    // localeText={{
                                    //     toolbarExport: 'export'
                                    // }}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "40%",
                                height: '50%',
                                maxWidth: 'none'
                            },
                        },
                    }}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Unapproved Supplier Feedback
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography variant='h6' sx={{ mb: 1 }}> Parent company for {vendorName}</Typography>

                        <Grid item xs={2} sx={{ mt: 8 }}>
                            <TextField inputRef={textInputfeedback} fullWidth id="standard-basic" layout="horizontal" variant="standard" placeholder="Enter Parent Vendor name" onChange={event => setParentName(event.target.value)} />

                        </Grid>
                        <Stack direction='row' spacing={4} sx={{ mt: 4, float: 'right' }}>
                            <Button color="primary" onClick={() => handleFormSubmit}
                                variant="contained" style={{ marginBottom: "0px" }}>
                                Submit
                            </Button>
                            <Button color="secondary" onClick={handleClose}
                                variant="contained" style={{ marginBottom: "0px" }}>
                                Cancel
                            </Button>
                        </Stack>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose}>Close</Button>
                    </DialogActions>
                </BootstrapDialog>


                <CategoryDetailsDialog open={open} handleClose={value => handleCategoryDialogClose(value)} />


                <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} sm={12}>
                            <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} >
                                <Typography variant="h6" style={{ marginRight: '30px', display: 'inline-block' }}>Missed Opportunity by Category</Typography>
                                <TextField sx={{ marginLeft: '460px' }} inputRef={textInput3} id="standard-basic" variant="standard" placeholder="Search" onChange={requestMissedOppDataSearch} />
                                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"><SearchIcon /></IconButton>
                                <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportMissedOppByCategory()}><FileDownloadOutlinedIcon/>Export</Button>
                                <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
                                <Box sx={{ mt: 2, height: '400px' }}>
                                    <DataGrid
                                        rows={missedOpp}
                                        columns={missedOppColumn}
                                        pageSize={pageSize3}
                                        onPageSizeChange={(newPageSize) => setPageSize3(newPageSize)}
                                        rowsPerPageOptions={[10, 50, 100]}

                                        disableSelectionOnClick
                                        density='compact'
                                        // components={{
                                        //     Toolbar: CustomToolbar,
                                        // }}
                                        // localeText={{
                                        //     toolbarExport: 'export',
                                        // }}

                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </React.Fragment> </>
    )
}

export default AdminOpportunitySummary;