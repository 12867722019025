import { Avatar, Box, Chip, Stack, Toolbar } from '@mui/material';
import axios from "axios";
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import ConversionOpportunities from './Opportunity';
import OpportunityByMember from './opportunityByMember';



function ConversionOpportunity() {
    const { vendorIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/' + 18).then((res) => {
            console.log('console create user post api data is ', res.data)
            if (res.data) {
                enqueueSnackbar('Corporate Overview tab');
            }
        })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar("Error Message", error.response.data.error);
                }
            });
    }, []);

    return (
        <PageContent ready={vendorIds.length > 0}>
            <React.Fragment>
                <SecondaryAppBar>
                    <Toolbar>
                        {vendorIds.map(vendor => <Chip key={'dba-' + vendor.dbaId} avatar={<Avatar>V</Avatar>} label={vendor.dbaName} sx={{ mr: 2 }} />)}
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <PageFilters />
                    </Toolbar>
                </SecondaryAppBar>
                <Box sx={{ width: "100%" }}>
                    <Stack direction='row' spacing={4} >
                        <Box sx={{ width: "50%" }}>
                            <OpportunityByMember />
                        </Box>
                        <Box sx={{ width: "50%" }}>
                            <ConversionOpportunities />

                        </Box>

                    </Stack>
                </Box>
            </React.Fragment>
        </PageContent>
    );
}

export default ConversionOpportunity;