import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import TextField from '@mui/material/TextField';

import { DataGrid,gridClasses,GridToolbarContainer ,GridToolbarExport} from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import axios from "axios";
import { useSelector } from "react-redux";
import { modifyFiltersToOldFormat } from "../../../middleware/helper";
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-51px',right:'-7px',position:'absolute'
    ,padding:'7px 12px',fontSize:'14px',color:'#fff',marginRight:'10px'}}
      />
    </GridToolbarContainer>
  );
}


// .MuiDataGrid-columnHeadersInner MuiDataGrid-columnHeadersInner--scrollable css-1s0hp0k-MuiDataGrid-columnHeadersInner{

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
        
      },
    },
  },
}));


function formatDate(date) {
 
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');

 
}

const AdminComplianceSummary = () => {
  const { period, year, month, quarter } = useSelector((state) => state.filter);
  const [memberData, setMemberData] = useState([])
  const [vendorData, setVendorData] = useState([])
  const [memberDatabackup, setMemberDataBackup] = useState([])
  const [vendorDatabackup, setVendorDataBackup] = useState([])
  const textInput = React.useRef(null);
  const textInput1 = React.useRef(null);
  const [pageSize, setPageSize] = React.useState(20);

  const [pageSize1, setPageSize1] = React.useState(20);


  const requestMemberDataSearch = (e) => {
    const dataFilter = memberData.filter((val) => {
      console.log("test row", rows);
      if (e.target.value === "") {
        return val;
      } else if (
        val.Name.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return val;
      }
    });
    console.log("dataFilter", dataFilter);
    setMemberData(dataFilter);
  };
  const requestVendorDataSearch = (e) => {
    const dataFilter = vendorData.filter((val) => {
      console.log("test row", rows);
      if (e.target.value === "") {
        return val;
      } else if (
        val.Name.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return val;
      }
    });
    console.log("dataFilter", dataFilter);
    setVendorData(dataFilter);
  };

  const memberColumns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'Name',
      headerName: 'NAME',
      width: 200,
    },
    {
      field: 'groupName',
      headerName: 'COMPANY NAME',
      width: 300,

    },
    {
      field: 'complianceStatus',
      headerName: 'COMPLIANCE STATUS',
      width: 200,
    },
    {
      field: 'lastTransactionUpload',
      headerName: 'LAST TRANSACTION FILE UPLOAD',
      width: 150,
      align: "right",
      renderCell: (params) => 
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    {
      field: 'lastSource',
      headerName: 'LAST SOURCE',
      width: 120,
    },
    {
      field: 'firstTransactionUpload',
      headerName: 'FIRST TRANSACTION DATE',
      width: 150,
      align: "right",
      renderCell: (params) => 
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    {
      field: 'dataMaxDate',
      headerName: 'LAST TRANSACTION DATE',
      width: 100,
      align: "right",
      renderCell: (params) =>
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    {
      field: 'loginCount',
      headerName: 'NO OF TIME DASHBOARD LOGIN',
      width: 200,
      align: "right",
    },
    {
      field: 'email',
      headerName: 'EMAIL ID',
      width: 200,
    }
  ]

  const vendorColumns = [
    { field: 'Name', headerName: 'NAME', width: 180 },
    { field: 'groupName', headerName: 'COMPANY NAME', width: 180 },
    { field: 'complianceStatus', headerName: 'COMPLIANCE STATUS', width: 200 },
    {
      field: 'lastTransactionUpload', headerName: 'LAST TRANSACTION FILE UPLOAD', width: 200
      , align: "right",
       renderCell: 
       (params) => 
       (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    { field: 'lastSource', headerName: 'LAST SOURCE', width: 110 },
    {
      field: 'dataMinDate', headerName: 'FIRST TRANSACTION DATE', width: 200
      , align: "right", renderCell: (params) => 
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    {
      field: 'dataMaxDate', headerName: 'LAST TRANSACTION DATE', width: 200,
      align: "right", renderCell: (params) => 
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    {
      field: 'lastCatalogUpload', headerName: 'LAST PRODUCT CATALOG UPLOAD', width: 200
      , align: "right", renderCell: (params) =>
      (params.value == null) ?  (params.value) : formatDate(params.value)
    },
    { field: 'loginCount', headerName: 'NO OF TIME DASHBOARD LOGIN', width: 150, align: "right" },
    { field: 'email', headerName: 'EMAIL ID', width: 200 },


  ]

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,

    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,

    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 110,

    }
  ];

  const rows = [

  ];



  // const MemberData=async()=>{ 
  //   await axios.post("/api/corporate/compliance/getComplianceMemberDetails", params )
  //       .then(response => setMemberData(response.data.map((row, idx) => ({...row, id: idx})))
  //       ,console.log('response',memberData))
  // }
  // const VendorData=async()=>{ 
  //   await axios.post("/api/corporate/compliance/getComplianceVendorPartnerDetails", params )
  //       .then(response => setVendorData(response.data.map((row, idx) => ({...row, id: idx})))
  //       ,console.log('response',vendorData))
  // }

  //     useEffect(() => {

  //       MemberData();
  //       VendorData();
  //  }, []);
  useEffect(() => {
    let params = modifyFiltersToOldFormat({ year, month, period, quarter, dropDown: [] });
    axios.post("/api/corporate/compliance/getComplianceMemberDetails", params)
      .then(response =>
         setMemberData(response.data.map((row, idx) => ({ ...row, id: idx })),
         setMemberDataBackup(response.data.map((row, idx) => ({ ...row, id: idx }))))
        , console.log('response', memberData)
        )
    axios.post("/api/corporate/compliance/getComplianceVendorPartnerDetails", params)
      .then(response => setVendorData(response.data.map((row, idx) => ({ ...row, id: idx })),
      setVendorDataBackup(response.data.map((row, idx) => ({ ...row, id: idx })))),

        console.log('response', vendorData)
        )
  }, [year, month, period, quarter]);

  const clearAll = () =>
  {
    textInput.current.value = '';

    setMemberData(memberDatabackup);
  }

  const clearAll1 = () =>
  {
    textInput1.current.value = '';

    setVendorData(vendorDatabackup)
  }
  const generateExportMemberDetails=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("MemberDetails", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${memberData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Member Details";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["NAME","COMPANY NAME","COMPLIANCE STATUS","LAST TRANSACTION FILE UPLOAD","LAST SOURCE",
  "FIRST TRANSACTION DATE","LAST TRANSACTION DATE","NO OF TIME DASHBOARD LOGIN","EMAIL ID"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
     

  
    
      memberData.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.Name ,obj.groupName,obj.complianceStatus,(obj.lastTransactionUpload==null?(obj.lastTransactionUpload) : formatDate(obj.lastTransactionUpload)),
        (obj.lastSource ==null?'':obj.lastSource),(obj.firstTransactionUpload==null?obj.firstTransactionUpload:formatDate(obj.firstTransactionUpload)),(obj.dataMaxDate==null?obj.dataMaxDate:formatDate(obj.dataMaxDate)),
       (obj.loginCount),(obj.email)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

           
        });
    });
  
    
  
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 12;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 40;

  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Member Details';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "MemberDetails.xlsx");
    });
}

const generateExportVendorDetails=()=>{
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("VendorDetails", {
      pageSetup: {
          paperSize: 9,
          orientation: 'landscape',
          printArea: `A1:F${vendorData.length + 4}`,
          printTitlesRow: '1:4',
          horizontalCentered: true,
          showGridLines: false
      },
      // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
      views: [{  showGridLines: false, showRowColHeaders: false }],

      headerFooter: {
          oddFooter: "Page &P of &N"
      }
  });

  // Add Image
  const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
  const title = "BLUE HAWK Vendor Partner Details";
  const title1 = `Based on collective Purchasing Survey data: ${year}`;
  const str = title + "\n" + title1;
  const sheetHeaderRow = ["NAME","COMPANY NAME","COMPLIANCE STATUS","LAST TRANSACTION FILE UPLOAD","LAST SOURCE",
"FIRST TRANSACTION DATE","LAST TRANSACTION DATE","LAST PRODUCTION CATALOG UPLOAD","NO OF TIME DASHBOARD LOGIN","EMAIL ID"];

  const titleRow = worksheet.addRow([str]);
  titleRow.eachCell((cell, number) => {
      cell.alignment = { wrapText: true, vertical: 'middle' };
  });
  titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
  worksheet.addRow([]);
  // Add Image in "E1:F2" cell range
  // worksheet.addImage(insertedLogo, {
  //     tl: { col: 4.2, row: 0.2 },
  //     br: { col: 5.8, row: 1.8 }
  // });
  worksheet.addImage(insertedLogo, "E1:F3");
  worksheet.mergeCells("A1:D2");
  worksheet.mergeCells("E1:F2");
  worksheet.addRow([]);


  // Add Header Row
  const headerRow = worksheet.addRow(sheetHeaderRow);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
      cell.fill = {
          type: "pattern",
          pattern: "",
          fgColor: { argb: "FFAA00" },
          bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thick" },
          right: { style: "thin" },
      };
      // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

  });
   


  
      vendorData.forEach(function (obj) {
      let addedRow = worksheet.addRow([obj.Name ,obj.groupName,obj.complianceStatus,(obj.lastTransactionUpload==null?(obj.lastTransactionUpload) : formatDate(obj.lastTransactionUpload)),
      (obj.lastSource ==null?'':obj.lastSource),(obj.dataMinDate==null?obj.dataMinDate:formatDate(obj.dataMinDate)),(obj.dataMaxDate==null?obj.dataMaxDate:formatDate(obj.dataMaxDate)),
      (obj.lastCatalogUpload==null?obj.lastCatalogUpload:formatDate(obj.lastCatalogUpload)),(obj.loginCount),(obj.email)]);
      addedRow.eachCell((cell, number) => {
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
          };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

         
      });
  });

  

  worksheet.getColumn(1).width = 25;
  worksheet.getColumn(2).width = 30;
  worksheet.getColumn(3).width = 30;
  worksheet.getColumn(4).width = 25;
  worksheet.getColumn(5).width = 10;
  worksheet.getColumn(6).width = 25;
  worksheet.getColumn(7).width = 25;
  worksheet.getColumn(8).width = 25;
  worksheet.getColumn(9).width = 10;
  worksheet.getColumn(10).width = 30;


  // Add some meta data in excel file
  workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
  workbook.company = 'Tredence Inc.';
  workbook.category = 'Vendor Partner Details';

  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "VendorDetails.xlsx");
  });
}
  return (
    <><React.Fragment>
      <Paper elivation={1} sx={{ p: 2, mb: 3, mt: 3 }}>
        {/* <Grid container spacing={12} sx={{ p: 2 }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} > */}
              <Typography variant="h6" style={{ marginRight: '30px', display: 'inline-block' }}>Member Details</Typography>
              <TextField inputRef={textInput} onChange={requestMemberDataSearch} id="standard-basic" variant="standard" placeholder="Search" sx ={{marginLeft:'800px'}}/>
              
              <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportMemberDetails()}><FileDownloadOutlinedIcon/>Export</Button>
                            <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
              {/* <input onChange={requestMemberDataSearch} type="text" placeholder="Search" style={{ float: 'right', marginRight: '50px', width: '300px', height: '35px', fontSize: '16px' }}></input> */}

              <Box sx={{ mt: 2, height: '600px' }}>
                <StripedDataGrid
                  rows={memberData}
                  columns={memberColumns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                            pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
          rowsPerPageOptions={[20,50,100]} 
                  density='compact'
                  disableSelectionOnClick
                  // components={{
                  //   Toolbar: CustomToolbar,
                  //  }}
                  //  localeText={{
                  //   toolbarExport: "export"
                  // }}
                />
              </Box>
            {/* </Paper>
          </Grid>
        </Grid> */}
      </Paper>

      <Paper elivation={1} sx={{ p: 2, mt: 6 }}>
        {/* <Grid container spacing={12} sx={{ p: 2 }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} > */}
              <Typography variant="h6" style={{ marginRight: '30px', display: 'inline-block' }}>Vendor Partner Details</Typography>
              <TextField inputRef={textInput1} onChange={requestVendorDataSearch} id="standard-basic" variant="standard" placeholder="Search" style={{marginLeft: '800px'}}/>
              <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportVendorDetails()}><FileDownloadOutlinedIcon/>Export</Button>
              <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
              {/* <Button variant="contained" sx={{ float: 'right',marginRight:'120px' }} onClick={() => clearAll1()}>Clear All</Button> */}
              {/* <input onChange={requestVendorDataSearch} type="text" placeholder="Search" style={{ float: 'right', marginRight: '50px', width: '300px', height: '35px', fontSize: '16px' }}></input> */}
              <Box sx={{ mt: 2, height: '600px' }}>
                <DataGrid
                  rows={vendorData}
                  columns={vendorColumns}
                            pageSize={pageSize1}
          onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}    
          rowsPerPageOptions={[20,50,100]} 
                  disableSelectionOnClick
                  density='compact'
                  // components={{
                  //   Toolbar: CustomToolbar,
                  //  }}
                  //  localeText={{
                  //   toolbarExport: "export"
                  // }}
                />
              </Box>
            {/* </Paper>
          </Grid>
        </Grid> */}
      </Paper>

    </React.Fragment> </>
  )
}

export default AdminComplianceSummary;