import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Toolbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OverviewCard from "../../common/overview-card";
import  {YoyChip } from '../../common/formatter';


export default function AdminOverviewSummary() {

    const { period, year, month, quarter } = useSelector((state) => state.filter);
    const [data, setData] = useState({});
    const [rebateTypeList, setRebateTypeList] = useState([]);
    const [rebateType, setRebateType] = useState(0);

    useEffect(() => {
        axios.post("/api/member/overview/getRebateNames", { year }).then(response => {
            setRebateTypeList(response.data);
        });
    }, [year]);

    useEffect(() => {
        let params = { year, month, period, quarter, source: "Q" };
        if (rebateType) {
            params = { ...params, rebateType };
        }
        axios.post("/api/corporate/overview/summary", { ...params }).then(response => response.data).then(setData);
       
    }, [period, year, month, quarter, rebateType]);
    console.log('summary data',data)
    const setRebate = (val) => {
        // setRebateType(val === 'Guaranteed' ? 'Quarterly Flat' : val);
        setRebateType(val)
    }

    return (
        <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
            <Toolbar disableGutters sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 3 }}>
                    Overall Volume and Rebate ( VP Verified data )
                </Typography>
                <FormControl>
                    <InputLabel>Select Program Type</InputLabel>
                    <Select onChange={(event) => setRebate(event.target.value)} labelId="rebate-select" id="rebate-select" value={rebateType} label="Select Program Type" sx={{ minWidth: 250 }} size="small">
                        <MenuItem value={0}>All</MenuItem>
                         {/* {rebateTypeList.map((rebate) => <MenuItem key={rebate} value={rebate}>{rebate === 'Quarterly Flat' ? 'Guaranteed' : rebate}</MenuItem>)}  */}
                        {rebateTypeList.map((rebate) => <MenuItem key={rebate} value={rebate}>{rebate}</MenuItem>)}
                    </Select>
                </FormControl>
            </Toolbar>
            <Grid container spacing={2} columns={4}>
                <Grid item xs={1}>
                    <OverviewCard title="Total Transactions" value={data?.sales}  yoy={data?.salesYoy} updateDate={data?.updateDate} />
                </Grid>
                <Grid item xs={1}>
                <OverviewCard title="Total Rebate Received" value={data?.rebate} yoy={data?.rebateYoy} updateDate={data?.updateDate} />
                </Grid>
                <Grid item xs={1}>
                    <OverviewCard title="Total Dividend Payout" value={data?.dividendPayout} yoy={data?.dividendPayoutYoy} updateDate={data?.updateDate} />

                </Grid>
                <Grid item xs={1}>
                    <OverviewCard title="Total 5% HoldBack" value={data?.holdback} yoy={data?.holdbackYoy} updateDate={data?.updateDate} />

                </Grid>
            </Grid>
        </Paper>
    );
}
