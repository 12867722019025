import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack,Button} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useParams } from 'react-router-dom';
import { FormatCurrency } from '../../common/formatter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function PurchaseOrderTable({ memberId }) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  let { groupIds } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [tableData, setTableData] = useState([])
  const [rows, setRows] = React.useState(tableData);

  // const [value2, setValue] = React.useState('2023-01-01');
  // const [value3, setValue1] = React.useState('2023-05-31');
  const [value2, setValue] = React.useState('2022-12-31');
  const [value3, setValue1] = React.useState('2023-12-31');
  const { period, year, month, quarter, memberIds } = useSelector(state => state.filter);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('asc');
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);
  const [vpType, setVpType] = React.useState('');
  const [vpId,setVpId]=React.useState('');
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);

  const [searched, setSearched] = React.useState("");
  const [branchList, setBranchList] = React.useState([]);
  //const [memberIds, setMemberIds] = React.useState(groupIds.split(',').map(id => parseInt(id)));


  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  const loadData = async () => { // funtion to call post invoice api. and get data
    if (localStorage.getItem('authToken')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
    }
    let postParamsData = { memberId: memberIds.map(row => row.dbaId) };
    let currentDate = new Date().toISOString().slice(0, 10).split('/').reverse().join('-');
    var date = new Date();
    console.log(currentDate)


    postParamsData.startDate = value2;
    postParamsData.endDate = value3;
    postParamsData.poNumber = searched;
    // if (vpName != "" && vpType != "") {
    //   postParamsData.dropDown = [{ vpName: [vpName], vpType: [vpType] }];
    // }
    if (vpName != "" && vpType != "") {
      postParamsData.dropDown = [{  vpType: [vpType] }];
      postParamsData.vendorId= vpName
    }
    else if (vpName != "") {
      postParamsData.dropDown = [];
      postParamsData.vendorId= vpName
    }
    // else if (vpName != "") {
    //   postParamsData.dropDown = [{ vpName: [vpName] }];
    // }
    else if (vpType != "") {
      postParamsData.dropDown = [{ vpType: [vpType] }];
    }
    else {
      postParamsData.dropDown = [];

    }

    postParamsData.startRowNo = 0
    postParamsData = clean(postParamsData);
    console.log('check postParamsDat', postParamsData)
    // postParamsData = modifyFiltersToOldFormat1(postParamsData);

    await axios.post('/api/member/po/getPurchasedOrdersData', postParamsData
    )
      //.then((res) => {
      //createData(res.data)
      //.then((res) => (res.data).json())
      .then((res) => {
        setTableData(res.data)
        setRows(res.data)
        //.then((res) => setRows(res.data))

        console.log('initial post data ', tableData)
        console.log('initial post data ', rows)


      })
  }

  useEffect(() => {
    setLoading(true);
    const params = ({ year, memberId: memberIds.map(row => row.dbaId) });
    Promise.all([
      axios.post('/api/common/getActiveVendorList', { ...params }),

    ]).then(response => {
      setLoading(false);
      setVpNames(response[0].data);

    });
  }, [year]);


  useEffect(() => {
    loadData();
  }, [vpName, vpType, value2, value3])

  const columns = [
    {
      name: 'purchaseOrderNumber', label: 'PO NUMBER', minWidth: 80, options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'poDate', label: 'PO DATE', minWidth: 60, options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'vendorName',
      label: 'VENDOR NAME',
      minWidth: 170,
      align: 'right',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'vendorInd',
      label: 'VP / UNAPPROVED SUPPLIERS',
      minWidth: 70,
      align: 'right',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'productDesc',
      label: 'PRODUCT DESCRIPTION',
      minWidth: 200,
      align: 'right',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'amount',
      label: 'AMOUNT ($)',
      minWidth: 170,
      align: 'right',
      options: {

        customBodyRender: value => <span>{<FormatCurrency value={value} />}</span>,
        filter: true,
        sort: true

      }
    },
  ];

  function showDocumentStatusInfo() {
    console.log('column info is printed');
  }

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: false, // <===== will turn off checkboxes in rows
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
  ];

  const generateExport=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("PurchaseOrder", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${tableData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Purchases order";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["PO NUMBER","PO DATE","VENDOR NAME","VP / UNAPPROVED SUPPLIERS",
    "PRODUCT DESCRIPTION","AMOUNT ($)"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
    
    
    tableData.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.purchaseOrderNumber, obj.poDate, obj.vendorName,obj.vendorInd,obj.productDesc,
             '$'+Math.round(obj.amount) ]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 18;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 15;
 
   
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Purchases order';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "PurchaseOrder.xlsx");
    });
  }
  const handlePOrder=(event)=>{
    setVpName(event.target.value)
     console.log('326',event.target.value)
  }

  return (
    <Box>
      <Stack direction='row' spacing={4} marginBottom='10' >


        <FormControl>

          <InputLabel>Select Supplier</InputLabel>
          <Select
            labelId="vp-name-select-small"
            id="vp-name-select-small"
            value={vpName}
            label="Select Supplier"
            sx={{ minWidth: 450, marginBottom: 2, minHeight: 55 }}
            // onChange={(event) => setVpName(event.target.value)}
            onChange={handlePOrder}
            size='small'
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {vpNames.map(vp => (<MenuItem key={vp.vendorId} value={vp.vendorId}>{vp.vendorName}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Select Vendor Type</InputLabel>
          <Select
            labelId="vp-type-select-small"
            id="vp-type-select-small"
            value={vpType}
            label="Select Vendor Type"
            sx={{ minWidth: 300, marginBottom: 2, minHeight: 55 }}
            onChange={(event) => setVpType(event.target.value)}
            size='small'
          >
            <MenuItem key={0} value={'0'}>{'Unapproved Supplier'}</MenuItem>
            <MenuItem key={1} value={'1'}>{'Vendor Partner'}</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={value2}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <p>to</p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            value={value3}
            onChange={(newValue1) => {
              setValue1(newValue1);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button variant="contained" sx={{ ml: 2,height:'42px' }} onClick={() => generateExport()}><FileDownloadOutlinedIcon/>Export</Button>

      </Stack>

      <CacheProvider value={muiCache}>
        <ThemeProvider theme={createTheme()}>

          <MUIDataTable
            title={"Purchases order"}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </CacheProvider>
    </Box>
  );
}

