import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { VectorMap } from 'react-jvectormap';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import stateRegionMapping from '../../../middleware/state-region-mapping.json';
import { FormatCurrency } from '../../common/formatter';

const colorScale = ['#0D47A1', '#1565C0', '#1976D2', '#1E88E5', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB'];

export default function Map({ stateCodes, setStateCodes }) {

    const vectorMapRef = useRef(null);
    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);
    const [hardiRegionData, setHardiRegionData] = React.useState([]);

    useEffect(() => {
        const params = modifyFiltersToOldFormat({ period, year, month, quarter, vendorId: vendorIds.map(row => row.dbaId) });
        axios.post('/api/vendor/opportunity/getOpportunityVolumeOpportunityByHardiRegion', { ...params }).then(response => {
            return response.data.map(row => ({ state: `US-${String(row.state).toUpperCase()}`, opportunity: row.opportunity }));
        }).then(setHardiRegionData);
    }, [period, year, month, quarter, vendorIds]);

    let regionSales = {};
    hardiRegionData.forEach(row => {
        const regionName = stateRegionMapping[row.state];
        if (regionSales[regionName]) {
            const existingOpportunity = regionSales[regionName] + row.opportunity;
            regionSales[regionName] = existingOpportunity;
        } else {
            regionSales[regionName] = row.opportunity;
        }
    });

    let mapRegion = { scale: {}, values: {}, attribute: 'fill', normalizeFunction: 'linear' };
    Object.keys(stateRegionMapping).forEach(stateCode => {
        mapRegion.values[stateCode] = regionSales[stateRegionMapping[stateCode]];
    });

    Object.values(regionSales).sort((a, b) => b - a).forEach((value, index) => mapRegion.scale[value] = colorScale[index]);
    const series = { regions: [mapRegion] };

    const handleMouseEvent = (e, el, code) => {
        el.html(`
            <div>${el.html()} - ${stateRegionMapping[code]}</div>
            <hr>
            <div>Opportunity: ${mapRegion.values[code] ? FormatCurrency({ value: mapRegion.values[code] }) : '-NA-'}</div>
        `);
    }

    const handleRegionClick = (e, el, code) => {
        const selectedRegion = stateRegionMapping[el];
        console.log(stateRegionMapping[el], "stateRegionMapping[el]stateRegionMapping[el]stateRegionMapping[el]");
        let stateCodesInRegion = [];
        Object.keys(stateRegionMapping).forEach(stateCode => {
            if (stateRegionMapping[stateCode] === selectedRegion) {
                stateCodesInRegion.push(stateCode.substring(3).toLowerCase());
            }
        });
        console.log(selectedRegion, 'states', stateCodesInRegion);
        // setStateCodes(stateCodesInRegion);
        setStateCodes(stateRegionMapping[el]);

    }

    return (
        <React.Fragment>
            <Typography variant='h5' sx={{ mb: 1.5 }}>Vendor Opportunity by HARDI Region</Typography>
            <div style={{ height: 450 }}>
                <VectorMap
                    map={'us_aea'}
                    ref={vectorMapRef}
                    containerClassName="us-map"
                    zoomButtons={false}
                    zoomOnScroll={false}
                    backgroundColor='transparent'
                    series={series}
                    regionsSelectable={false}
                    regionStyle={{
                        initial: {
                            'fill': '#e4e4e4',
                            'fill-opacity': 1,
                            'stroke': 'none',
                            'stroke-width': 0,
                            'stroke-opacity': 0
                        },
                        hover: { fill: '#00138a', 'fill-opacity': 0.9, 'cursor': 'pointer' }
                    }}
                    onRegionTipShow={(e, el, code) => handleMouseEvent(e, el, code)}
                    onRegionClick={(e, el, code) => handleRegionClick(e, el, code)}
                    containerStyle={{ width: '100%', height: '100%' }}
                />
            </div>
        </React.Fragment>
    );
}