import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { modifyFiltersToOldFormat } from '../../../../src/middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { Button } from '@mui/material';

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function TerminatedVPOpportunity({ memberId }) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  let { groupIds } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [tableData, setTableData] = useState([])
  const [rows, setRows] = React.useState(tableData);

  const [value2, setValue] = React.useState('2022-01-01');
  const [value3, setValue1] = React.useState('2022-07-26');
  const { period, year, month, quarter, memberIds } = useSelector(state => state.filter);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('asc');
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);
  const [searched, setSearched] = React.useState("");
  const [branchList, setBranchList] = React.useState([]);
  //const [memberIds, setMemberIds] = React.useState(groupIds.split(',').map(id => parseInt(id)));

  
  const clean = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

const loadData = async () => { // funtion to call post invoice api. and get data
    if (localStorage.getItem('authToken')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
    }
    let postParamsData = { year, month, quarter, period,memberId: memberIds.map(row => row.dbaId) }

    if (vpName != "") {
      postParamsData.dropDown = [{ vpName: [vpName] }];
    }
    else {
      postParamsData.dropDown = [];

    }

    postParamsData = clean(postParamsData);
    postParamsData = modifyFiltersToOldFormat(postParamsData);
    postParamsData.quarter = "qtr" + quarter;
    console.log('check postParamsDat', postParamsData)
    await axios.post('/api/member/member-conversion/getTerminatedVpOpportunitiesTerminatedVpOpportunityDetails', postParamsData
    )
      .then((res) => {
        setTableData(res.data)
        setRows(res.data)
        //.then((res) => setRows(res.data))

        console.log('initial post data ', tableData)
        console.log('initial post data ', rows)


      })
  };
  let tableDataBackup = [...rows]

  //   useEffect(() => {
  //     loadData();

  //     axios.post('/api/groups/dba/member', { 'ids': groupIds.split(',').map(id => parseInt(id)) }).then(response => setBranchList(response.data));
  // }, []);

  useEffect(() => {
    if (year && memberIds && memberIds.length > 0) {
      let params = new URLSearchParams();
      params.append('year', year);
      const parameters = { year, memberId: memberIds.map(row => row.dbaId) };
    Promise.all([
      axios.post('/api/member/member-conversion/getTerminatedVpOpportunitiesVPNames', parameters),
    ]).then((values) => {
      //setVpNames(values[0].data.data.vendorName);
      //console.log('setVpnames', values[0].data.data.vendorName)
    loadData();
    });

  }}, []);

  useEffect(() => {
    loadData();
  }, [year, month, period, quarter, memberIds,vpName])

  

const columns = [
    { name: 'terminatedVendorPartner', label: 'TERMINATED VENDOR PARTNER', align: 'right', minWidth: 170 ,      options: {
        filter: true,
        sort: true
       }    },
    { name: 'poCount', label: 'PO COUNT', align: 'right', minWidth: 170 ,      options: {
        filter: true,
        sort: true
       }    },
    {
        name: 'purchases',
      label: 'VOLUME',
      minWidth: 170,
      align: 'right',
      options: {
       
        customBodyRender: value => <span>{<FormatCurrency value = {value}/>}</span>,
        filter: true,
         sort: true
    
      }
    },
    {
        name: 'averagePurchasesPerPo',
      label: 'AVERAGE VOLUME/PO',
      minWidth: 170,
      align: 'right',
      options: {
       
        customBodyRender: value => <span>{<FormatCurrency value = {value}/>}</span>,
        filter: true,
         sort: true
    
      }
    }
  
  ];
  
    
    function showDocumentStatusInfo(){
        console.log('column info is printed');
      }
      
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: false, // <===== will turn off checkboxes in rows
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
  ];

  const generateExport=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("TerminatedVendorPartnerOpportunityPODetails", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${tableData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Terminated Vendor Partner Opportunity PO Details";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["TERMINATED VENDOR PARTNER","PO COUNT","VOLUME","AVERAGE VOLUME/PO"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
    
    
    tableData.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.terminatedVendorPartner, obj.poCount,
          '$'+Math.round(obj.purchases) ,'$'+Math.round(obj.averagePurchasesPerPo)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
   
 
   
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Terminated Vendor Partner Opportunity PO Details';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "TerminatedVendorPartnerOpportunityPODetails.xlsx");
    });
  }

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>

        {/* <FormControl>
          <InputLabel id="demo-simple-select-label">
            Responsive Option
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={responsive}
            style={{ width: "200px", marginBottom: "10px", marginRight: 10 }}
            onChange={(e) => setResponsive(e.target.value)}
          >
            <MenuItem value={"vertical"}>vertical</MenuItem>
            <MenuItem value={"standard"}>standard</MenuItem>
            <MenuItem value={"simple"}>simple</MenuItem>

            <MenuItem value={"scroll"}>scroll (deprecated)</MenuItem>
            <MenuItem value={"scrollMaxHeight"}>
              scrollMaxHeight (deprecated)
            </MenuItem>
            <MenuItem value={"stacked"}>stacked (deprecated)</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Table Body Height
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableBodyHeight}
            style={{ width: "200px", marginBottom: "10px", marginRight: 10 }}
            onChange={(e) => setTableBodyHeight(e.target.value)}
          >
            <MenuItem value={""}>[blank]</MenuItem>
            <MenuItem value={"400px"}>400px</MenuItem>
            <MenuItem value={"800px"}>800px</MenuItem>
            <MenuItem value={"100%"}>100%</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            Max Table Body Height
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableBodyMaxHeight}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setTableBodyMaxHeight(e.target.value)}
          >
            <MenuItem value={""}>[blank]</MenuItem>
            <MenuItem value={"400px"}>400px</MenuItem>
            <MenuItem value={"800px"}>800px</MenuItem>
            <MenuItem value={"100%"}>100%</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Search Button</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchBtn}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setSearchBtn(e.target.value)}
          >
            <MenuItem value={"true"}>{"true"}</MenuItem>
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"disabled"}>disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Download Button</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={downloadBtn}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setDownloadBtn(e.target.value)}
          >
            <MenuItem value={"true"}>{"true"}</MenuItem>
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"disabled"}>disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Print Button</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={printBtn}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setPrintBtn(e.target.value)}
          >
            <MenuItem value={"true"}>{"true"}</MenuItem>
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"disabled"}>disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            View Column Button
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={viewColumnBtn}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setViewColumnBtn(e.target.value)}
          >
            <MenuItem value={"true"}>{"true"}</MenuItem>
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"disabled"}>disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Filter Button</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterBtn}
            style={{ width: "200px", marginBottom: "10px" }}
            onChange={(e) => setFilterBtn(e.target.value)}
          >
            <MenuItem value={"true"}>{"true"}</MenuItem>
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"disabled"}>disabled</MenuItem>
          </Select>
        </FormControl> */}
        <div>
        <Button variant="contained" sx={{ ml: 2,height:'42px',float:'right',marginTop:'20px',marginRight:'23px'}} onClick={() => generateExport()}><FileDownloadOutlinedIcon/>Export</Button>

        
        <MUIDataTable
          title={"Terminated Vendor Partner Opportunity PO Details"}
          data={tableData}
          columns={columns}
          options={options}
        />
</div>
      </ThemeProvider>
    </CacheProvider>
  );
}

