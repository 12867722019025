import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Button, IconButton, ToggleButton, ToggleButtonGroup, Toolbar, Typography,Paper } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { FormatCurrency, YoyChip } from '../../common/formatter';
import MaterialSearch from '../../common/material-search';

function MemberDashboardLink({ value }) {
    return (
        <IconButton component={Link} color='primary' size='small' to={generatePath('/member/:groupIds', { groupIds: value })}>
            <LaunchIcon fontSize="inherit" />
        </IconButton>
    );
}

export default function AdminOverviewMemberPerformance({ parentRollup, selectedMembers, setSelectedMembers, selectedVendors, setSelectedVendors }) {

    const { period, year, month, quarter } = useSelector((state) => state.filter);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [volumeTab, setVolumeTab] = useState(true);
    const [memberNameData] = useState();

    const clearOperations = () => {
        setSearch('')
        setSelectedVendors([]);
        setSelectedMembers([]);
    }

    const columns = [
        { field: 'dbaId', headerName: '', width: 20, renderCell: MemberDashboardLink, sortable: false },
        { field: 'dbaName', headerName: 'Member Name', flex: 1 },
        { field: 'memberSales', headerName: 'Member Volume', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'memberRebate', headerName: 'Member Rebate', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'vendorSales', headerName: 'Vendor Volume', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'vendorRebate', headerName: 'Vendor Rebate', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'bhSales', headerName: 'Verified Volume', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'bhRebate', headerName: 'Verified Rebate', width: 130, align: 'right', renderCell: FormatCurrency },
        { field: 'bhSalesYoy', headerName: 'YOY', width: 120, align: 'right', renderCell: (params) => <YoyChip yoy={params.value} /> }
    ];

    useEffect(() => {
        let params = { year, month, period, quarter, parentRollup };
        if (memberNameData) {
            params = { ...params, dropDown: [{ memberName: [memberNameData] }] };
        }
        if (selectedVendors && selectedVendors.length > 0) {
            params = { ...params, vendorId: selectedVendors };
        }
        setLoading(true);
        axios.post("/api/corporate/overview/member-performance", { ...params }).then((response) => {
            setLoading(false);
            return response.data;
        }).then(setRows);
    }, [year, period, month, quarter, parentRollup, selectedVendors]);

    const columnVisibility = volumeTab ? { memberRebate: false, vendorRebate: false, bhRebate: false } : { memberSales: false, vendorSales: false, bhSales: false, bhSalesYoy: false };
    const visibleRows = search ? rows.filter(row => String(row.dbaName).toLowerCase().includes(search.toLowerCase())) : rows;

    return (
        <React.Fragment>
            <Toolbar disableGutters>
            

                <Typography>Member Performance</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <MaterialSearch value={search} onChange={event => setSearch(event.target.value)} />
                <Button sx={{ mr: 2 }} variant="outlined" onClick={() => clearOperations()}><FilterAltOffIcon sx={{ mr: 1 }} /> Clear</Button>
                <ToggleButtonGroup size='small' value={volumeTab} exclusive onChange={(_event, value) => setVolumeTab(value)}>
                    <ToggleButton value={true}>VOLUME</ToggleButton>
                    <ToggleButton value={false}>REBATE</ToggleButton>
                </ToggleButtonGroup>
            </Toolbar>
            <Box sx={{ height: '455px' }}>
            <DataGrid rows={visibleRows} columns={columns} getRowId={(row) => row.dbaId} pageSize={pageSize}
                onPageSizeChange={setPageSize} rowsPerPageOptions={[10, 50, 100]}
                density='compact' columnVisibilityModel={columnVisibility} loading={loading}
                selectionModel={selectedMembers} onSelectionModelChange={setSelectedMembers} disableColumnMenu />
                </Box>
        </React.Fragment>
    );
}