import { getMonth } from "date-fns";

export const SET_FILTER = 'SET_FILTER';
export const SET_PERIOD = 'SET_PERIOD';
export const SET_YEAR = 'SET_YEAR';
export const SET_MONTH = 'SET_MONTH';
export const SET_QUARTER = 'SET_QUARTER';
export const SET_MEMBER_IDS = 'SET_MEMBER_IDS';
export const SET_VENDOR_IDS = 'SET_VENDOR_IDS';
export const SET_REBATE_TYPES = 'SET_REBATE_TYPES';
export const SET_SOURCES = 'SET_SOURCES';


/**
 * Set any Default Date to start seeing Dashboard.
 */
let newDate = new Date()

// const START_DATE = new Date(2023, 11, 31);
const START_DATE = new Date(2024, 11, 31);


// const monthcheck=newDate.getMonth() +1;
const monthcheck=newDate.getMonth() +1;
console.log('monthcheck',newDate.getMonth(),START_DATE)

export const filter = (state = {
    period: 'ytd',
    year: START_DATE.getFullYear(),
    // month: START_DATE.getMonth() + 1,
    month: monthcheck,
    quarter: Math.ceil((START_DATE.getMonth() + 1) / 3),
    memberIds: [],
    vendorIds: [],
    rebateTypes: [],
    sources: [],
   
},action = { type: null, payload: null }) => {
    switch (action.type) {
        case SET_FILTER:
            return { ...action.payload };
        case SET_PERIOD:
            return { ...state, period: action.payload };
        case SET_YEAR:
            return { ...state, year: action.payload };
        case SET_MONTH:
            return { ...state, month: action.payload };
        case SET_QUARTER:
            return { ...state, quarter: action.payload };
        case SET_MEMBER_IDS:
            return { ...state, memberIds: action.payload };
        case SET_VENDOR_IDS:
            return { ...state, vendorIds: action.payload };
        case SET_REBATE_TYPES:
            return { ...state, rebateTypes: action.payload };
        case SET_SOURCES:
            return { ...state, sources: action.payload };
        default:
            return state;          
    }
   
    
};


