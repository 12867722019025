import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import dashboardLogo from '../../assets/images/bh.png';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

export default function ForgotPassword() {

    const [responseMessage, setResponseMessage] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        // axios.get('/track/'+ 21).then((res) => {
        //     console.log('console create user post api data is ', res.data) 
        //     if(res.data){
        //      enqueueSnackbar('Forgot password');
        //     }
        //   })
        //   .catch(error => {
        //     if (error.response) {
        //       enqueueSnackbar("Error Message",error.response.data.error);
        //       // alert("Error Message",error.response.data.error);
        //     }
        //   });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.currentTarget);
        let data = { email: formData.get('email'), host: window.location.origin }
        axios.post('/forgot-password', data).then(response => {
            setResponseMessage(`${response.data.message} please check the mail and reset your passwod`);
        });
    }

    if (!responseMessage) {
        return (
            <Box sx={{ mx: 'auto', minHeight: '100vh', maxWidth: 500, display: 'flex', alignItems: "center" }}>
                <Paper elevation={4} sx={{ p: 4, flexGrow: 1 }}>
                    <Stack spacing={2} component="form" onSubmit={handleSubmit}>
                        <Box><img src={dashboardLogo} alt="bh logo" style={{ height: '5rem' }} /></Box>
                        <Typography variant='h5'>Forgot Password</Typography>
                        <TextField fullWidth variant="outlined" name="email" label="Email" type="email" required />
                        <Typography variant='caption'>Enter email address associated with Blue Hawk and we'll send you a link to reset your password.</Typography>
                        <Button type="submit" variant='contained' color='success'>Continue</Button>
                    </Stack>
                </Paper>
            </Box>
        );
    } else {
        return (
            <Box sx={{ mx: 'auto', minHeight: '100vh', maxWidth: 500, display: 'flex', alignItems: "center" }}>
                <Paper elevation={4} sx={{ p: 4, textAlign: 'center', flexGrow: 1 }}>
                    <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <CheckCircleOutlineIcon sx={{ fontSize: 100, color: 'green' }} />
                        <Typography>{responseMessage}</Typography>
                        <Button component={Link} to="/login" variant='contained' color='success'>Go to Login Page</Button>
                    </Stack>
                </Paper>
            </Box>
        );
    }
}

