import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { FormatCurrency } from '../../common/formatter';

const monthsCategory = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const quarterCategory = ['Qtr 1', 'Qtr 2', 'Qtr 3', 'Qtr 4'];

export default function VendorOpportunityTrend() {

    const { period, year, vendorIds } = useSelector(state => state.filter);
    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        axios.post('/api/vendor/opportunity/opportunity-trend', { year, vendorId: vendorIds.map(row => row.dbaId) }).then(response => {
            setSummaryData(response.data);
        });
    }, [year, vendorIds]);

    const chartOptions = {
        chart: { id: 'opportunity-line-chart', type: 'bar', toolbar: { show: false }, zoom: { enabled: true } },
        markers: { size: 6 },
        xaxis: { type: 'category', categories: period === 'qtr' ? quarterCategory : monthsCategory },
        yaxis: { labels: { formatter: (value) => FormatCurrency({ value }) } }
    };

    let newSeries = [];

    switch (period) {
        case 'qtr':
            const quarterData = summaryData.filter(row => [3, 6, 9, 12].includes(row.month));
            newSeries.push({ name: 'Verified Transactions', data: quarterData.map(row => row.bhSales) });
            newSeries.push({ name: 'VP Transactions', data: quarterData.map(row => row.vendorSales) });
            newSeries.push({ name: 'Opportunity CY', data: quarterData.map(row => row.opportunity) });
            break;
        case 'mth':
            newSeries.push({ name: 'Verified Transactions', data: summaryData.map(row => row.bhSales) });
            newSeries.push({ name: 'VP Transactions', data: summaryData.map(row => row.vendorSales) });
            newSeries.push({ name: 'Opportunity CY', data: summaryData.map(row => row.opportunity) });
            break;
        default:
            newSeries.push({ name: 'Verified Transactions', data: summaryData.map(row => row.bhSalesYtd)});
            newSeries.push({ name: 'VP Transactions', data: summaryData.map(row => row.vendorSalesYtd) });
            newSeries.push({ name: 'Opportunity CY', data: summaryData.map(row => row.opportunityYtd) });
            break;
    }

    return (
        <React.Fragment>
            <Typography variant="h5" sx={{ mb: 1.5 }}>Volume Opportunity</Typography>
            <Chart options={chartOptions} series={newSeries} height="450" />
            {console.log('period',period,summaryData)}
        </React.Fragment>
    );

}
