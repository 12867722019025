import React, { useEffect, useState } from 'react';
import { FormatCurrency } from "../../common/formatter";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from "axios";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
import { modifyFiltersToOldFormat } from "../../../middleware/helper";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ float: 'right' }}>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        sx={{
          backgroundColor: '#556cd6', '&:hover': {
            backgroundColor: "#556cd6",
          },
          top: '-68px', right: '-8px', position: 'absolute'
          , padding: '7px 12px', fontSize: '14px', color: '#fff', marginRight: '10px'
        }}
      />
    </GridToolbarContainer>
  );
}

function AdminDataQualitySummary() {
  const { period, year, month, quarter } = useSelector((state) => state.filter);
  const [qualityData, setQualityData] = useState([]);
  const [memberData, setMemberData] = useState([])
  const [memberDatas, setMemberDatas] = useState('');
  const [vendorData, setVendorData] = useState([])
  const [vendorDatas, setVendorDatas] = useState('');
  const [tableDataBackupRows, setTableDataBackupRows] = useState("");
  const [empty, setEmpty] = useState('')
  const [pageSize, setPageSize] = React.useState(20);

  const dataQualityColumns = [
    { field: 'vendorId', headerName: 'Vendor ID', width: 80 },
    { field: 'vendorName', headerName: 'Vendor Partner', width: 350 },
    { field: 'memberId', headerName: 'Member ID', width: 150 },
    { field: 'memberName', headerName: 'Member', width: 200 },
    { field: 'year', headerName: 'Year', width: 110 },
    { field: 'quarter', headerName: 'Quarter', width: 80, align: 'right' },
    {
      field: 'vendorSales', headerName: 'Sales VP Trans', width: 120,
      align: "right", renderCell: (params) => <FormatCurrency value={params.value} />
    },
    {
      field: 'quarterlySales', headerName: 'Sales VP Guaranteed ', width: 150,
      align: "right", renderCell: (params) => <FormatCurrency value={params.value} />
    },
    {
      field: 'difference', headerName: 'Difference ($)', width: 150,
      align: "right", renderCell: (params) => <FormatCurrency value={params.value} />
    },
    { field: 'differencePercentage', headerName: 'Difference (%)', width: 120, align: "right", },
  ]


  useEffect(() => {
    let params = modifyFiltersToOldFormat({ year, month, period, quarter });
    params = { ...params, }
    if (memberDatas) {
      params = { ...params, dropDown: [{ memberName: [memberDatas] }], page: '1' };
    }
    if (vendorDatas) {
      params = { ...params, dropDown: [{ vendorName: [vendorDatas] }], page: '1' };
    }
    if (memberDatas, vendorDatas) {
      params = { ...params, dropDown: [{ memberName: [memberDatas] }, { vendorName: [vendorDatas] }], page: '1' };
    }
    axios.post("/api/common/data-quality-sales", params)
      // .then(response =>setQualityData(response.data.map((row, index) => ({ ...row, id: index })))
      // ,setQualityData(response.data.map((row, index) => ({ ...row, id: index }))))
      .then(response => {
        setQualityData(response.data.map((row, index) => ({ ...row, id: index })))
        setTableDataBackupRows(response.data.map((row, index) => ({ ...row, id: index })))
      });
    axios.post("/api/common/data-quality-members", params)
      .then(response => setMemberData(response.data))
    axios.post("/api/common/data-quality-vendors", params)
      .then(response => setVendorData(response.data))
  }, [year, month, period, quarter, memberDatas, vendorDatas]);

  const memberOnchange = (e) => {
    setMemberDatas(e.target.value)
  }
  const vendorOnchange = (e) => {
    setVendorDatas(e.target.value)
    console.log('selected member data', e.target.value)
  }

  const clearAll = () => {
    if (memberDatas) {
      setMemberDatas(empty)
      setQualityData(tableDataBackupRows);
    }
    if (vendorDatas) {
      setVendorDatas(empty)
      setQualityData(tableDataBackupRows);
    }
    if (memberDatas, vendorDatas) {
      setMemberDatas(empty)
      setVendorDatas(empty)
      setQualityData(tableDataBackupRows);
    }
  }

  // const handleExport = () => {
  //   var wb = XLSX.utils.book_new(),
  //     ws = XLSX.utils.json_to_sheet(qualityData);
  //   XLSX.utils.book_append_sheet(wb, ws, "DataQualitySales")
  //   XLSX.writeFile(wb, "DataQualitySales.xlsx")
  // }

  const generateExportDataQuality=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("DataQuality", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${qualityData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Data Quality";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["VENDOR ID","VENDOR PARTNER","MEMBER ID","MEMBER","YEAR","QUARTER","SALES VP TRANS","SALES VP GUARANTEED","DIFFERENCE($)","DIFFERENCE(%)"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
    
    
    qualityData.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.vendorId, obj.vendorName, obj.memberId,obj.memberName,obj.year,obj.quarter,
             '$'+Math.round(obj.vendorSales) ,'$'+Math.round(obj.quarterlySales),
             '$'+Math.round(obj.difference),(obj.differencePercentage)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 12;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 50;
    worksheet.getColumn(5).width = 12;
    worksheet.getColumn(6).width = 8;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 8;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 12;
   
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Data Quality';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "DataQuality.xlsx");
    });
  
}


  return (
    <><React.Fragment>
      <Paper elivation={1} sx={{ p: 2, mb: 3, mt: 10 }}>
        <Grid container spacing={12} sx={{ boxShadow: '0px' }}>
          <Grid item xs={12} sm={12}>
            {/* <Paper style={{height:'auto',width:'100%'}} > */}
            <div sx={{ display: 'inline-flex' }}>
              <Typography variant="h6" style={{ marginRight: '30px', float: 'left', fontSize: "26px", marginTop: '8px' }}>Data Quality</Typography>
              <FormControl sx={{ mr: 3 }}>
                <InputLabel id="demo-simple-select-helper-label">Select Member</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={memberDatas}
                  label="Select Member"
                  onChange={memberOnchange}
                  sx={{ float: 'right', height: '50px', width: '200px' }}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>

                  {memberData.map((data, index) => (
                    <MenuItem key={index} value={data.memberName}>
                      {data.memberName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">Select Vendor Partner</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={vendorDatas}
                  label="Select Vendor Partner"
                  onChange={vendorOnchange}
                  sx={{ float: 'right', height: '50px', width: '250px' }}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {vendorData.map((data, index) => (
                    <MenuItem key={index} value={data.vendorName}>
                      {data.vendorName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button variant="contained" sx={{ float: 'right',marginLeft:'10px' }} onClick={() => generateExportDataQuality()}><FileDownloadOutlinedIcon/>Export</Button>
              <Button variant="contained" sx={{ float: 'right' }} onClick={() => clearAll()}>Clear All</Button>
              {/* <Button variant="contained" sx={{ float: 'inherit',marginTop:'6px',marginLeft:'20px' }} onClick={clearAll}>Clear All </Button> */}
              {/* <Button variant="contained" sx={{float:'right',marginRight:'30px'}} onClick={handleExport} >Export</Button> */}
            </div>
            <Box sx={{ mt: 2, height: '600px', width: '100%' }}>
              <DataGrid
                rows={qualityData}
                columns={dataQualityColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
                rowsPerPageOptions={[20,50,100]} 
                      checkboxSelection
                disableSelectionOnClick
                // components={{
                //   Toolbar: CustomToolbar,
                // }}
                // localeText={{
                //   toolbarExport: "export"
                // }}
              />
            </Box>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment> </>
  )
}
export default AdminDataQualitySummary;