import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import HttpError from './http-error';

export default function RequireAuth({ children }) {

    const location = useLocation();
    const { groupIds } = useParams();
    const auth = useSelector(state => state.auth);
    const [authorised, setAuthorised] = useState(true);

    useEffect(() => {
        // console.log('GROUPS', location.pathname, groupIds);
        if (auth.userType === 'CORPORATE') {
            setAuthorised(true);
        } else if (!location.pathname.startsWith(`/${auth.userType.toLowerCase()}`)) {
            setAuthorised(false);
        } else if (location.pathname.startsWith(`/${auth.userType.toLowerCase()}`)) {
            if (groupIds) {
                let allowed = true;
                groupIds.split(',').forEach(id => {
                    if (!auth.branches.includes(parseInt(id))) {
                        allowed = false;
                    }
                });
                setAuthorised(allowed);
            } else {
                setAuthorised(false);
            }
        }
    }, [location]);

    if (auth.id) {
        return authorised ? children : <HttpError code={401} message={'You are not allowed to view this content'} />;
    }
     else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
}
