import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import { Chip, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, Switch, TableFooter, TablePagination, TableSortLabel, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getComparator, stableSort } from '../../../middleware/helper';
import LinearProgressWithLabel from '../../common/linear-progress-with-label';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";



// const useRowStyles = makeStyles({
//     tableBody: {
//       "& > :not(:last-child)": {
//         borderBottom: "25px solid red"
//       }
//     }
//   });
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CategoryDetailsDialog({ open, handleClose }) {
    return (
        ''
    );
}

function CategoryFeedbackDialog({ open, handleClose }) {
    return (
        ''
    );
}

function Row({ row }) {
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [categoriesData, setCategoriesData] = useState([]);
    const [ProductData, setProductData] = useState([]);
    const [feedbackName, setFeedbackName] = useState('');
    const [categoryName1, setCategoryName] = useState('');
    const [vpName, setVpName] = React.useState('');
    const [vpNames, setVpNames] = React.useState(['']);

    const [Lists, setCategoryList] = React.useState(['']);
    const [Liststotal, setCategoryListtotal] = React.useState(['']);

    const [Lists1, setCategoryList1] = React.useState(['']);
    const [ListsId1, setCategoryListId1] = React.useState(['']);
    const [categoryId, setCategoryId] = useState('');
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [outVendor, setOutVendor] = useState('');
    const [removeToggle, setRemoveToggle] = useState(false);
    const [removeToggle1, setRemoveToggle1] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = React.useState(false);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const { period, year, month, quarter, vendorIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    const handleOpen = (row) => {
        setOpen(true);
        setLoading(true);
        let params = { period, year, month, quarter, memberId: row.memberId, vendorId: vendorIds.map(row => row.dbaId) };
        axios.post('/api/vendor/opportunity/member-overview-category', { ...params }).then(response => {
            setLoading(false);
            setCategoriesData(response.data);
        });
        axios.post('/api/vendor/opportunity/member-overview-product', { ...params }).then(response => {
            setLoading(false);
            setProductData(response.data);
        });
    };


    useEffect(() => {
        Promise.all([
            axios.post('/api/common/getBhCategoryList'),

        ]).then(response => {
            setLoading(false);
            setCategoryList(response[0].data.map((row, index) => ({ ...row, id: index })));


        });

    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenfeedbackDialog = (categoryId, categoryName, productId, productDescription, outVendor) => {
        console.log(categoryId, categoryName, productId, productDescription, outVendor, "categoryId,categoryName,productId,productName,outVendor")
        setOpenFeedbackDialog(true);
        setCategoryId(categoryId)
        setCategoryName(categoryName)
        setProductId(productId)
        setProductName(productDescription)
        setOutVendor(outVendor)
    };

    const handleFeedackDialogClose = (e) => {
        console.log('handleCategoryDialogClose')
    }

    const handleClose = () => {
        setOpenFeedbackDialog(false);

    };

    const categoryset = (e) => {
        let text = e.target.value
        setCategoryListtotal(text);

        let key = text.split("#")[1];
        let value = text.split("#")[0];
        setCategoryList1(value);
        setCategoryListId1(key);
        console.log("valuevalue", value)
        console.log("keykey", key)


    }
    const handleFormSubmit = e => {
        e.preventDefault();

        let data = {
            bhCategoryId: categoryId,
            bhCategoryName: categoryName1,
            comments: "",
            productDescription: productName,
            productId: productId,
            productNotSold: 1,
            statusForProductsNotSold: removeToggle1,
            statusForWrongClassification: removeToggle,
            suggestedBhCategoryId: ListsId1,
            suggestedBhCategoryName: Lists1,
            wrongCategory: 1,

        }
        if (removeToggle == true && removeToggle1 != true) {
            axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
                .then(res => {
                    if (res.status == 200) {
                        enqueueSnackbar(res.data.message);
                        setOpenFeedbackDialog(false);
                    }
                })
                .catch(err => {
                    enqueueSnackbar('Error while submitting feedback');
                    setOpenFeedbackDialog(false);
                })
        }
        else if (removeToggle1 == true && removeToggle != true) {
            axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
                .then(res => {
                    if (res.status == 200) {
                        enqueueSnackbar(res.data.message);
                        setOpenFeedbackDialog(false);
                    }
                })
                .catch(err => {
                    enqueueSnackbar('Error while submitting feedback');
                    setOpenFeedbackDialog(false);
                })
        }
        else if (removeToggle == true && removeToggle1 == true) {
            axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
                .then(res => {
                    if (res.status == 200) {
                        enqueueSnackbar(res.data.message);
                        setOpenFeedbackDialog(false);
                    }
                })
                .catch(err => {
                    enqueueSnackbar('Error while submitting feedback');
                    setOpenFeedbackDialog(false);
                })


            axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
                .then(res => {
                    if (res.status == 200) {
                        enqueueSnackbar(res.data.message);
                        setOpenFeedbackDialog(false);
                    }
                })
                .catch(err => {
                    enqueueSnackbar('Error while submitting feedback');
                    setOpenFeedbackDialog(false);
                })

        }


    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);
    const columnCategory = [
        { field: "categoryName", headerName: "Categories", flex: 1 },
        {
            field: "opportunity", headerName: "Opportunities", width: 200, renderCell: params => (
                <Box sx={{ height: 3 / 4, border: 1, width: 1 }}>
                    <Box sx={{ height: 1, width: params.value / 100, bgcolor: '#ccc' }}></Box>
                </Box>
            )
        },
        { field: "productCount", headerName: "Products", with: 100 }
    ];

    return (
        <React.Fragment>
            <TableRow key={'row-' + row.memberid} row={row} sx={{ '& > *': { bgcolor: open ? '#BBDEFB' : '#fff' } }}>
                <TableCell scope="row">{row.memberName}</TableCell>
                <TableCell scope="row">{row.state}</TableCell>
                {/* <TableCell scope="row"><Chip label={row.bucket} /></TableCell> */}
                <TableCell scope="row">
                    <Box sx={{ mr: 2, display: 'inline' }}><Chip label={row.bucket} /></Box>
                    <IconButton aria-label="expand row" size="small" onClick={() => open ? setOpen(false) : handleOpen(row)}>
                        {open ? <KeyboardArrowUpIcon sx={{ fontSize: 'inherit' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 'inherit' }} />}
                    </IconButton>
                </TableCell>
                <TableCell scope="row"><LinearProgressWithLabel value={row.opportunity} /></TableCell>
                <TableCell scope="row" align='right'>{row.productCount}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ p: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {loading ? <LinearProgress sx={{ height: 10 }} /> : (
                            <Paper elevation={0} square sx={{ overflowY: 'auto' }} >

                                <Grid container columns={2} spacing={2} >
                                    <Grid item xs={1}>
                                        <DataGrid pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            rowsPerPageOptions={[10, 50, 100]}
                                            density='compact' pagination rows={categoriesData} columns={columnCategory} getRowId={row => row.categoryName} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TableContainer>
                                            <Table aria-label="collapsible table" size='small' stickyHeader square >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Product Desc</TableCell>
                                                        <TableCell>Unapproved Supplier</TableCell>
                                                        <TableCell align="left">Feedback</TableCell>
                                                        {/* <TableCell align="right">Volume</TableCell> */}
                                                        {/* <TableCell align="right">Prob</TableCell>
                            <TableCell align="right">P/Unit</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(rowsPerPage > 0 ? stableSort(ProductData, getComparator(order, orderBy)).
                                                        slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : ProductData).map((historyRow, rowIdx) => (
                                                            <TableRow key={historyRow.productId + rowIdx}>
                                                                <TableCell component="th" scope="row" align="left">
                                                                    {historyRow.productDescription}
                                                                </TableCell>
                                                                <TableCell align="left"  >{historyRow.outVendor}</TableCell>
                                                                {/* <TableCell align="left" hidden >{historyRow.catgeoryName}</TableCell>
                                                            <TableCell align="left" hidden >{historyRow.categoryId}</TableCell>
                                                            <TableCell align="left" hidden >{historyRow.productId}</TableCell> */}

                                                                <TableCell align="left">
                                                                    <IconButton sx={{ ml: 0.5 }} size="small"
                                                                        onClick={() => handleOpenfeedbackDialog(historyRow.categoryId, historyRow.categoryName, historyRow.productId, historyRow.productDescription, historyRow.outVendor)}><OpenInNewIcon fontSize='inherit' /></IconButton>
                                                                </TableCell>
                                                                {/* <TableCell align="left">{historyRow.volume}</TableCell> */}
                                                                {/* <TableCell align="left">{historyRow.probability}</TableCell>
                                <TableCell align="left">{historyRow.pricePerUnit}</TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                                            count={ProductData.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
            <Paper>


                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openFeedbackDialog}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "40%",
                                height: '70%',
                                maxWidth: 'none'
                            },
                        },
                    }}
                >
                    <Box sx={{ flexGrow: 1, p: 5 }}>

                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Product feedback for <strong>{categoryName1}</strong>
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid item xs={2}>
                                <Box>
                                    <Stack direction='column' spacing={4} marginBottom='5' >

                                        <Typography id="modal-modal-title" variant="body1" component="h2">
                                            Is <strong>{productName}</strong> wrongly classified as <strong>{categoryName1}</strong>?
                                            <FormControlLabel control={<Switch checked={removeToggle} onChange={() => setRemoveToggle(!removeToggle)} />} />

                                        </Typography>
                                        {removeToggle ? (
                                            <FormControl>

                                                <InputLabel>Select Category</InputLabel>
                                                <Select
                                                    labelId="category-name-select-large"
                                                    id="category-name-select-large"
                                                    value={Liststotal}
                                                    label="Select category"
                                                    sx={{ marginBottom: 2, minHeight: 55 }}
                                                    onChange={(event) => categoryset(event)}
                                                    size='large'
                                                >
                                                    <MenuItem value="">
                                                        <em>All</em>
                                                    </MenuItem>
                                                    {Lists.map(vp => (<MenuItem key={vp.bhCategoryId} value={vp.bhCategoryName + "#" + vp.bhCategoryId}>{vp.bhCategoryName}</MenuItem>))}
                                                </Select>
                                            </FormControl>) : ''}
                                        <Typography id="modal-modal-title" variant="body1" component="h2">
                                            <strong>{productName}</strong> is not sold by any vendor within Blue Hawk?
                                            <FormControlLabel control={<Switch checked={removeToggle1} onChange={() => setRemoveToggle1(!removeToggle1)} />} />

                                        </Typography>


                                        <Stack direction='row' spacing={4} marginBottom='5' >
                                            <Button color="primary" onClick={handleFormSubmit}
                                                variant="contained" style={{ marginBottom: "0px" }}>
                                                Submit
                                            </Button>
                                            <Button color="secondary" onClick={handleClose}
                                                variant="contained" style={{ marginBottom: "0px" }}>
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Box>

                </BootstrapDialog>

                <CategoryFeedbackDialog open={openFeedbackDialog} handleClose={value => handleFeedackDialogClose(value)} />
            </Paper>

        </React.Fragment>
    );
}
Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export default function VolumeOpportunityByMemberCategories({ stateCodes, setStateCodes }) {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [tableData, setTableData] = useState(stateCodes);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const textInput = React.useRef(null);
    const [tableDataBackup, setTableBackup] = useState("");
    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);

    useEffect(() => {
        console.log(stateCodes, stateCodes.length, "statecodes...............................")
        let params = { period, year, month, quarter, vendorId: vendorIds.map(row => row.dbaId) };
        // postParamsData = modifyFiltersToOldFormat(postParamsData);
        if (stateCodes == "" || stateCodes == [] || stateCodes.length == 0 || stateCodes == null) {
            console.log('hiiii')
            params = params;

        }
        else {
            console.log('helloooooooooooo')
            params.region = stateCodes;
        }
        setLoading(true);
        axios.post('/api/vendor/opportunity/member-overview', params)
            .then(response => {
                setLoading(false);
                setTableData(response.data);
                setTableBackup(response.data);

            })
    }, [period, year, month, quarter, memberIds, vendorIds, stateCodes]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const headCells = [
        { key: 'memberName', label: 'Member Name' },
        { key: 'state', label: 'State' },
        { key: 'bucket', label: 'Volume Rank' },
        // { key: 'categoryCount', label: 'No.Of Categories' },
        { key: 'opportunity', label: 'Opportunity' },
        { key: 'productCount', align: 'right', label: 'SKUs' },
    ];
    const clearAll = () => {
        setTableData(tableDataBackup);
        textInput.current.value = '';

    }

    const requestSearch = (searchedVal) => {
        if (searchedVal == '') {
            setTableData(tableDataBackup);
        }
        setTableData(tableDataBackup);
        const filteredRows = tableDataBackup.filter((row) => {
            return row.memberName.toLowerCase().includes(searchedVal);
        });
        console.log(filteredRows, "filteredRows")
        setTableData(filteredRows);
    }

   




    return (
        <TableContainer component={Paper}>
            <Typography variant='h6' sx={{ m: 2 }}>Volume Opportunity by Member and Categories  {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</Typography>

            <Paper component="div" sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: 400, float: 'right' }} elevation={0}>
                {/* <InputBase type = "text" sx={{ ml: 1, flex: 1 }} value='' placeholder="Search"  onChange={requestSearch} inputProps={{ 'aria-label': 'search google maps' }} /> */}
                <TextField inputRef={textInput} id="standard-basic" variant="standard" placeholder="Search" onChange={(searchVal) => requestSearch(searchVal.target.value)} />
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"><SearchIcon /></IconButton>
                <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} onClick={() => clearAll()}>Clear All</Button>
            </Paper>

            <Paper square elivation={1} sx={{ p: 2, mb: 3 }}>

                <Table aria-label="collapsible table" size='small' stickyHeader square >
                    <TableHead >
                        <TableRow>
                            {headCells.map(headerCell => (
                                <TableCell key={headerCell.key} align={headerCell.align ? headerCell.align : 'left'} sortDirection={orderBy === headerCell.key ? order : false}>
                                    <TableSortLabel active={orderBy === headerCell.key} direction={orderBy === headerCell.key ? order : 'asc'} onClick={createSortHandler(headerCell.key)}>
                                        {headerCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? stableSort(tableData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map((row, rowIdx) => (
                            <Row key={row.memberId + rowIdx} row={row} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                colSpan={headCells.length}
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </TableContainer>
    );
}