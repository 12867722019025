import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-54px',right:'-25px',position:'absolute'
    ,fontSize:'14px',color:'#fff',padding:'6px 12px',marginRight:'26px'}}
      />
    </GridToolbarContainer>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CategoryDetailsDialog({ open, handleClose1 }) {
  return (
    ''
  );
}

function CategoryFeedbackDialog({ open, handleClose }) {
  return (
    ''
  );
}


export default function ConversionOpportunities() {

  const { year, month, period, quarter, vendorIds } = useSelector(state => state.filter);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageSize1, setPageSize1] = React.useState(10);

  const [categoryRows, setCategoryRows] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState('');


  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');

  const [productRows, setProductRows] = useState([]);
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);

  const [Lists, setCategoryList] = React.useState(['']);
  const [Liststotal, setCategoryListtotal] = React.useState(['']);

  const [Lists1, setCategoryList1] = React.useState(['']);
  const [ListsId1, setCategoryListId1] = React.useState(['']);

  const [OpenfeedbackResponse, setOpenfeedback] = useState(false);
  const [removeToggle, setRemoveToggle] = useState(false);
  const [removeToggle1, setRemoveToggle1] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(OpenfeedbackResponse);
  }, [OpenfeedbackResponse]);

  const handleCategoryDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }

  const handleFeedackDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }

  const handleClose = () => {
    setRemoveToggle(false)
    setRemoveToggle1(false)
    // setOpenCategoryDialog(false);
    setOpenFeedbackDialog(false);

  };

  const handleClose1 = () => {
   
     setOpenCategoryDialog(false);
    

  };
  


  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, month, period, quarter, vendorId: vendorIds.map(row => row.dbaId) });
    if (vpName != '') {
      params.dropDown = [{ vendorName: [vpName] }];

    }
    else {
      params.dropDown = [];
    }
    Promise.all([
      axios.post('/api/corporate/conversion/getTerminatedVpOppVolumeOppByProductGroup', { ...params }),

    ]).then(response => {
      setLoading(false);
      setCategoryRows(response[0].data.map((row, index) => ({ ...row, id: index })));


    });
  }, [year, month, period, quarter, vpName]);


  useEffect(() => {
    const params1 = { year }
    Promise.all([
      axios.post('/api/corporate/conversion/getTerminatedVpOppVendorNames', params1),

    ]).then(response => {
      setLoading(false);
      setVpNames(response[0].data.map((row, index) => ({ ...row, id: index })));


    });

    Promise.all([
      axios.post('/api/common/getBhCategoryList'),

    ]).then(response => {
      setLoading(false);
      setCategoryList(response[0].data.map((row, index) => ({ ...row, id: index })));


    });

  }, [year]);

  const columnCategoryOpportunity = [
    { field: "bhCategoryId", headerName: "PRODUCT GROUP", flex: 1, hide: true },

    { field: "productGroup", headerName: "PRODUCT GROUP", flex: 1 },
    { field: "sales", headerName: "PURCHASES", width: 180, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    {
      field: "", headerName: "PRODUCTS", width: 100, renderCell: (params) => (
        <React.Fragment>
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenCategoryDialog(params.row.productGroup, params.row.bhCategoryId)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      )
    },];


  const columnProductPurchases = [
    { field: "productId", headerName: "PRODUCT DESCRIPTION", width: 400, align: 'right', hide: true },
    { field: "productDescription", headerName: "PRODUCT DESCRIPTION", width: 400, align: 'right' },
    {
      field: "", headerName: "FEEDBACK", flex: 1, renderCell: (params1) => (
        <React.Fragment>
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenfeedbackDialog(params1.row.productDescription, params1.row.productId)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      )
    },];



  const handleOpenCategoryDialog = (bhCategoryName, bhCategoryId) => {
    setOpenCategoryDialog(true);
    console.log('open category dialog modal component', setCategoryName(bhCategoryName));
    setCategoryId(bhCategoryId)
    setLoading(true);

    Promise.all([
      axios.post('/api/vendor/conversion/getProductsPurchasedByMembers', { bhCategory: bhCategoryName }),

    ]).then(response => {
      setLoading(false);
      setProductRows(response[0].data);
    });

  };

  const handleOpenfeedbackDialog = (productName, productId) => {
    setOpenFeedbackDialog(true);

    console.log('open feedback dialog modal component', setProductName(productName));
    setProductId(productId)
  };

  const categoryset = (e) => {
    let text = e.target.value
    setCategoryListtotal(text);

    let key = text.split("#")[1];
    let value = text.split("#")[0];
    setCategoryList1(value);
    setCategoryListId1(key);
    console.log("valuevalue", value)
    console.log("keykey", key)


  }
  const handleFormSubmit = async() => {    
    let data = {
      bhCategoryId: categoryId,
      bhCategoryName: categoryName,
      comments: "",
      productDescription: productName,
      productId: productId,
      productNotSold: 1,
      statusForProductsNotSold: removeToggle1,
      statusForWrongClassification: removeToggle,
      suggestedBhCategoryId: ListsId1,
      suggestedBhCategoryName: Lists1,
      wrongCategory: 1,

    }
    if(removeToggle == true && removeToggle1 != true )
    {
    axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == 200 ) {
          enqueueSnackbar(res.data.message);
          setOpenFeedbackDialog(false)
        }
      })
      .catch(err => {
        enqueueSnackbar('Error while submitting feedback');
        setOpenFeedbackDialog(false)


      })
    }
    else if(removeToggle1 == true && removeToggle != true){
    await  axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == 200 ) {
          enqueueSnackbar(res.data.message);
          setOpenFeedbackDialog(false)

        }
      })
      .catch(err => {
        enqueueSnackbar('Error while submitting feedback');
        setOpenFeedbackDialog(false)

      })

    }
    else if(removeToggle == true && removeToggle1 == true){
      axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == '200' ) {
          enqueueSnackbar(res.data.message);
          setOpenFeedbackDialog(false)

        }
      })
      .catch(err => {
        enqueueSnackbar('error while submitting feedback');
        setOpenFeedbackDialog(false)


      })
    

      axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == '200' ) {
          enqueueSnackbar(res.data.message);
          setOpenFeedbackDialog(false)

        }
      })
      .catch(err => {
        enqueueSnackbar('error while submitting feedback');
        setOpenFeedbackDialog(false)


      })
    }


  }

  const generateExportOppByCategory=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("OppByCategory", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${categoryRows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Opportunity By Category";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["PRODUCT GROUP","PURCHASES"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
     
    
    categoryRows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.productGroup ,
             '$'+Math.round(obj.sales)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 30;
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Opportunity By Category';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "OppByCategory.xlsx");
    });
  }


  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3} columns={2}>
        <Grid item xs={3}>
          <Stack direction='row' spacing={33} >
          <div class="parent" style={{width:'100%'}}>
              <div class="block1" style={{width:'33.33%',float:'left'}}>
            <Typography variant='h6' sx={{ mb: 1 }}>Opportunity By Category
              {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</Typography>
              </div>
            <div class="block2" style={{width:'33.33%',float:'left'}}>
            <FormControl>

              <InputLabel>Select VP</InputLabel>
              <Select
                labelId="vp-name-select-small"
                id="vp-name-select-small"
                value={vpName}
                label="Select Supplier"
                sx={{ minWidth: 200, marginBottom: 2, minHeight: 50, float: 'right' }}
                onChange={(event) => setVpName(event.target.value)}
                size='small'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>
            </div>
            <div class="block3" style={{width:'33.33%',float:'right'}}>
            <Button variant="contained" sx={{height:'42px',padding:'0px 24px',float:'right'}} onClick={() => generateExportOppByCategory()}><FileDownloadOutlinedIcon/>Export</Button>
            </div>
            </div>
          </Stack>

          <div>
          <DataGrid sx={{ height: 500 }} disableColumnMenu density='compact' loading={loading}
            rows={categoryRows} columns={columnCategoryOpportunity}            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
            rowsPerPageOptions={[10,50,100]}  disableSelectionOnClick
            getRowId={(row) => row.productGroup}
            // components={{
            //   Toolbar: CustomToolbar,
            //  }}
            //  localeText={{
            //   toolbarExport:'export'
            // }}
          />
          </div>
        </Grid>

        <BootstrapDialog
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={openCategoryDialog}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "50%",
                height: '100%',
                maxWidth: 'none'
              },
            },
          }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose1}>
            Products purchased in {categoryName}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid item xs={2}>
              {/* <Typography variant='h6' sx={{ mb: 1 }}></Typography> */}
              <DataGrid autoHeight disableColumnMenu density='compact' loading={loading} rows={productRows} columns={columnProductPurchases}
                getRowId={(row) => row.productId}
            pageSize={pageSize1}
            onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}    
            rowsPerPageOptions={[10,50,100]} 
            disableSelectionOnClick />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose1}>Close</Button>
          </DialogActions>
        </BootstrapDialog>


      </Grid>
      <CategoryDetailsDialog open={openCategoryDialog} handleClose1={value => handleCategoryDialogClose(value)} />


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openFeedbackDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '70%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <Box sx={{ flexGrow: 1, p:5 }}>

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Product feedback for {categoryName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid item xs={2}>
            <Box>
              <Stack direction='column' spacing={4} marginBottom='5' >

                <Typography id="modal-modal-title" variant="body1" component="h2">
                  Is {productName} wrongly classified as {categoryName}?
                  <FormControlLabel control={<Switch checked={removeToggle} onChange={() => setRemoveToggle(!removeToggle)} />} />

                </Typography>
                {removeToggle ? (
                  <FormControl>

                    <InputLabel>Select Category</InputLabel>
                    <Select
                      labelId="category-name-select-large"
                      id="category-name-select-large"
                      value={Liststotal}
                      label="Select category"
                      sx={{ marginBottom: 2, minHeight: 55 }}
                      onChange={(event) => categoryset(event)}
                      size='large'
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {Lists.map(vp => (<MenuItem key={vp.bhCategoryId} value={vp.bhCategoryName + "#" + vp.bhCategoryId}>{vp.bhCategoryName}</MenuItem>))}
                    </Select>
                  </FormControl>) : ''}
                <Typography id="modal-modal-title" variant="body1" component="h2">
                  {productName} is not sold by any vendor within Blue Hawk?
                  <FormControlLabel control={<Switch checked={removeToggle1} onChange={() => setRemoveToggle1(!removeToggle1)} />} />

                </Typography>


                <Stack direction='row' spacing={4} marginBottom='5' >
                  <Button color="primary" onClick={handleFormSubmit}
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Submit
                  </Button>
                  <Button color="secondary" onClick={handleClose}
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Box>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
        </Box>

      </BootstrapDialog>

      <CategoryFeedbackDialog open={openFeedbackDialog} handleClose={value => handleFeedackDialogClose(value)} />



    </Paper>
  );
}