import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, CardActions, CardContent, Chip, CircularProgress, IconButton, Paper, Popover, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

const GroupInfoCard = ({ groupData, userType }) => {
    return groupData.length > 0 ? groupData.map((userGroup, userGroupIndex) => {
        let groupLabel = userType;
        if (userGroup.groupId === userGroup.dbaId === userGroup.parentId) {
            groupLabel = `${userType} PARENT`;
        } else if (userGroup.groupId === userGroup.dbaId) {
            groupLabel = `${userType} DBA`;
        }
        return (
            <Card variant="outlined" key={`dashboard-id-chip-${userGroup.groupId}`}>
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant="body2" component="div">{userGroup.groupId}</Typography>
                    <Typography variant="body1" component="div">{userGroup.groupName}</Typography>
                    <Typography variant="caption" sx={{ display: "block" }}>{userGroup.joiningDate} - {userGroup.leavingDate}</Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Chip label={groupLabel} />
                    {userGroupIndex !== 0 ? <IconButton size="small" color="error"><DeleteIcon /></IconButton> : null}
                </CardActions>
            </Card>
        );
    }) : (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h6" sx={{ textAlign: 'center', mb: 0, pb: 0 }}>Info not available</Typography>
            </CardContent>
        </Card>
    );
};

export default function DashboardIdChip({ dashboardId, userType }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [groupData, setGroupData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setLoading(true);
        axios.post('/api/groups/location', { groupType: userType.substring(0, 1), ids: [dashboardId] }).then(resp => {
            setLoading(false);
            setGroupData(resp.data);
        }).catch(error => setLoading(false));
    };

    const handlePopoverClose = () => {
        setGroupData([]);
        setAnchorEl(null);
    };

    const id = 'popover' + dashboardId + userType;
    const anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
    const transformOrigin = { vertical: 'top', horizontal: 'center' };

    return (
        <React.Fragment>
            <Chip label={dashboardId} size="small" color='primary' aria-describedby={id} onClick={handlePopoverOpen} sx={{ borderRadius: '4px' }} />
            <Popover id={id} open={!!anchorEl} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Paper sx={{ p: 2, width: 300 }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, pb: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : <GroupInfoCard groupData={groupData} userType={userType} />}
                </Paper>
            </Popover>
        </React.Fragment>
    );
}