import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography,Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-52px',right:'-8px',position:'absolute'
    ,padding:'6px 12px',fontSize:'14px',color:'#fff',marginRight:'12px'}}
      />
    </GridToolbarContainer>
  );
}

export default function OpportunityByMember() {

  const { year, month, period, quarter, vendorIds } = useSelector(state => state.filter);
  const [rows, setMemberRows] = useState([]);
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, month, period, quarter, vendorId: vendorIds.map(row => row.dbaId) });
    if (vpName != '') {
      params.dropDown = [{ vpName: [vpName] }];

    }
    else {
      params.dropDown = [];
    }

    // if (selectedCategory1) {
    //   params = { ...params, categoryId: selectedCategory1 };
    //   }
    Promise.all([
      axios.post('/api/vendor/conversion/getTerminatedVpOpportunityByMember', { ...params }),

    ]).then(response => {
      setLoading(false);
      setMemberRows(response[0].data);
    });
  }, [year, month, period, quarter, vpName]);
  useEffect(() => {
    let params = { vendorId: vendorIds.map(row => row.dbaId),year };

    Promise.all([
      axios.post('/api/vendor/conversion/getTerminatedVpVendorNames', params),
    ]).then((values) => {
      setVpNames(values[0].data);
      console.log('setVpnames', values)


    });

  }, []);


  const columnMemberOpportunity = [
    // { field: "memberId", headerName: "MEMBER NAME", flex: 1 },

    { field: "memberName", headerName: "MEMBER NAME", flex: 1 },
    { field: "state", headerName: "STATE", width: 250,},
    { field: "mem_rank", headerName: "RANK", width: 150, 
    // renderCell:(params) => <FormatCurrency value= {params.value} />
    //  <LinearProgressWithVolume value= {params.value} />
    },

  ];

  const generateOppByMember=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("OppByMember", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${rows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false,
            fitToPage:true,
            fitToHeight:0
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Opportunity By Member";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["MEMBER NAME","STATE","RANK"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });

    
    rows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.memberName, obj.state, 
             obj.mem_rank]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 20;
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Opportunity By Member';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "OppByMember.xlsx");
    });
  
}



  return (
    <Paper sx={{ p: 2, mt: 5 }}>
      <Grid container spacing={3} columns={2}>

        <Grid item xs={3}>
          <Stack direction='row' spacing={34} >
            <div class="parent" style={{width:'100%'}}>
              <div class="block1" style={{width:'33.33%',float:'left'}}>
            <Typography variant='h6' sx={{ mb: 1 }}>Opportunity By Member</Typography>
            </div>
            <div class="block2" style={{width:'33.33%',float:'left'}}>
            <FormControl>

              <InputLabel>Select Supplier</InputLabel>
              <Select
                labelId="vp-name-select-small"
                id="vp-name-select-small"
                value={vpName}
                label="Select Supplier"
                sx={{ minWidth: 200, marginBottom: 2, minHeight: 40, float: 'right' }}
                onChange={(event) => setVpName(event.target.value)}
                size='small'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>
            </div>
            <div class="block3" style={{width:'33.33%',float:'right'}}>
            <Button variant="contained" onClick={() => generateOppByMember()} sx={{height:'24px',padding:'20px 10px',float:'right'}}><FileDownloadOutlinedIcon/>Export</Button>
            </div>
            </div>
            </Stack>
            <div>
          <DataGrid sx={{ height: 500 }} disableColumnMenu density='compact' loading={loading}
            rows={rows} columns={columnMemberOpportunity}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
            rowsPerPageOptions={[10,50,100]} 
            getRowId={(row) => (row.memberId)}
            //  selectionModel={selectedMembers} onSelectionModelChange={setSelectedMembers}
            //             components={{
            //   Toolbar: CustomToolbar,
            //  }}
            //  localeText={{
            //   toolbarExport: "export"
            // }}          
            />
            </div>
        </Grid>
      </Grid>
    </Paper>
  );
}