import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormatCurrency, FormatValue, RebateAttainment, RebateCritera } from '../formatter';

export default function VpvTableRowDetail({ row, programData }) {
    const tierList = Object.keys(programData).sort();
    return (
        <Box sx={{ bgcolor: '#E3F2FD', padding: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Typography variant="caption">Volume CY</Typography>
                    <Typography><FormatCurrency value={row.volumeCY} /></Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="caption">Volume PY Total</Typography>
                    <Typography><FormatCurrency value={row.salesPYTotal} /></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="caption">Source</Typography>
                    <Typography>{row.source == "Member" ? 'Member Trans Data' :
                                                         row.source == "VP Quarterly Data"?'VP Verified Data':
                                                         row.source == 'VP Transaction Data'?'VP Trans Data':row.source}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container columns={4} spacing={2}>
                        <Grid item xs={2}><Typography variant="caption">Target Criteria</Typography></Grid>
                        <Grid item xs={1}><Typography variant="caption">Target</Typography></Grid>
                        <Grid item xs={1}><Typography variant="caption">Gap</Typography></Grid>
                        {programData && programData[row.tier] ? programData[row.tier].map((criteria, criteriaIndex) => (
                            <React.Fragment key={'ctr1-' + criteriaIndex}>
                                <Grid item xs={2}><RebateCritera criteriaName={criteria.criteriaName} criteriaSetting={criteria.criteriaSetting} min={criteria.min} /></Grid>
                                <Grid item xs={1}><FormatCurrency value={criteria.target} /></Grid>
                                <Grid item xs={1}><FormatCurrency value={criteria.gap} /></Grid>
                            </React.Fragment>
                        )) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Paper elevation={1} sx={{ mt: 1, p: 2 }} square>
                <Stack spacing={1}>
                    <Grid container spacing={1} columns={12}>
                        <Grid item xs={1}></Grid>
                        {tierList.map(tier => (
                            <Grid item xs={1} key={'tier-' + tier} textAlign='center'>
                                <Typography variant="caption">Tier {tier}</Typography>
                            </Grid>
                        ))}
                        <Grid item xs={12 - (tierList.length + 1)}></Grid>
                        <Grid item xs={1}>Rebate</Grid>
                        {tierList.map(tier => {
                            const criteria = programData[tier].sort((a, b) => a.attainment - b.attainment)[0];
                            return (
                                <Grid item xs={1} key={'tier1-' + tier} textAlign='center'>
                                    <FormatValue value={criteria.payoutValue} format={criteria.payoutSetting} />
                                </Grid>
                            );
                        })}
                        <Grid item xs={12 - (tierList.length + 1)}></Grid>
                        <Grid item xs={1}>Attainment</Grid>
                        {tierList.map(tier => {
                            const criteria = programData[tier].sort((a, b) => a.attainment - b.attainment)[0];
                            return (
                                <Grid item xs={1} key={'tier2-' + tier} textAlign='center'>
                                    <RebateAttainment attainment={criteria.attainment} />
                                </Grid>
                            );
                        })}
                        <Grid item xs={12 - (tierList.length + 1)}></Grid>
                        <Grid item xs={1}>Target</Grid>
                        {tierList.map(tier => {
                            const criteria = programData[tier].sort((a, b) => a.attainment - b.attainment)[0];
                            return (
                                <Grid item xs={1} key={'tier3-' + tier} textAlign='center'>
                                    <FormatCurrency value={criteria.target} />
                                </Grid>
                            );
                        })}
                        <Grid item xs={12 - (tierList.length + 1)}></Grid>
                    </Grid>
                </Stack>
            </Paper>
        </Box>
    );
}