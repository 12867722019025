import { Avatar, Box, Chip, Toolbar } from '@mui/material';
import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';
import PageFiltersVpv from '../../common/filter/page-filters-vpv';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import VpvTable from '../../common/vpv-table';
import VendorOverviewVerifiedSummary from './vendor-overview-verified-summary';
import axios from "axios";
import { useSnackbar } from 'notistack';

export default function VendorOverview() {
    const { vendorIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/'+ 15).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);

    return (
        <PageContent ready={vendorIds.length > 0}>
            <SecondaryAppBar>
                <Toolbar>
                    {vendorIds.map(vendor => <Chip key={'dba-' + vendor.dbaId} avatar={<Avatar>V</Avatar>} label={vendor.dbaName} sx={{ mr: 2 }} />)}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFiltersVpv />
                </Toolbar>
            </SecondaryAppBar>
            <VendorOverviewVerifiedSummary />
            <VpvTable />
        </PageContent>
    );
}
