import { Chip, Grid, Paper, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { FormatCurrency } from '../../common/formatter';

const monthsCategory = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const quarterCategory = ['Qtr 1', 'Qtr 2', 'Qtr 3', 'Qtr 4'];

export default function AdminOpportunityGraphs() {

    const [volumeTrendData, setVolumeTrendData] = useState([]);
    const [rebateTrendData, setRebateTrendData] = useState([]);
    const { period, year, month, quarter } = useSelector(state => state.filter);

    useEffect(() => {
        const params = ({ year, month, period, quarter });
        Promise.all([
            axios.post("/api/corporate/opportunity/getOpportunitiesVolVsVolOpp", params),
            axios.post("/api/corporate/opportunity/getOpportunitiesRebateVsRebateOpp", params)
        ]).then(([volumeTrendResponse, rebateTrendResponse]) => {
            setVolumeTrendData(volumeTrendResponse.data.map(row => ({ ...row, quarter: Math.ceil(row.month / 3) })));
            setRebateTrendData(rebateTrendResponse.data.map(row => ({ ...row, quarter: Math.ceil(row.month / 3) })));
        });
    }, [year]);

    const volumeChartOptions = {
        chart: { id: 'volume-opp-trend', type: 'line', toolbar: { show: false }, zoom: { enabled: true } },
        markers: { size: 6 },
        xaxis: { type: 'category', categories: period === 'qtr' ? quarterCategory : monthsCategory },
        yaxis: { labels: { formatter: (value) => FormatCurrency({ value }) } }
    };

    const rebateChartOptions = {
        chart: { id: 'react-opp-trend', type: 'line', toolbar: { show: false }, zoom: { enabled: true } },
        markers: { size: 6 },
        xaxis: { type: 'category', categories: period === 'qtr' ? quarterCategory : monthsCategory },
        yaxis: { labels: { formatter: (value) => FormatCurrency({ value }) } }
    };

    let volumeTrendSeries = [];
    let rebateTrendSeries = [];

    switch (period) {
        case 'qtr':
            const volumeQuarterTrendData = {};
            const rebateQuarterTrendData = {};
            volumeTrendData.forEach(row => {
                if (volumeQuarterTrendData[row.quarter]) {
                    volumeQuarterTrendData[row.quarter][0] = volumeQuarterTrendData[row.quarter][0] + row.axis1;
                    volumeQuarterTrendData[row.quarter][1] = volumeQuarterTrendData[row.quarter][1] + row.axis2;
                } else {
                    volumeQuarterTrendData[row.quarter] = [row.axis1, row.axis2];
                }
            });
            rebateTrendData.forEach(row => {
                if (rebateQuarterTrendData[row.quarter]) {
                    rebateQuarterTrendData[row.quarter][0] = rebateQuarterTrendData[row.quarter][0] + row.axis1;
                    rebateQuarterTrendData[row.quarter][1] = rebateQuarterTrendData[row.quarter][1] + row.axis2;
                } else {
                    rebateQuarterTrendData[row.quarter] = [row.axis1, row.axis2];
                }
            });
            volumeTrendSeries.push({ name: 'Volume', data: [1, 2, 3, 4].map(row => volumeQuarterTrendData[row][0]) });
            volumeTrendSeries.push({ name: 'Volume Opportunity', data: [1, 2, 3, 4].map(row => volumeQuarterTrendData[row][1]) });
            rebateTrendSeries.push({ name: 'Rebate', data: [1, 2, 3, 4].map(row => rebateQuarterTrendData[row][0]) });
            rebateTrendSeries.push({ name: 'Rebate Opportunity', data: [1, 2, 3, 4].map(row => rebateQuarterTrendData[row][1]) });
            break;
        case 'mth':
            volumeTrendSeries.push({ name: 'Volume', data: volumeTrendData.map(row => row.axis1) });
            volumeTrendSeries.push({ name: 'Volume Opportunity', data: volumeTrendData.map(row => row.axis2) });
            rebateTrendSeries.push({ name: 'Rebate', data: rebateTrendData.map(row => row.axis1) });
            rebateTrendSeries.push({ name: 'Rebate Opportunity', data: rebateTrendData.map(row => row.axis2) });
            break;
        default:
            volumeTrendSeries.push({ name: 'Volume', data: volumeTrendData.map(row => row.cumulative_axis1) });
            volumeTrendSeries.push({ name: 'Volume Opportunity', data: volumeTrendData.map(row => row.cumulative_axis2) });
            rebateTrendSeries.push({ name: 'Rebate', data: rebateTrendData.map(row => row.cumulative_axis1) });
            rebateTrendSeries.push({ name: 'Rebate Opportunity', data: rebateTrendData.map(row => row.cumulative_axis2) });
            break;
    }

    return (
        <Paper sx={{ mb: 3, p: 2 }}>
            <Grid container columns={2} spacing={3}>
                <Grid item xs>
                    <Toolbar disableGutters sx={{ mb: 2, justifyContent: "center" }}>
                        <Typography variant='h6'>Volume <Chip label='VS' /> Volume Opportunity</Typography>
                    </Toolbar>
                    <Chart options={volumeChartOptions} series={volumeTrendSeries} height="400" />
                </Grid>
                <Grid item xs>
                    <Toolbar disableGutters sx={{ mb: 2, justifyContent: "center" }}>
                        <Typography variant='h6'>Rebate <Chip label='VS' /> Rebate Opportunity</Typography>
                    </Toolbar>
                    <Chart options={rebateChartOptions} series={rebateTrendSeries} height="400" />
                </Grid>
            </Grid>
        </Paper>
    );
}
