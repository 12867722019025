export const tabs = ['All'];

export const RebateTabsMap = {
    "Annual Individual Incentive": 'Incentives',
    "Individual Annual Incentive": 'Incentives',
    "Annual Growth Incentive": 'Incentives',
    "Annual Group Incentive": 'Incentives',
    "Group Annual Incentive": 'Incentives',
    "Quarterly Flat": 'Guaranteed',
    "Quarterly Individual Incentive": 'Incentives',
    "Quarterly Group Incentive": 'Incentives',
    "Quarterly Growth Incentive": 'Incentives',
    "Warranty": 'Special',
    "BuyBLUE": 'Special',
    "EDI": 'Special',
    "PrimeTime": 'Special',
    "Vendor Rebate Deduction": 'Special',
    "Conversion":'Conversion'
};

export const searchFn = (row, searchQuery) => {
    return row.memberName.toLowerCase().includes(searchQuery.toLowerCase())
        || row.vendorName.toLowerCase().includes(searchQuery.toLowerCase())
        || row.programName.toLowerCase().includes(searchQuery.toLowerCase());
};

export const getRouteTypeOptions = ({ pathname }) => {
    if (String(pathname).startsWith('/member')) {
        return { routeType: 'MEMBER', useMemberFilter: true, useVendorFilter: false };
    } else if (String(pathname).startsWith('/vendor')) {
        return { routeType: 'VENDOR', useMemberFilter: false, useVendorFilter: true };
    } else {
        return { routeType: 'CORPORATE', useMemberFilter: false, useVendorFilter: false };
    }
};
