import SearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import * as React from 'react';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(2),
        width: "auto",
    }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1.4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(0.7, 1, 0.7, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "15ch",
        }
    }
}));

export default function MaterialSearch({ placeholder, value, onChange }) {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase value={value || ''} onChange={onChange} placeholder={placeholder || ''} inputProps={{ 'aria-label': 'search' }} />
        </Search>
    );
}
