import { Grid, Paper, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import OverviewCard from '../../common/overview-card';

const defaultCards = [
    { total: 0, "cardName": "Total Member Purchases", "yoy": null, "updatedDate": null },
    { total: 0, "cardName": "Missed Opportunity (Purchases)", "yoy": null, "updatedDate": null },
    { total: 0, "cardName": "Total Rebate", "yoy": null, "updatedDate": null },
    { total: 0, "cardName": "Missed Opportunity (Rebate)", "yoy": null, "updatedDate": null }
];

export default function AdminOpportunitySummaryCards() {

    const { period, year, month, quarter } = useSelector((state) => state.filter);
    const [data, setData] = useState(defaultCards);

    useEffect(() => {
        const params = modifyFiltersToOldFormat({ year, month, period, quarter });
        axios.post("/api/corporate/opportunity/getOpportunitiesCards", params).then(response => setData(response.data)).catch(() => setData(defaultCards));
    }, [year,month,period,quarter]);

    return (
        <Paper sx={{ p: 2, mb: 3 }}>
            <Toolbar disableGutters sx={{ mb: 2 }}>
                <Typography variant="h6">Missed Opportunity Summary</Typography>
            </Toolbar>
            <Grid container spacing={2} columns={data.length}>
                {data.map(card => {
                    return <Grid item xs><OverviewCard {...{ title: card.cardName, value: card.total, yoy: card.yoy, updateDate: card.updatedDate }} /></Grid>
                })}
            </Grid>
        </Paper>
    );
}