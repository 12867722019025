import { Box, Toolbar ,Stack} from '@mui/material';
import React, { useState,useEffect } from 'react';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import ConversionOpportunities from './opportunity';
import OpportunityByMember from './opportunityByMember';
import ConversionOpportunity from './conversionOpportunity';
import axios from "axios";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


function AdminTerminatedOpportunity() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/'+ 5).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <Box sx={{ width: "100%"}}>
                    <Stack direction='row' spacing={2} >
                      <Box sx={{ width: "50%" }}>
                            <OpportunityByMember />
                        </Box>
                        <Box sx={{ width: "50%" }}>
                            <ConversionOpportunities />

                        </Box>
                    </Stack>
                    <Stack direction='row' spacing={4} sx={{ mt: 2}} >

                    <ConversionOpportunity/>
               </Stack>
                </Box>
        </PageContent>
    );
}

export default AdminTerminatedOpportunity;