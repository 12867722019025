import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MONTH } from '../../../middleware/filter';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


export default function FilterMonth() {
    const dispatch = useDispatch();
    const selectedMonth = useSelector(state => state.filter.month);
    // const check=useSelector(state=>state.filter)
    // console.log('filtermonthselectedMonth',check)
//  
    const handleChange = (event) => dispatch({ type: SET_MONTH, payload: parseInt(event.target.value) });
    return (
        <FormControl sx={{ minWidth: 120 }} size="small" variant="filled">
            <InputLabel id="month-select-small">Month</InputLabel>
            <Select labelId="month-select-small" id="month-select-small" value={selectedMonth} label="Month" onChange={handleChange}>
                {months.map(month => <MenuItem key={month} value={month}>{monthNames[month - 1]}</MenuItem>)}
            </Select>
        </FormControl>
    );
}
