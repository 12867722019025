import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, TableFooter, TablePagination, TextField } from '@mui/material';
import Box from '@mui/material/Box';
// import { makeStyles } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton, Paper, TableSortLabel, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import * as XLSX from 'xlsx';
import { getComparator, modifyFiltersToOldFormat, stableSort } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}




export default function ConversionOpportunity() {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [order1, setOrder1] = useState('asc');
    const [orderBy1, setOrderBy1] = useState('');
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [pageconversionOpportunityPO, setPageconversionOpportunityPO] = useState(0);
    const [rowsPerPageconversionOpportunityPO, setRowsPerPageconversionOpportunityPO] = useState(10);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const textInput = React.useRef(null);
    const textInput1 = React.useRef(null);

    const [tableDataBackup, setTableBackup] = useState("");
    const [tableDataBackup1, setTableBackup1] = useState("");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);

    useEffect(() => {
        const params = modifyFiltersToOldFormat({ year, month, period, quarter, vendorId: vendorIds.map(row => row.dbaId), dropDown: [] });

        // if(VpName != ''){
        //     dropDown =[{vendorName:[VpName]}];

        // }
        // else {
        //     dropDown =[];
        // }
        setLoading(true);
        axios.post('/api/corporate/conversion/getTerminatedVpOppConversionOpp', params)
            .then(response => {
                setLoading(false);
                setTableData1(response.data);
                setTableBackup(response.data);

            })
        axios.post('/api/corporate/conversion/getTerminatedVpOppConversionOppPoDetails', params)
            .then(response => {
                setLoading(false);
                setTableData2(response.data);
                setTableBackup1(response.data);

            })
    }, [period, year, month, quarter, memberIds, vendorIds]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePageconversionOpportunityPO = (event, pageconversionOpportunityPO) => {
        setPageconversionOpportunityPO(pageconversionOpportunityPO);
    };

    const handleChangeRowsPerPageconversionOpportunityPO = (event) => {
        setRowsPerPageconversionOpportunityPO(parseInt(event.target.value, 10));
        setPageconversionOpportunityPO(0);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const handleRequestSort1 = (event, property) => {
        const isAsc = orderBy1 === property && order1 === 'asc';
        setOrder1(isAsc ? 'desc' : 'asc');
        setOrderBy1(property);
    };
    const createSortHandler1 = (property) => (event) => handleRequestSort1(event, property);



    const conversionOpportunity = [
        { key: 'memberName', label: 'MEMBER NAME' },
        { key: 'productGroup', label: 'PRODUCT GROUP' },
        { key: 'volume', label: 'VOLUME' },
        { key: 'vp1', label: 'VP 1' },
        { key: 'vp2', label: 'VP 2' },
        { key: 'vp3', label: 'VP 3' },
        { key: 'vp4', label: 'VP 4' }

    ];
    const conversionOpportunityPO = [
        { key: 'memberName', label: 'MEMBER NAME' },

        { key: 'poCount', label: 'PO COUNT' },
        { key: 'volume', label: 'VOLUME' },
        { key: 'avgVolPerPo', label: 'AVERAGE VOLUME/PO' },

    ];
    const clearAll = () => {
        setTableData1(tableDataBackup);
        textInput.current.value = '';

    }
    const clearAll1 = () => {
        setTableData2(tableDataBackup1);
        textInput1.current.value = '';

    }

    const requestSearch = (searchedVal) => {
        if (searchedVal == '') {
            setTableData1(tableDataBackup);
        }
        setTableData1(tableDataBackup);
        const filteredRows = tableDataBackup.filter((row) => {
            return row.memberName.toLowerCase().includes(searchedVal);
        });
        console.log(filteredRows, "filteredRows")
        setTableData1(filteredRows);
    }

    const requestSearch1 = (searchedVal1) => {
        console.log(searchedVal1)
        if (searchedVal1 == '') {
            setTableData2(tableDataBackup1);
        }
        setTableData2(tableDataBackup1);
        const filteredRows1 = tableDataBackup1.filter((row1) => {
            return row1.memberName.toLowerCase().includes(searchedVal1);
        });
        console.log(filteredRows1, "filteredRows")
        setTableData2(filteredRows1);
    }

    // const handleExport1 = () => {
    // var wb = XLSX.utils.book_new(),
    // ws = XLSX.utils.json_to_sheet(tableData1);
    // XLSX.utils.book_append_sheet(wb, ws, "conversionOpportunity")
    // XLSX.writeFile(wb, "conversionOpportunity.xlsx")
    // }
    // const handleExport2 = () => {
    // var wb = XLSX.utils.book_new(),
    // ws = XLSX.utils.json_to_sheet(tableData2);
    // XLSX.utils.book_append_sheet(wb, ws, "conversionOpportunityPoDetails")
    // XLSX.writeFile(wb, "conversionOpportunityPoDetails.xlsx")
    // }

    const handleExport1=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("conversionOpportunity", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${tableData1.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Conversion Opportunity";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["MEMBER NAME","PRODUCT GROUP","VOLUME","VP 1","VP 2","VP 3","VP 4"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");
        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
         
        
        tableData1.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.memberName ,obj.productGroup,
                 '$'+Math.round(obj.volume),obj.vp1,obj.vp2,obj.vp3,obj.vp4]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 50;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 50;
        worksheet.getColumn(5).width = 50;
        worksheet.getColumn(6).width = 50;
        worksheet.getColumn(7).width = 50;
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Conversion Opportunity';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "conversionOpportunity.xlsx");
        });
      }
      const handleExport2=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("conversionOpportunityPoDetails", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${tableData2.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Conversion Opportunity PO Details";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["MEMBER NAME","PO COUNT","VOLUME","AVERAGE VOLUME/PO"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");

        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
         
        
        tableData2.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.memberName ,obj.poCount,
                 '$'+Math.round(obj.volume),'$'+Math.round(obj.avgVolPerPo)]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 50;
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 50;
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Conversion Opportunity PO Details';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "conversionOpportunityPoDetails.xlsx");
        });
      }
    
    return (

        <TableContainer component={Paper}   sx={{ height: 600 }}>
            <Typography variant='h6' sx={{ m: 2 }}>Category Overview   {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</Typography>

            <Box sx={{ bgcolor: 'background.paper' }}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="half"
                        aria-label="half width tabs example"
                    >
                        <Tab label="Conversion Opportunity" {...a11yProps(0)} />
                        <Tab label="Conversion Opportunity PO Details" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}

                        <Paper component="div" sx={{ p: '0px 4px', float: 'right', display: 'flex', alignItems: 'center', width: 500 }} elevation={0}>
                            <TextField inputRef={textInput} id="standard-basic" variant="standard" placeholder="Search" onChange={(searchVal) => requestSearch(searchVal.target.value)} />
                            <IconButton type="submit" sx={{ p: '2px' }} aria-label="search"><SearchIcon /></IconButton>
                            <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} onClick={() => clearAll()}>Clear All</Button>
                            <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} onClick={handleExport1}><FileDownloadOutlinedIcon/>Export</Button>



                        </Paper>
                        <Paper square>

                            <Table aria-label="collapsible table" density='compact' stickyHeader square >
                                <TableHead>
                                    <TableRow>
                                        {conversionOpportunity.map(headerCell => (
                                            <TableCell
                                                key={headerCell.key}
                                                align={headerCell.align ? headerCell.align : 'left'}
                                                sortDirection={orderBy === headerCell.key ? order : false}
                                            >
                                                <TableSortLabel active={orderBy === headerCell.key} direction={orderBy === headerCell.key ? order : 'asc'} onClick={createSortHandler(headerCell.key)}>
                                                    {headerCell.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {(rowsPerPage > 0 ? stableSort(tableData1, getComparator(order, orderBy)).
                                        slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : tableData1).map((row, rowIdx) => (
                                            <TableRow key={row.memberId + rowIdx}>
                                                <TableCell component="th" scope="row" align="left">
                                                    {(row.memberName).toUpperCase()}
                                                </TableCell>

                                                <TableCell align="left">{row.productGroup}</TableCell>
                                                <TableCell align="left"><FormatCurrency value={row.volume} /></TableCell>
                                                <TableCell align="left">{row.vp1}</TableCell>
                                                <TableCell align="left">{row.vp2}</TableCell>
                                                <TableCell align="left">{row.vp3}</TableCell>
                                                <TableCell align="left">{row.vp4}</TableCell>

                                            </TableRow>
                                        ))}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            colSpan={conversionOpportunity.length}
                                            count={tableData1.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        // ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Paper component="div" sx={{ p: '0px 4px', float: 'right', display: 'flex', alignItems: 'center', width: 500 }} elevation={0}>
                            {/* <InputBase type = "text" sx={{ ml: 1, flex: 1 }} value='' placeholder="Search"  onChange={requestSearch} inputProps={{ 'aria-label': 'search google maps' }} /> */}
                            <TextField inputRef={textInput1} id="standard-basic1" variant="standard" placeholder="Search" onChange={(searchVal1) => requestSearch1(searchVal1.target.value)} />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search"><SearchIcon /></IconButton>
                            <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} onClick={() => clearAll1()}>Clear All</Button>
                           <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} onClick={handleExport2}><FileDownloadOutlinedIcon/>Export</Button>



                        </Paper>
                        <Paper square>

                            <Table aria-label="collapsible table" density='compact' stickyHeader square >                            
                            <TableHead>
                                <TableRow>
                                    {conversionOpportunityPO.map(conversionOpportunityPOheaderCell => (
                                        <TableCell
                                            key={conversionOpportunityPOheaderCell.key}
                                            align={conversionOpportunityPOheaderCell.align ? conversionOpportunityPOheaderCell.align : 'left'}
                                            sortDirection={orderBy1 === conversionOpportunityPOheaderCell.key ? order1 : false}
                                        >
                                            <TableSortLabel active={orderBy1 === conversionOpportunityPOheaderCell.key} direction={orderBy1 === conversionOpportunityPOheaderCell.key ? order1 : 'asc'} onClick={createSortHandler1(conversionOpportunityPOheaderCell.key)}>
                                                {conversionOpportunityPOheaderCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                                <TableBody>
                                    {(rowsPerPageconversionOpportunityPO > 0 ? stableSort(tableData2, getComparator(order1, orderBy1)).
                                        slice(pageconversionOpportunityPO * rowsPerPageconversionOpportunityPO, pageconversionOpportunityPO * rowsPerPageconversionOpportunityPO + rowsPerPageconversionOpportunityPO)
                                        : tableData2).map((conversionOpportunityPO, idx) => (
                                            <TableRow key={conversionOpportunityPO.memberName + idx}>
                                                <TableCell component="th" scope="row" align="left">
                                                    {(conversionOpportunityPO.memberName).toUpperCase()}
                                                </TableCell>
                                                <TableCell align="left">{conversionOpportunityPO.poCount}</TableCell>
                                                <TableCell align="left"><FormatCurrency value={conversionOpportunityPO.volume} /></TableCell>
                                                <TableCell align="left"><FormatCurrency value={conversionOpportunityPO.avgVolPerPo} /></TableCell>

                                            </TableRow>
                                        ))}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            colSpan={conversionOpportunityPO.length}
                                            count={tableData2.length}
                                            rowsPerPage={rowsPerPageconversionOpportunityPO}
                                            page={pageconversionOpportunityPO}
                                            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                            onPageChange={handleChangePageconversionOpportunityPO}
                                            onRowsPerPageChange={handleChangeRowsPerPageconversionOpportunityPO}
                                        // ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Paper>
                    </TabPanel>

                </SwipeableViews>
            </Box>

        </TableContainer>

    );
}