import React from 'react';
import { useRoutes } from 'react-router-dom';
import Admin from './admin/admin';
import AdminCategoryDetails from './admin/adminCategoryDetails';
import AdminCompliance from './admin/adminCompliance';
import AdminDataQuality from './admin/adminDataQuality';
import AdminFeedback from './admin/adminFeedback';
import AdminRebateData from './admin/adminRebateData';
import AdminTerminatedOpportunity from './admin/adminTerminatedOpportunity';
import AdminDirectory from './admin/directory/admin-directory';
import AdminOpportunity from './admin/opportunity/admin-opportunity';
import AdminOverview from './admin/overview/admin-overview';
import HttpError from './common/http-error';
import RequireAuth from './common/require-auth';
import BluehawkHome from './home/bluehawk-home';
import Login from './login';
import ForgotPassword from './login/forgot-password';
import SetPassword from './login/set-password';
import Invoice from './member/invoice';
import Member from './member/member';
import MemberOverview from './member/overview';
import PurchaseOrder from './member/purchaseOrder';
import TerminatedOpportunity from './member/terminatedOpportunity/index';
import MemberVerified from './member/verified/member-verified';
import Invoices from './vendor/invoices';
import MarketShare from './vendor/marketShare';
import Opportunity from './vendor/Opportunity';
import VendorOverview from './vendor/overview/vendor-overview';
import ConversionOpportunity from './vendor/terminatedOpportunity';
import Vendor from './vendor/vendor';

export default function RouterModule() {

    const routeConfig = useRoutes([
        {
            path: "/", element: <BluehawkHome />, children: [
                {
                    path: 'admin', element: <RequireAuth><Admin /></RequireAuth>, children: [
                        { index: true, element: <AdminOverview /> },
                        { path: 'rebateData', element: <AdminRebateData /> },
                        { path: 'opportunities', element: <AdminOpportunity /> },
                        { path: 'feedback', element: <AdminFeedback /> },
                        { path: 'terminatedOpportunities', element: <AdminTerminatedOpportunity /> },
                        { path: 'categoryDetails', element: <AdminCategoryDetails /> },
                        { path: 'compliance', element: <AdminCompliance /> },
                        { path: 'dataQuality', element: <AdminDataQuality /> },
                        { path: 'market-share', element: <MarketShare /> },
                        { path: 'directory', element: <AdminDirectory /> },
                        { path: '*', element: <HttpError code={404} message='Nothing to see here!' /> }
                    ]
                },
                {
                    path: "member/:groupIds", element: <RequireAuth><Member /></RequireAuth>, children: [
                        { index: true, element: <MemberVerified /> },
                        { path: 'overview', element: <MemberOverview /> },
                        { path: 'po', element: <PurchaseOrder /> },
                        { path: 'invoice', element: <Invoice /> },
                        { path: 'conversion', element: <TerminatedOpportunity /> },
                        { path: '*', element: <HttpError code={404} message='Nothing to see here!' /> }
                    ]
                },
                {
                    path: 'vendor/:groupIds', element: <RequireAuth><Vendor /></RequireAuth>, children: [
                        { index: true, element: <VendorOverview /> },
                        { path: 'opportunity', element: <Opportunity /> },
                        { path: 'invoice', element: <Invoices /> },
                        { path: 'conversion', element: <ConversionOpportunity /> },
                        { path: 'market-share', element: <MarketShare /> },
                        { path: '*', element: <HttpError code={404} message='Nothing to see here!' /> }
                    ]
                }
            ]
        },
        
        { path: "/login", element: <Login /> },
        { path: "reset/:token", element: <SetPassword /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: '*', element: <HttpError code={404} message='Nothing to see here!' /> }
    ]);

    return routeConfig;
}