import { Avatar, Button, Checkbox, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_VENDOR_IDS } from '../../../middleware/filter';

export default function VendorSelectionButton({ useMemberFilter }) {

    const dispatch = useDispatch();
    const { year, memberIds, vendorIds } = useSelector(state => state.filter);
    const [open, setOpen] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [checked, setChecked] = useState([]);
    const getDependencies = () => useMemberFilter ? [year, memberIds] : [year];

    const getParams = () => {
        let params = { year };
        if(useMemberFilter) {
            params = {...params, memberId: memberIds.map(row => row.dbaId)};
        }
        return params;
    };

    useEffect(() => {
        axios.post('api/vpv/vendors', getParams()).then(response => {
            dispatch({ type: SET_VENDOR_IDS, payload: [] });
            setVendorList(response.data);
        });
    }, getDependencies());

    useEffect(() => {
        setChecked(vendorIds && vendorIds.length > 0 ? vendorIds.map(row => row.dbaId) : []);
    }, [vendorIds]);

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClose = () => {
        dispatch({ type: SET_VENDOR_IDS, payload: vendorList.filter(row => checked.includes(row.dbaId)) });
        setOpen(false);
    };

    const handleCancel = () => {
        setChecked(vendorIds.map(row => row.dbaId));
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant='outlined' onClick={() => setOpen(true)}>Select VP</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Select Vendor Partner</DialogTitle>
                <List sx={{ width: '100%', maxWidth: 700, minWidth: 500, overflow: 'auto', maxHeight: 500, bgcolor: 'background.paper' }}>
                    {vendorList.map((vendor) => {
                        const labelId = `checkbox-list-label-${vendor.dbaId}`;
                        return (
                            <ListItem key={labelId} disablePadding secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={() => handleToggle(vendor.dbaId)}
                                    checked={checked.indexOf(vendor.dbaId) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            }>
                                <ListItemButton role={undefined} onClick={() => handleToggle(vendor.dbaId)} dense>
                                    <ListItemAvatar><Avatar>VP</Avatar></ListItemAvatar>
                                    <ListItemText id={labelId} primary={vendor.dbaName} secondary={vendor.dbaId === vendor.parentId ? 'PARENT' : 'DBA'} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleClose}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
