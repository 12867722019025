import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, IconButton, Paper, Typography, Stack, FormControl, InputLabel, MenuItem, Select, Switch, FormControlLabel, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search'
import {Tabs,Tab,TextField} from '@mui/material';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ float: 'right' }}>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        sx={{
          backgroundColor: '#556cd6', '&:hover': {
            backgroundColor: "#556cd6",
          },
          top: '-52px', right: '-8px', position: 'absolute'
          , padding: '6px 12px', fontSize: '14px', color: '#fff', marginRight: '12px'
        }}
      />
    </GridToolbarContainer>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function toTitleCase(str) {
  const titleCase = str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return titleCase;
}
 

export default function MemberOpportunity() {

  const { year, month, period, quarter, memberIds } = useSelector(state => state.filter);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [supplierOppRows, setSupplierOppRows] = useState([]);
  const [categoryRows, setCategoryRows] = useState([]);
  const [openCategoryDialogpopup, setopenCategoryDialogpopup] = useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openCategoryDialogApproved,setOpenCategoryDialogApproved]=useState(false);
  const textInput = React.useRef(null);
  const textInput1 = React.useRef(null);
  const textInput2 = React.useRef(null);
  const textInput3 = React.useRef(null);
  const textInput4 = React.useRef(null);
  const [tableDataBackupRows, setTableBackupRows] = useState("");
  const [tableDataBackupSupplier, setTableBackupSupplier] = useState("");
  const [tableDataBackupCategory, setTableBackupCategory] = useState("");
  const [unapprovedSupplier] = useState();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [favourableVendorName, setFavourableVendorName] = useState(false);
  const [favourableVendorId, setFavourableVendorId] = useState(false);
  const [categorypopupRows, setCategorypopupRows] = useState("");
  const [tableDataBackupCategoryPopUpRows,setTableDataBackupCategoryPopUpRows]=useState("")
  const [categorypopupRowsApprovedSupplier, setCategorypopupRowsApprovedSupplier] = useState("");
  const [tableDataBackupCategoryPopUpRowsApprovedSupplier,setTableDataBackupCategoryPopUpRowsApprovedSupplier]=useState("")
  const [feedbackCategoryName, setFeedbackCategoryName] = useState();
  const [feedbackCategoryId, setFeedbackCategoryId] = useState();
  const [feedbackVendorName, setFeedbackVendorName] = useState();
  const [feedbackVendorId, setFeedbackVendorId] = useState();
  let [feedbackProductId,setFeedbackProductId]=useState('')

  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  let [approvedSupVendorId,setApprovedSupVendorId]=useState('');
  let [approvedSupVendorName,setApprovedSupVendorName]=useState('');
  let [approvedSupVendorProdDesc,setApprovedVendorProdDesc]=useState('');
  let [approvedSupProductId,setApprovedSupProductId]=useState('')
  const [vendorText, setVendorText] = useState('');
  const [parentName, setParentName] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const textInputfeedback = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);
  const [FavourableVpNames, setFavourableVpNames] = React.useState(['']);
  const [FavourableVpId, setFavourableVpId] = React.useState(['']);
  const [removeToggle, setRemoveToggle] = useState(false);
  const [removeToggle1, setRemoveToggle1] = useState(false);
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [Lists, setCategoryList] = React.useState(['']);
  const [Liststotal, setCategoryListtotal] = React.useState(['']);
  const [categoryIds,setCategoryIds]=useState('');
  const [Lists1, setCategoryList1] = React.useState(['']);
  const [ListsId1, setCategoryListId1] = React.useState(['']);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageSize1, setPageSize1] = React.useState(10);
  const [pageSize2, setPageSize2] = React.useState(10);
  const [pageSize3, setPageSize3] = React.useState(10);
  const [value,setValue]=useState(0);

  
  const handleChange = (event ,newValue) => {
    setValue(newValue);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleCategoryDialogpopupClose = (e) => {
    console.log('handleCategoryDialogClose')

  }
  const handleDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }
  const handleFeedbackDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }
  const handleCategoryDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }

  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, month, period, quarter, memberId: memberIds.map(row => row.dbaId) });
    Promise.all([
      axios.post('/api/member/member-data/getMemberDataVendorPartnerPurchasesData', { ...params }),
      axios.post('/api/member/member-data/getMemberDataUnapprovedSuppliersData', { ...params }),
      axios.post('/api/member/member-data/getMemberDataUnapprovedSuppliersByCategoryData', { ...params })

    ]).then(response => {
      setLoading(false);
      setRows(response[0].data);
      setTableBackupRows(response[0].data);
      setSupplierOppRows(response[1].data.map((row, index) => ({ ...row, id: index })));
      setTableBackupSupplier(response[1].data.map((row, index) => ({ ...row, id: index })));
      setCategoryRows(response[2].data);
      setTableBackupCategory(response[2].data)
      console.log('186 line',response)
    });
  }, [year, month, period, quarter, memberIds]);

  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, memberId: memberIds.map(row => row.dbaId) });
    Promise.all([
      axios.post('/api/common/getActiveVendorList', { ...params }),

    ]).then(response => {
      setLoading(false);
      setVpNames(response[0].data);

    });
  }, [year, month, period, quarter, memberIds]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      axios.post('/api/common/getBhCategoryList'),

    ]).then(response => {
      setLoading(false);
      setCategoryList(response[0].data);

    });
  }, []);

  const columnVpv = [
    { field: "vendorName", headerName: "VP NAME", width: 300 },
    { field: "purchase", headerName: "VP VOLUME", width: 130, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    { field: "rebateEstimate", headerName: "REBATE ESTIMATE", width: 130, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    { field: "potentialPurchase", headerName: "CONVERSION VOLUME", width: 150, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    { field: "potentialRebate", headerName: "POTENTIAL ADDITIONAL REBATE", flex:1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
  ];

  const columnSupplierOpportunity = [
    { field: "vendorName", headerName: "UNAPPROVED SUPPLIERS", width: 350 },
    { field: "volume", headerName: "VOLUME", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    // { field: "rebateOpportunity",headerName: "REBATE OPPORTUNITY", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    {
      field: "rebateOpportunity", headerName: "REBATE OPPORTUNITY", flex: 1, align: 'right', renderCell: (params) => (
        <React.Fragment>
          <FormatCurrency value={params.value}/>
       <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleClickOpen(params.row.vendorId, params.row.vendorName)}><OpenInNewIcon fontSize='inherit' /></IconButton>
            {/* {console.log('handleOpenCategoryDialog',params)} */}
        </React.Fragment>
      )
    },
    // {
    //   field: '',
    //   headerName: '',
    //   width: 50,
    //   align: "left",
    //   renderCell: (params) => (
    //     <React.Fragment>
    //       <IconButton sx={{ ml: 0.5 }} size="small"
    //         onClick={() => handleClickOpen(params.row.vendorId, params.row.vendorName)}><OpenInNewIcon fontSize='inherit' /></IconButton>
    //     </React.Fragment>
    //   ),
    // },
  ];

  const columnProductPurchases = [
    { field: "productId", headerName: "PROD ID", width: 200 },
    { field: "productDesc", headerName: "PROD DESC", width: 680 },
    { field: "vendorName", headerName: "UNAPPROVED SUPPLIER", width: 200 },
    {
      field: '',
      headerName: 'FEEDBACK',
      width: 120,
      align: "left",
      renderCell: (params) => (
        <React.Fragment>
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenCategoryFeedbackDialog(params.row.productId, params.row.productDesc, params.row.vendorId, params.row.vendorName)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      ),
    },
    { field: "salesOpportunity", headerName: "VOLUME", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },

    { field: "rebateOpportunity", headerName: "REBATE OPPORTUNITY", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },

  ]
  const columnProductPurchasesApprovedSupplier = [
    { field: "productId", headerName: "PROD ID", width: 200 },
    { field: "prodDesc", headerName: "PROD DESC", width: 310 },
    { field: "vendorName", headerName: "APPROVED SUPPLIER", width: 200 },
    {
      field: '',
      headerName: 'FEEDBACK',
      width: 120,
      align: "left",
      renderCell: (params) => (
        <React.Fragment>
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenCategoryFeedbackDialogApproved(params.row.productId, params.row.productDesc, params.row.vendorId, params.row.vendorName)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      ),
    },
    { field: "salesOpportunity", headerName: "SALES OPPORTUNITY", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },

    // { field: "rebateOpportunity", headerName: "REBATE OPPORTUNITY", flex: 1, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },

  ]
  const columnCategoryOpportunity = [
    {          
      field: "categoryId", headerName: "CATEGORY", width: 300, hide: 'true'
    },
    {
      field: "categoryName", headerName: "CATEGORY", width: 590, renderCell: (params) => (
        <React.Fragment>
          {params.value}
       <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenCategoryDialog(params.row.categoryName, params.row.id)}><OpenInNewIcon fontSize='inherit' /></IconButton>
            {/* {console.log('handleOpenCategoryDialog',params)} */}
        </React.Fragment>
      )
    },
    { field: "salesOpportunity", headerName: "VOLUME", width: 150, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    { field: "rebateOpportunity", headerName: "REBATE OPPORTUNITY", width: 180, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },
    {
      field: "favorableVendorName1", headerName: "VENDOR PARTNER ", width: 300,
      renderCell: (params) => (
        params.value ?
        <React.Fragment>
          {params.value}
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenFeedbackDialog(params.row.favorableVendorId1, params.row.favorableVendorName1, params.row.categoryName, params.row.categoryId, "Vendor Partner 1")}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
        : ''
      )
    },
    {
      field: "favorableVendorName2", headerName: "VENDOR PARTNER ", width: 300,
      renderCell: (params) => (
        params.value ?
        <React.Fragment>
          {params.value}
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenFeedbackDialog(params.row.favorableVendorId2, params.row.favorableVendorName2, params.row.categoryName, params.row.categoryId, "Vendor Partner 2")}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
        :''
      )
    },
    {
      field: "favorableVendorName3", headerName: "VENDOR PARTNER ", width: 300,
      renderCell: (params) => (
        params.value ?
        <React.Fragment>
          {params.value}
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenFeedbackDialog(params.row.favorableVendorId3, params.row.favorableVendorName3, params.row.categoryName, params.row.categoryId, "Vendor Partner 3")}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
        :''
      )
    },
    {
      field: "favorableVendorName4", headerName: "VENDOR PARTNER ", width: 250,
      renderCell: (params) => (
        params.value ?
        <React.Fragment>
          {params.value}
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenFeedbackDialog(params.row.favorableVendorId4, params.row.favorableVendorName4, params.row.categoryName, params.row.categoryId, "Vendor Partner 4")}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
        :''
      )
    }
  ];

  const handleOpenFeedbackDialog = (favorableVendorId, favorableVendorName, categoryName, categoryId, vp) => {
    setOpenFeedbackDialog(true);
    setFavourableVendorName(favorableVendorName);
    setFavourableVendorId(favorableVendorId)
    setCategoryName(categoryName);
    setCategoryId(categoryId);
    setVendorText(vp);

    console.log('open category dialog modal component');
  };


 
  const handleOpenCategoryDialog = (categoryName, categoryId) => {
    setopenCategoryDialogpopup(true);
    setCategoryId(categoryId)
    setCategoryName(categoryName)

    const params = modifyFiltersToOldFormat({
      year,
      month,
      period,
      quarter,
      memberId: memberIds.map((row) => row.dbaId),
    });
    params.dropDown = [{ bhCategoryName: [categoryName] }]
    params.bhCategoryId = categoryId
    Promise.all([
      axios.post('/api/member/member-data/getMemberDataUnapprovedSupplierPurhcaseByCategoryPopUp', 
      params),
      axios.post('/api/member/member-data/getMemberDataApprovedSupplierPurhcaseByCategoryPopUp', 
      params),

    ]).then(response => {
      setLoading(false);
      setCategorypopupRows(response[0].data);
      setTableDataBackupCategoryPopUpRows(response[0].data)
      setCategorypopupRowsApprovedSupplier(response[1].data);
      setTableDataBackupCategoryPopUpRowsApprovedSupplier(response[1].data)
      // console.log('promise all',response[0].data,response[1].data)
    })
  };

  const handleOpenCategoryFeedbackDialog = (productId, productDesc, vendorId, vendorName) => {
    console.log('handleOpenCategoryFeedbackDialogUnApproved open')
    setOpenCategoryDialog(true);
    console.log('open category dialog modal component', setFeedbackCategoryName(productDesc));
    setFeedbackCategoryName(productDesc)
    setFeedbackCategoryId(productId)
    setFeedbackVendorId(vendorId)
    setFeedbackVendorName(vendorName)
    console.log('setOpenCategoryDialog',setOpenCategoryDialog(true));
  };

  const handleOpenCategoryFeedbackDialogApproved = (productId, productDesc, vendorId, vendorName) => {

    console.log('handleOpenCategoryFeedbackDialogApproved open')
    setOpenCategoryDialogApproved(true);
  };



  const handleClickOpen = (vendorId, vendorName) => {
    console.log(vendorId, vendorName)
    setOpen(true);
    setVendorName(vendorName)
    setVendorId(vendorId)

  }

  const handleClose = () => {
    setOpen(false);
    setOpenCategoryDialog(false);
    setOpenCategoryDialogApproved(false);
    setOpenFeedbackDialog(false);
    setRemoveToggle(false)
    setRemoveToggle1(false)
    // setCategoryName('');
  };

  const handleClose1 = () => {
    setopenCategoryDialogpopup(false);
     setCategoryName('');

  };

  function CategoryDetailsDialogpopup({ openCategoryDialogpopup, handleClose1 }) {
    return (
      ''
    );
  }
  function UnapprovedSupplierDetailsDialog({ open, handleClose }) {
    return (
      ''
    );
  }

  function FavorableVendorFeedbackDetailsDialog({ openFeedbackDialog, handleClose }) {
    return (
      ''
    );
  }

  function CategoryDetailsDialog({ openCategoryDialog, handleClose }) {
    return (
      ''
    );
  }

  function CategoryDetailsDialogApproved({ open, handleClose }) {
    return (
      ''
    );
  }
  // Fot Interclicking functioanlity

  const onClickUnapprovedSupVol = (e) => {
    let unapprovedSupplier = e.row.vendorName;
    let SupVolparams = modifyFiltersToOldFormat({
      year,
      month,
      period,
      quarter,
      memberId: memberIds.map((row) => row.dbaId),
    });
    SupVolparams = {
      ...SupVolparams,
      dropDown: [{ vendorName: [unapprovedSupplier] }],
    };
    console.log("on click params value", SupVolparams);
    axios
      .post(
        "/api/member/member-data/getMemberDataUnapprovedSuppliersByCategoryData",
        SupVolparams
      )
      .then((response) =>
        // setCategoryRows(response.data.map((row, idx) => ({ ...row, id: idx })),
        setCategoryRows(response.data),
      );
      axios
      .post(
        "/api/member/member-data/getMemberDataUnapprovedSuppliersData",
        SupVolparams
      )
      .then((response) =>
        setSupplierOppRows(response.data.map((row, idx) => ({ ...row, id: idx })))
        // setSupplierOppRows(response.data)
      );

  };
  const onClickVendorPartnerVolume = (e) => {
    let vendorName = e.row.vendorName;
    let VendorVolparams = modifyFiltersToOldFormat({
      year,
      month,
      period,
      quarter,
      memberId: memberIds.map((row) => row.dbaId),
    });
    VendorVolparams = {
      ...VendorVolparams,
      dropDown: [{ unapprovedSupplier: [vendorName] }],
    };
    console.log("on click params value", VendorVolparams);
    axios
      .post(
        "/api/member/member-data/getMemberDataUnapprovedSuppliersByCategoryData",
        VendorVolparams
      )
      .then((response) =>
        // setCategoryRows(response.data.map((row, idx) => ({ ...row, id: idx })))
        setCategoryRows(response.data)
      );
    axios
      .post(
        "/api/member/member-data/getMemberDataVendorPartnerPurchasesData",
        VendorVolparams
      )
      .then((response) =>
      setRows(
          response.data.map((row, idx) => ({ ...row, id: idx }))
        )
      );
  };

  const onClickCategoryData = (e) => {
    let vendorName = e.row.categoryName;
    let categoryId=e.row.categoryId;
      setCategoryIds(categoryId)
    let VendorVolparams = modifyFiltersToOldFormat({
      year,
      month,
      period,
      quarter,
      memberId: memberIds.map((row) => row.dbaId),
    });
    VendorVolparams = {
      ...VendorVolparams,
      dropDown: [{ categoryName: [vendorName] }],
    };
    console.log("on click params value", VendorVolparams);
    axios
      .post(
        "/api/member/member-data/getMemberDataVendorPartnerPurchasesData",
        VendorVolparams
      )
      .then((response) =>
      setRows(
          response.data.map((row, idx) => ({ ...row, id: idx }))
        )
      );
    axios
      .post(
        "/api/member/member-data/getMemberDataUnapprovedSuppliersData",
        VendorVolparams
      )
      .then((response) =>
        setSupplierOppRows(
          response.data.map((row, idx) => ({ ...row, id: idx }))
        )
      );
  };

  const onClickApprovedSupplierpopup=(e)=>{
    setApprovedSupVendorId(e.row.vendorId);
    setApprovedSupVendorName(e.row.vendorName)
    setApprovedVendorProdDesc(e.row.prodDesc)
    setApprovedSupProductId(e.row.bhProductId)
    console.log('onClickApprovedSupplierpopup',approvedSupVendorId,approvedSupVendorName)

  }
  const onClickUnpprovedSupplierpopup=(e)=>{
    setFeedbackProductId(e.row.bhProductId)
    console.log('onClickUnpprovedSupplierpopup',e.row.bhProductId)
  }
  // Interclicking functionality ended

  const handleFormSubmit = e => {
    e.preventDefault();

    let data = {
      comments: "",
      source: "CORPORATE",
      suggestedSupplierId: null,
      suggestedSupplierName: parentName,
      supplier: "",
      supplierType: "PARENT",
      unapprovedSupplierId: null,
      unapprovedSupplierName: vendorName,

    }
    axios.post('api/corporate/feedback/insertFeedbackUnapprovedSupplierFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == 200) {
          enqueueSnackbar(res.data.message);
          setOpen(false);
        }
      })
      .catch(err => {
        enqueueSnackbar('Error while submitting feedback');
        setOpen(false);
      })

  }

  const handleFavorableVendorFeedbackDetailsFormSubmit = e => {
    e.preventDefault();

    let data = {
      bhCategoryId: categoryId,
      bhCategoryName: categoryName,
      comments: "",
      favorableSupplierId: favourableVendorId,
      favorableSupplierIdSuggested: FavourableVpId,
      favorableSupplierName: favourableVendorName,
      favorableSupplierNameSuggested: FavourableVpNames,
      favorableSupplierWrong: 1,
      favorableVpRank: 1,
      productDescription: "",
      productId: null,

    }
    axios.post('api/corporate/feedback/insertFeedbackFavorableSupplierFeedback', JSON.stringify(data))
      .then(res => {
        if (res.status == 200) {
          enqueueSnackbar(res.data.message);
          setOpenFeedbackDialog(false);
        }
      })
      .catch(err => {
        enqueueSnackbar('Error while submitting feedback');
        setOpenFeedbackDialog(false);
      })

  }

  const favourableVendorNameset = (e) => {
    let text = e.target.value
    setVpName(text);

    let key = text.split("#")[1];
    let value = text.split("#")[0];
    setFavourableVpNames(value);
    setFavourableVpId(key);
    console.log("valuevalue", value)
    console.log("keykey", key)

  }

  const clearAllRows = () => {
    textInput.current.value = '';
    setRows(tableDataBackupRows);
  }
  const clearAllSupplier = () => {
    textInput1.current.value = '';
    setSupplierOppRows(tableDataBackupSupplier);
   
  }

  const clearAllCategory = () => {
    textInput2.current.value = '';
    setCategoryRows(tableDataBackupCategory);
    
  }

  const clearAllUnapprovedSupplier = () => {
    textInput3.current.value='';
    setCategorypopupRows(tableDataBackupCategoryPopUpRows)
  }

  const clearAllApprovedSupplier=()=>{
    textInput4.current.value='';
    setCategorypopupRowsApprovedSupplier(tableDataBackupCategoryPopUpRowsApprovedSupplier)
  }

  const requestSearchRows = (evalue) => {
    // if (evalue == '') {
    //   console.log("hiiiiiiiiiiiiiiiiii",evalue)

    //   setRows(rows);
    // }
    if( evalue && evalue.length && typeof evalue === 'string'){
      console.log("hiiiiiiiiiiiiiiiiii",evalue)
    const filteredRows = rows.filter((row1) => {
      console.log("row1.vendorNamerow1.vendorName",row1.vendorName);
      return row1.vendorName.toLowerCase().includes(evalue);
    });
    setRows(filteredRows);}
    else {
      console.log("hellloooooooooooooooooooooo")
      setRows(rows);
    }
    if(textInput.current.value == ''){
      setRows(tableDataBackupRows);
    }
  }
  const requestSearchSupplier = (searchedVal) => {
    if (searchedVal == '') {
      setSupplierOppRows(supplierOppRows);
    }
    setSupplierOppRows(supplierOppRows);
    const filteredRows = supplierOppRows.filter((row) => {
      return row.vendorName.toLowerCase().includes(searchedVal);
    });
    setSupplierOppRows(filteredRows);
    if(textInput1.current.value == ''){
      setSupplierOppRows(tableDataBackupSupplier);
    }
  }

  const requestSearchCategory = (searchedVal) => {
    if (searchedVal == '') {
      setCategoryRows(categoryRows);
    }
    setCategoryRows(categoryRows);
    const filteredRows = categoryRows.filter((row) => {
      return row.categoryName.toLowerCase().includes(searchedVal);
    });
    setCategoryRows(filteredRows);
    if(textInput2.current.value == ''){
      setCategoryRows(tableDataBackupCategory);
    }
  }

  const requestSearchUnapprovedSupplier=(searchedVal)=>{
    if (searchedVal == '') {
      setCategorypopupRows(categorypopupRows);
    }
    setCategorypopupRows(categorypopupRows);
    const filteredRows = categorypopupRows.filter((row) => {
      return row.vendorName.toLowerCase().includes(searchedVal) ||
      row.productDesc.toLowerCase().includes(searchedVal) ||
      row.salesOpportunity.toString().toLowerCase().includes(searchedVal) ||
      row.rebateOpportunity.toString().toLowerCase().includes(searchedVal) ||
      row.productId.toString().toLowerCase().includes(searchedVal)
    });
    setCategorypopupRows(filteredRows);
    if(textInput3.current.value == ''){
      setCategorypopupRows(tableDataBackupCategoryPopUpRows);
    }
  }

  const requestSearchApprovedSupplier=(searchedVal)=>{
    if (searchedVal == '') {
      setCategorypopupRowsApprovedSupplier(categorypopupRowsApprovedSupplier);
    }
    setCategorypopupRowsApprovedSupplier(categorypopupRowsApprovedSupplier);
    const filteredRows = categorypopupRowsApprovedSupplier.filter((row) => {
      return row.vendorName.toLowerCase().includes(searchedVal) ||
      row.prodDesc.toLowerCase().includes(searchedVal) ||
      row.salesOpportunity.toString().toLowerCase().includes(searchedVal) ||
      row.productId.toString().toLowerCase().includes(searchedVal)
    });
    setCategorypopupRowsApprovedSupplier(filteredRows);
    if(textInput4.current.value == ''){
      setCategorypopupRowsApprovedSupplier(tableDataBackupCategoryPopUpRowsApprovedSupplier);
    }
  }

  const generateExporUnapprovedSupplier = () => {

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("unapprovedSupplierPopup", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${categorypopupRows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });

    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Unapproved Suppliers";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["PROD ID", "PROD DESC", "UNAPPROVED SUPPLIER", "VOLUME", "REBATE OPPORTUNITY"];

    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);


    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });

    // const filterData= categorypopupRows.filter((row) => {
    //     if((row.total_sales>0 && row.market_share>0)) {
    //     return categorypopupRows
    // }
    //   });
      

    categorypopupRows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.productId, obj.productDesc, 
            obj.vendorName, '$'+Math.round(obj.salesOpportunity), '$'+Math.round(obj.rebateOpportunity) ]);
            // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
            //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            // if (number === 4) {
            //     cell.numFmt = '$#,##0;[Red]-$#,##0';
            // }
            // if (number === 5) {
            //     cell.numFmt = '0.00%';
               
            // }
            // if (number === 6) {
            //     cell.numFmt = '#,##0';
            // }
        });
    });

    

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
   

    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Unapproved Suppliers';

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "unapprovedSupplierPopup.xlsx");
    });
};

const generateExportApprovedSupplier = () => {

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("ApprovedSupplierPopup", {
      pageSetup: {
          paperSize: 9,
          orientation: 'landscape',
          printArea: `A1:F${categorypopupRowsApprovedSupplier.length + 4}`,
          printTitlesRow: '1:4',
          horizontalCentered: true,
          showGridLines: false
      },
      // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
      views: [{  showGridLines: false, showRowColHeaders: false }],

      headerFooter: {
          oddFooter: "Page &P of &N"
      }
  });

  // Add Image
  const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
  const title = "BLUE HAWK Approved Suppliers";
  const title1 = `Based on collective Purchasing Survey data: ${year}`;
  const str = title + "\n" + title1;
  const sheetHeaderRow = ["PROD ID", "PROD DESC", "APPROVED SUPPLIER",  "SALES OPPORTUNITY"];

  const titleRow = worksheet.addRow([str]);
  titleRow.eachCell((cell, number) => {
      cell.alignment = { wrapText: true, vertical: 'middle' };
  });
  titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
  worksheet.addRow([]);
  // Add Image in "E1:F2" cell range
  // worksheet.addImage(insertedLogo, {
  //     tl: { col: 4.2, row: 0.2 },
  //     br: { col: 5.8, row: 1.8 }
  // });
  worksheet.addImage(insertedLogo, "E1:F3");

  worksheet.mergeCells("A1:D2");
  worksheet.mergeCells("E1:F2");
  worksheet.addRow([]);


  // Add Header Row
  const headerRow = worksheet.addRow(sheetHeaderRow);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
      cell.fill = {
          type: "pattern",
          pattern: "",
          fgColor: { argb: "FFAA00" },
          bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thick" },
          right: { style: "thin" },
      };
      // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

  });

  // const filterData= categorypopupRows.filter((row) => {
  //     if((row.total_sales>0 && row.market_share>0)) {
  //     return categorypopupRows
  // }
  //   });
    

  categorypopupRowsApprovedSupplier.forEach(function (obj) {
      let addedRow = worksheet.addRow([obj.productId, obj.prodDesc, 
          obj.vendorName, '$'+Math.round(obj.salesOpportunity) ]);
          // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
          //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
      addedRow.eachCell((cell, number) => {
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
          };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

          // if (number === 4) {
          //     cell.numFmt = '$#,##0;[Red]-$#,##0';
          // }
          // if (number === 5) {
          //     cell.numFmt = '0.00%';
             
          // }
          // if (number === 6) {
          //     cell.numFmt = '#,##0';
          // }
      });
  });

  

  worksheet.getColumn(1).width = 20;
  worksheet.getColumn(2).width = 20;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 20;
  worksheet.getColumn(5).width = 20;
 

  // Add some meta data in excel file
  workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
  workbook.company = 'Tredence Inc.';
  workbook.category = 'Approved Suppliers';

  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "ApprovedSupplierPopup.xlsx");
  });
};

const generateExportUnapprovedSuppVolumeByCategory =()=>{

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("unapprovedSuppVolByCategory", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${categoryRows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });

    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Unapproved Supplier Volume (By Category)";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["CATEGORY", "VOLUME", "REBATE OPPORTUNITY", "VENDOR PARTNER ", "VENDOR PARTNER ",
    "VENDOR PARTNER ","VENDOR PARTNER "];

    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);


    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });

    
    categoryRows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.categoryName, '$'+Math.round(obj.salesOpportunity), 
             '$'+Math.round(obj.rebateOpportunity),obj.favorableVendorName1,obj.favorableVendorName2,
             obj.favorableVendorName3,obj.favorableVendorName4 ]);
            // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
            //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });

    

    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(4).width = 12;
    worksheet.getColumn(5).width = 12;
    worksheet.getColumn(6).width = 12;
    worksheet.getColumn(7).width = 12;
   

    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Unapproved Supplier Volume (By Category)';

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "unapprovedSuppVolByCategory.xlsx");
    });

}

const generateExportUnapprovedSuppVolume =()=>{

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("unapprovedSuppVol", {
      pageSetup: {
          paperSize: 9,
          orientation: 'landscape',
          printArea: `A1:F${supplierOppRows.length + 4}`,
          printTitlesRow: '1:4',
          horizontalCentered: true,
          showGridLines: false
      },
      // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
      views: [{  showGridLines: false, showRowColHeaders: false }],

      headerFooter: {
          oddFooter: "Page &P of &N"
      }
  });

  // Add Image
  const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
  const title = "BLUE HAWK Unapproved Supplier Volume";
  const title1 = `Based on collective Purchasing Survey data: ${year}`;
  const str = title + "\n" + title1;
  const sheetHeaderRow = ["UNAPPROVED SUPPLIER", "VOLUME", "REBATE OPPORTUNITY"];

  const titleRow = worksheet.addRow([str]);
  titleRow.eachCell((cell, number) => {
      cell.alignment = { wrapText: true, vertical: 'middle' };
  });
  titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
  worksheet.addRow([]);
  // Add Image in "E1:F2" cell range
  // worksheet.addImage(insertedLogo, {
  //     tl: { col: 4.2, row: 0.2 },
  //     br: { col: 5.8, row: 1.8 }
  // });
  worksheet.addImage(insertedLogo, "E1:F3");

  worksheet.mergeCells("A1:D2");
  worksheet.mergeCells("E1:F2");
  worksheet.addRow([]);


  // Add Header Row
  const headerRow = worksheet.addRow(sheetHeaderRow);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
      cell.fill = {
          type: "pattern",
          pattern: "",
          fgColor: { argb: "FFAA00" },
          bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thick" },
          right: { style: "thin" },
      };
      // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

  });

  
  supplierOppRows.forEach(function (obj) {
      let addedRow = worksheet.addRow([obj.vendorName, '$'+Math.round(obj.volume), 
           '$'+Math.round(obj.rebateOpportunity) ]);
          // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
          //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
      addedRow.eachCell((cell, number) => {
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
          };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
  });

  

  worksheet.getColumn(1).width = 40;
  worksheet.getColumn(2).width = 25;
  worksheet.getColumn(3).width = 25;

 

  // Add some meta data in excel file
  workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
  workbook.company = 'Tredence Inc.';
  workbook.category = 'Unapproved Supplier Volume';

  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "unapprovedSuppVol.xlsx");
  });

}

const generateExportVpv=()=>{
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("vendorPartnerVolume", {
      pageSetup: {
          paperSize: 9,
          orientation: 'landscape',
          printArea: `A1:F${rows.length + 4}`,
          printTitlesRow: '1:4',
          horizontalCentered: true,
          showGridLines: false
      },
      // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
      views: [{  showGridLines: false, showRowColHeaders: false }],

      headerFooter: {
          oddFooter: "Page &P of &N"
      }
  });

  // Add Image
  const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
  const title = "BLUE HAWK Vendor Partner Volume";
  const title1 = `Based on collective Purchasing Survey data: ${year}`;
  const str = title + "\n" + title1;
  const sheetHeaderRow = ["VP NAME", "VP VOLUME", "REBATE ESTIMATE","CONVERSION VOLUME","POTENTIAL ADDITIONAL REBATE"];

  const titleRow = worksheet.addRow([str]);
  titleRow.eachCell((cell, number) => {
      cell.alignment = { wrapText: true, vertical: 'middle' };
  });
  titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
  worksheet.addRow([]);
  // Add Image in "E1:F2" cell range
  // worksheet.addImage(insertedLogo, {
  //     tl: { col: 4.2, row: 0.2 },
  //     br: { col: 5.8, row: 1.8 }
  // });
  worksheet.addImage(insertedLogo, "E1:F3");

  worksheet.mergeCells("A1:D2");
  worksheet.mergeCells("E1:F2");
  worksheet.addRow([]);


  // Add Header Row
  const headerRow = worksheet.addRow(sheetHeaderRow);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
      cell.fill = {
          type: "pattern",
          pattern: "",
          fgColor: { argb: "FFAA00" },
          bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thick" },
          right: { style: "thin" },
      };
      // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

  });

  
  rows.forEach(function (obj) {
      let addedRow = worksheet.addRow([obj.vendorName, '$'+Math.round(obj.purchase), 
           '$'+Math.round(obj.rebateEstimate) ,'$'+Math.round(obj.potentialPurchase),'$'+Math.round(obj.potentialRebate)]);
          // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
          //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
      addedRow.eachCell((cell, number) => {
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
          };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
  });

  

  worksheet.getColumn(1).width = 40;
  worksheet.getColumn(2).width = 25;
  worksheet.getColumn(3).width = 25;
  worksheet.getColumn(4).width = 25;
  worksheet.getColumn(5).width = 25;

 

  // Add some meta data in excel file
  workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
  workbook.company = 'Tredence Inc.';
  workbook.category = 'Vendor Partner Volume';

  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "vendorPartnerVolume.xlsx");
  });

}

  const handleCategoryFormSubmitUnapprovedSupplier = e => {
    e.preventDefault();

    let data = {
      bhCategoryId: categoryId,
      bhCategoryName: categoryName,
      comments: "",
      productDescription: feedbackCategoryName,
      productId: feedbackProductId,
      // productId: e.row.bhProductId,
      productNotSold: 1,
      statusForProductsNotSold: removeToggle1,
      statusForWrongClassification: removeToggle,
      suggestedBhCategoryId: ListsId1,
      suggestedBhCategoryName: Lists1,
      wrongCategory: 1,

    }
    console.log('handleCategoryFormSubmitUnapprovedSupplier123 params',data,e)
    if (vendorId && vendorName){
      console.log('approved if data params')
       data={...data, vendorId:vendorId,vendorName:vendorName}
    }
    if (removeToggle == true && removeToggle1 != true) {
      axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
            setOpenCategoryDialog(false);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');
          setOpenCategoryDialog(false);
        })

    }
    else if (removeToggle1 == true && removeToggle != true) {
      axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');

        })

    }
    else if (removeToggle == true && removeToggle1 == true) {
      axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');

        })

      axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);

          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');
        })

    }

  }

  const handleCategoryFormSubmitApprovedSupplier = e => {
    e.preventDefault();

    let data = {
      bhCategoryId: categoryId,
      bhCategoryName: categoryName,
      comments: "",
      productDescription: approvedSupVendorProdDesc,
      productId: approvedSupProductId,
      productNotSoldByVendor: 1,
      statusForProductsNotSold: removeToggle1,
      statusForWrongClassification: removeToggle,
      suggestedBhCategoryId: ListsId1,
      suggestedBhCategoryName: Lists1,
      wrongCategory: 1,
      vendorId:approvedSupVendorId,
      vendorName:approvedSupVendorName
    }
    console.log('handleCategoryFormSubmitApprovedSupplier params',data)
    if (vendorId && vendorName){
      console.log('approved if data params')
       data={...data, vendorId:vendorId,vendorName:vendorName}
    }
    if (removeToggle == true && removeToggle1 != true) {
      axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
            setOpenCategoryDialogApproved(false);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');
          setOpenCategoryDialogApproved(false);
        })

    }
    else if (removeToggle1 == true && removeToggle != true) {
      axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');

        })

    }
    else if (removeToggle == true && removeToggle1 == true) {
      axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);
          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');

        })

      axios.post('api/corporate/feedback/insertFeedbackProductNotSoldFeedback', JSON.stringify(data))
        .then(res => {
          if (res.status == 200) {
            enqueueSnackbar(res.data.message);

          }
        })
        .catch(err => {
          enqueueSnackbar('Error while submitting feedback');
        })

    }

  }



 
  const categoryset = (e) => {
    let text = e.target.value
    setCategoryListtotal(text);

    let key = text.split("#")[1];
    let value = text.split("#")[0];
    setCategoryList1(value);
    setCategoryListId1(key);
    console.log("valuevalue", value)
    console.log("keykey", key)

  }



  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3} columns={2}>
        <Grid item xs={1} sx={{ mt: 2, height: '600px' }}>
        {/* <Box sx={{ mt: 2, height: '600px' }}> */}

          <div sx={{ height: '600px' }}>
            <Typography variant='h6' sx={{ mb: 1 }}>Vendor Partner Volume</Typography>
            <TextField
              inputRef={textInput}
              id="standard-basic"
              sx={{ marginLeft: "5px" }}
              variant="standard"
              placeholder="Search"
              onChange={(searchVal) =>
                requestSearchRows(searchVal.target.value)
              }
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              sx={{ marginBottom:'3px'}}
              onClick={() => clearAllRows()}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              sx={{float:'right'}}
              onClick={() => generateExportVpv()}
            ><FileDownloadOutlinedIcon/>
              Export
            </Button>
          </div>
          {/* <div style={{ height: '450px', width: '100%' }}> */}
            <div style={{width:'100%',height:'450px'}}>
          
          <DataGrid  disableColumnMenu density='compact' loading={loading}
            rows={rows} columns={columnVpv} 
            pagination
           
            pageSize={pageSize2}
            onPageSizeChange={(newPageSize) => setPageSize2(newPageSize)}    
            rowsPerPageOptions={[10,50,100]} 
            disableSelectionOnClick
            onRowClick={onClickUnapprovedSupVol}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            // localeText={{
            //   toolbarExport: "export"}}
             />
            </div>
        </Grid>
        <Grid item xs={1}>
          <div>
            <Typography variant='h6' sx={{ mb: 1,marginTop:'16px'}}>Unapproved Supplier Volume</Typography>
            <TextField
              inputRef={textInput1}
              id="standard-basic"
              sx={{ marginLeft: "5px" }}
              variant="standard"
              placeholder="Search"
              onChange={(searchVal) =>
                requestSearchSupplier(searchVal.target.value)
              }
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              sx={{}}
              onClick={() => clearAllSupplier()}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              sx={{ float:'right' }}
              onClick={() => generateExportUnapprovedSuppVolume()}
            ><FileDownloadOutlinedIcon/>
              Export
            </Button>
          </div>
          <div style={{ height: 450, width: '100%' }}>
          <DataGrid  disableColumnMenu density='compact' loading={loading}
            rows={(supplierOppRows)} columns={columnSupplierOpportunity} 
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
            rowsPerPageOptions={[10,50,100]} 
        disableSelectionOnClick
            onRowClick={onClickVendorPartnerVolume}

            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            // localeText={{
            //   toolbarExport: "export",}}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div>
            <Typography variant='h6' sx={{ mb: 1 }}>Unapproved Supplier Volume (By Category)</Typography>
            <TextField
              inputRef={textInput2}
              id="standard-basic"
              sx={{ marginLeft: "5px" }}
              variant="standard"
              placeholder="Search"
              onChange={(searchVal) =>
                requestSearchCategory(searchVal.target.value)
              }
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              sx={{ marginLeft: "5px" }}
              onClick={() => clearAllCategory()}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              sx={{ float:'right' }}
              onClick={() => generateExportUnapprovedSuppVolumeByCategory()}
            ><FileDownloadOutlinedIcon/>
              Export
            </Button>
          </div>
          <div style={{ height: 450, width: '100%' }}>
          <DataGrid  disableColumnMenu density='compact' loading={loading}
            rows={categoryRows} 
            columns={columnCategoryOpportunity} 
            getRowId={(row) => row.id}
            pageSize={pageSize1}
            onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}    
            rowsPerPageOptions={[10,50,100]} 
        disableSelectionOnClick
            onRowClick={onClickCategoryData}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            // localeText={{
            //   toolbarExport: "export"}}
             />
             </div>
        </Grid>
        {/* Category display popup  */}
        <BootstrapDialog
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={openCategoryDialogpopup}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "80%",
                height: '100%',
                maxWidth: 'none'
              },
            },
          }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose1}>
            {/* Products purchased in {categoryName} */}
          </BootstrapDialogTitle>
          <DialogContent>
            {/* <Grid >
              <DataGrid autoHeight disableColumnMenu density='compact' loading={loading} 
              rows={categorypopupRows} columns={columnProductPurchases}
                getRowId={(row) => row.id}
                pagination
                // pageSize={10} rowsPerPageOptions={[10]} 
                pageSize={pageSize3}
                // onPageSizeChange={setPageSize3}
             onPageSizeChange={(newPageSize1) => setPageSize3(newPageSize1)}    
            rowsPerPageOptions={[10,50,100]} 
                 />
            </Grid> */}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
    <Tab label="Unapproved Suppliers" {...a11yProps(0)} />
    <Tab label="Approved Suppliers" {...a11yProps(1)} />
  </Tabs>
</Box>
<TabPanel value={value} index={0}>
<div sx={{ height: '600px' }}>
<Typography variant='h6' sx={{ mb: 1 }}>Products purchased in {categoryName} </Typography>
            <TextField
              inputRef={textInput3}
              id="standard-basic"
              sx={{ marginLeft: "5px" }}
              variant="standard"
              placeholder="Search"
              onChange={(searchVal) =>
                requestSearchUnapprovedSupplier(searchVal.target.value)
              }
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              sx={{ marginRight: "100px" ,marginBottom:'3px'}}
              onClick={() => clearAllUnapprovedSupplier()}
            >
              Clear All
            </Button>

            <Button
              variant="contained"
              sx={{ marginLeft: "5px" ,float:'right' }}
              onClick={() => generateExporUnapprovedSupplier()}
            ><FileDownloadOutlinedIcon/>
              Export
            </Button>
          </div>
           <div style={{ height: 450, width: '100%' }}>
              <DataGrid autoHeight disableColumnMenu density='compact' loading={loading} 
              rows={categorypopupRows} columns={columnProductPurchases}
                getRowId={(row) => row.id}
                pagination
                // pageSize={10} rowsPerPageOptions={[10]} 
                pageSize={pageSize3}
                // onPageSizeChange={setPageSize3}
             onPageSizeChange={(newPageSize1) => setPageSize3(newPageSize1)}    
            rowsPerPageOptions={[10,50,100]} 
            onRowClick={onClickUnpprovedSupplierpopup}
                 />
            </div>
</TabPanel>

<TabPanel value={value} index={1}>
<div sx={{ height: '600px' }}>
<Typography variant='h6' sx={{ mb: 1 }}>Products purchased in {categoryName}</Typography>
            <TextField
              inputRef={textInput4}
              id="standard-basic"
              sx={{ marginLeft: "5px" }}
              variant="standard"
              placeholder="Search"
              onChange={(searchVal) =>
                requestSearchApprovedSupplier(searchVal.target.value)
              }
            />
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              sx={{ marginRight: "100px" ,marginBottom:'3px'}}
              onClick={() => clearAllApprovedSupplier()}
            >
              Clear All
            </Button>

            <Button
              variant="contained"
              sx={{ marginLeft: "5px" ,float:'right' }}
              onClick={() => generateExportApprovedSupplier()}
            ><FileDownloadOutlinedIcon/>
              Export
            </Button>
          </div>


          <div style={{ height: 450, width: '100%' }}>
              <DataGrid autoHeight disableColumnMenu density='compact' loading={loading} 
              rows={categorypopupRowsApprovedSupplier} columns={columnProductPurchasesApprovedSupplier}
                getRowId={(row) => row.id}
                pagination
                // pageSize={10} rowsPerPageOptions={[10]} 
                pageSize={pageSize3}
                // onPageSizeChange={setPageSize3}
             onPageSizeChange={(newPageSize1) => setPageSize3(newPageSize1)}    
            rowsPerPageOptions={[10,50,100]} 
            onRowClick={onClickApprovedSupplierpopup}
                 />
            </div>
</TabPanel>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose1}>Close</Button>
          </DialogActions>
        </BootstrapDialog>

      </Grid>
      <CategoryDetailsDialogpopup open={openCategoryDialogpopup} handleClose1={value => handleCategoryDialogpopupClose(value)} />

      {/* //Category display pop up end */}
      {/* Feedback popup for unapproved supplier volume */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '50%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Unapproved Supplier Feedback
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant='h6' sx={{ mb: 1 }}> Parent company for {vendorName}</Typography>

          <Grid item sx={{ mt: 5 }}>
            <TextField inputRef={textInputfeedback}  id="standard-basic" variant="standard" layout="horizontal"
              placeholder = "Enter Parent Vendor name" fullWidth onChange={event => setParentName(event.target.value)} />

          </Grid>
          <Stack direction='row' spacing={4} sx={{ mt: 4, float: 'right' }}>
            <Button color="primary" onClick={handleFormSubmit}
              variant="contained" style={{ marginBottom: "0px" }}>
              Submit
            </Button>
            <Button color="secondary" onClick={handleClose}
              variant="contained" style={{ marginBottom: "0px" }}>
              Cancel
            </Button>
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>

      <UnapprovedSupplierDetailsDialog open={open} handleClose={value => handleDialogClose(value)} />

      {/* feedback popup for unapproved supplier volume  */}

      {/* feedback popup for favourable vendor  */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openFeedbackDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '50%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vendor Feedback
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Typography variant='body1' sx={{ mb: 1 }}> Is <strong>{favourableVendorName}</strong> wrong as <strong>{vendorText}</strong> for <strong>{categoryName}</strong></Typography>

          <Grid item xs={2} sx={{ mt: 6 }}>
            <FormControl>

              <InputLabel>Select Vendor Partner</InputLabel>
              <Select
                labelId="vp-name-select-small"
                id="vp-name-select-small"
                value={vpName}
                label="Select Supplier"
                sx={{ minWidth: 400, marginBottom: 2, minHeight: 40, float: 'right' }}
                onChange={(event) => favourableVendorNameset(event)}
                size='small'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName + '#' + vp.vendorId}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>

          </Grid>
          <Stack direction='row' spacing={4} sx={{ mt: 4, float: 'right' }}>
            <Button color="primary" onClick={handleFavorableVendorFeedbackDetailsFormSubmit}
              variant="contained" style={{ marginBottom: "0px" }}>
              Submit
            </Button>
            <Button color="secondary" onClick={handleClose}
              variant="contained" style={{ marginBottom: "0px" }}>
              Cancel
            </Button>
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>

      <FavorableVendorFeedbackDetailsDialog open={openFeedbackDialog} handleClose={value => handleFeedbackDialogClose(value)} />
      {/* feedback popup for favourable vendor end */}

      {/* feedback popup for category unapproved supplier volume by category */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCategoryDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '50%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Product feedback for Unapproved {categoryName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid item xs={2}>
            <Box>
              <Stack direction='column' spacing={4} marginBottom='5' >

                <Typography id="modal-modal-title" variant="body1" component="h2">
                  Is {feedbackCategoryName} wrongly classified as {categoryName}?
                  <FormControlLabel control={<Switch checked={removeToggle} onChange={() => setRemoveToggle(!removeToggle)} />} />

                </Typography>
                {removeToggle ? (
                  <FormControl>

                    <InputLabel>Select Category</InputLabel>
                    <Select
                      labelId="category-name-select-large"
                      id="category-name-select-large"
                      value={Liststotal}
                      label="Select category"
                      sx={{ marginBottom: 2, minHeight: 55 }}
                      onChange={(event) => categoryset(event)}
                      size='large'
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {Lists.map(vp => (<MenuItem key={vp.bhCategoryId} value={vp.bhCategoryName + "#" + vp.bhCategoryId}>{vp.bhCategoryName}</MenuItem>))}
                    </Select>
                  </FormControl>) : ''}
                <Typography id="modal-modal-title" variant="body1" component="h2">
                  {feedbackCategoryName} is not sold by any vendor within Blue Hawk?
                  <FormControlLabel control={<Switch checked={removeToggle1} onChange={() => setRemoveToggle1(!removeToggle1)} />} />

                </Typography>

                <Stack direction='row' spacing={4} marginBottom='5' >
                  <Button color="primary" onClick={handleCategoryFormSubmitUnapprovedSupplier }
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Submit
                  </Button>
                  <Button color="secondary" onClick={handleClose}
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Box>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
      <CategoryDetailsDialog open={openCategoryDialog} handleClose={value => handleCategoryDialogClose(value)} />


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCategoryDialogApproved}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '50%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Product feedback for Approved {categoryName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid item xs={2}>
            <Box>
              <Stack direction='column' spacing={4} marginBottom='5' >

                <Typography id="modal-modal-title" variant="body1" component="h2">
                  Is {approvedSupVendorProdDesc} wrongly classified as {categoryName}?
                  <FormControlLabel control={<Switch checked={removeToggle} onChange={() => setRemoveToggle(!removeToggle)} />} />

                </Typography>
                {removeToggle ? (
                  <FormControl>

                    <InputLabel>Select Category</InputLabel>
                    <Select
                      labelId="category-name-select-large"
                      id="category-name-select-large"
                      value={Liststotal}
                      label="Select category"
                      sx={{ marginBottom: 2, minHeight: 55 }}
                      onChange={(event) => categoryset(event)}
                      size='large'
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {Lists.map(vp => (<MenuItem key={vp.bhCategoryId} value={vp.bhCategoryName + "#" + vp.bhCategoryId}>{vp.bhCategoryName}</MenuItem>))}
                    </Select>
                  </FormControl>) : ''}
                <Typography id="modal-modal-title" variant="body1" component="h2">
                  {approvedSupVendorProdDesc} is not sold by this vendor?
                  <FormControlLabel control={<Switch checked={removeToggle1} onChange={() => setRemoveToggle1(!removeToggle1)} />} />

                </Typography>

                <Stack direction='row' spacing={4} marginBottom='5' >
                  <Button color="primary" onClick={handleCategoryFormSubmitApprovedSupplier }
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Submit
                  </Button>
                  <Button color="secondary" onClick={handleClose}
                    variant="contained" style={{ marginBottom: "0px" }}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Box>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>


      <CategoryDetailsDialogApproved open={openCategoryDialogApproved} handleClose={value => handleCategoryDialogClose(value)} />


      {/* //ends */}
    </Paper>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


