import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

export default function UserStatusDialog({ id, enabled, accountNonExpired, accountNonLocked, credentialsNonExpired, onStatusChange }) {

    const [userStatus, setUserStatus] = useState({ id, enabled, accountNonExpired, accountNonLocked, credentialsNonExpired });
    const [open, setOpen] = useState(false);

    const handleDialogClose = () => {
        setOpen(false);
    }

    const handleSaveAndDialogClose = (event) => {
        event.preventDefault();
        axios.post('/api/user/change-status', { ...userStatus }).then(resp => {
            handleDialogClose();
            onStatusChange();
        });
    }

    return (
        <Box>
            <IconButton color="primary" size="small" onClick={() => setOpen(true)}><EditIcon sx={{ fontSize: 'inherit' }} /></IconButton>
            <Dialog open={open} onClose={handleDialogClose} component="form" onSubmit={handleSaveAndDialogClose}>
                <DialogTitle>Change Status</DialogTitle>
                <List sx={{ width: 500, bgcolor: 'background.paper' }} disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton role={undefined} onClick={() => setUserStatus({ ...userStatus, enabled: !userStatus.enabled })} dense>
                            <ListItemIcon sx={{ mr: 0, ml: 1 }}>
                                <Checkbox edge="start" checked={userStatus.enabled} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText primary="Enabled" secondary="When unchecked, user is disable from login." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton role={undefined} onClick={() => setUserStatus({ ...userStatus, accountNonExpired: !userStatus.accountNonExpired })} dense>
                            <ListItemIcon sx={{ mr: 0, ml: 1 }}>
                                <Checkbox edge="start" checked={userStatus.accountNonExpired} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText primary="Account Non Expired" secondary="When unchecked, user will have to renew membership with Blue Hawk to get access." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton role={undefined} onClick={() => setUserStatus({ ...userStatus, accountNonLocked: !userStatus.accountNonLocked })} dense>
                            <ListItemIcon sx={{ mr: 0, ml: 1 }}>
                                <Checkbox edge="start" checked={userStatus.accountNonLocked} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText primary="Account Non Locked" secondary="When unchecked, user has been locked because of multiple incorrect login attempt." />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton role={undefined} onClick={() => setUserStatus({ ...userStatus, credentialsNonExpired: !userStatus.credentialsNonExpired })} dense>
                            <ListItemIcon sx={{ mr: 0, ml: 1 }}>
                                <Checkbox edge="start" checked={userStatus.credentialsNonExpired} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText primary="Credentials Non Expired" secondary="When unchecked, the user will have to set password on login. (This feature is available only in PAT)" />
                        </ListItemButton>
                    </ListItem>
                </List>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" color="success" type='submit' onClick={handleSaveAndDialogClose}>Change Status</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}