import { Backdrop, CircularProgress, Container } from '@mui/material';
import React from 'react';

export default function PageContent({ ready, children }) {

    if (ready) {
        return (
            <Container maxWidth="false" sx={{ bgcolor: 'rgb(243, 246, 249)', pt: 3, pb: 5 }}>
                {children}
            </Container>
        );
    } else {
        return (
            <Backdrop invisible sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color='secondary' variant="indeterminate" size={100} thickness={2} />
            </Backdrop>
        );
    }
}