import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { AppBar, Avatar, Box, Button, IconButton, Link, SwipeableDrawer, Toolbar, Tooltip, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import dashboardLogo from '../../assets/images/bh.png';
import { SIGNOUT } from '../../middleware/authentication';
import HideOnScroll from './hide-on-scroll';





export default function AppLayout({ sideNav, setBranchDialog }) {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const sidebarWidth = 320;
    const [sidebar, setSidebar] = useState(false);

    const handleLogout = () => {
        console.info('Logout in progress...');
        localStorage.clear();
        dispatch({ type: SIGNOUT });
        navigate('/login', { state: { from: location }, replace: true });
    };

    const supportMails = ['bluehawk.helpdesk@tredence.com', 'bluehawk.support@tredence.com', 'imark.support@tredence.com'].join(',');
    // const patUrl = 'https://bluehawk-pat.tredence.com/bluehawk-pat';
    let patUrl;

   

    if (window.location.href==="https://bhtest.tredence.com/#/admin"){
        patUrl='https://bluehawk-pat.tredence.com/uat';
        
    }
    else{
        patUrl = 'https://bluehawk-pat.tredence.com/bluehawk-pat';
        
    }

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
                    <Toolbar variant="dense">
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => setSidebar(true)}><MenuIcon /></IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>BLUE HAWK</Typography>
                        {setBranchDialog ? <Button color="inherit" variant='outlined' onClick={() => setBranchDialog(true)}>Select Company</Button> : null}
                        <Button component={Link} href={patUrl} target='_blank' color="inherit" variant='outlined' sx={{ ml: 2 }}><ExitToAppIcon sx={{ mr: 1 }} /> Go to PAT</Button>
                        <IconButton component={Link} href={'mailto:' + supportMails} edge="end" color="inherit" sx={{ ml: 2 }}><MailOutlineIcon /></IconButton>
                        <Tooltip title="Logout">
                            <IconButton size="small" edge="end" color="inherit" sx={{ ml: 2 }} onClick={handleLogout}>
                                <Avatar sx={{ bgcolor: pink[500], width: 34, height: 34 }}>
                                    <PowerSettingsNewIcon />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar variant="dense" />
            <SwipeableDrawer anchor='left' open={sidebar} onClose={() => setSidebar(false)} onOpen={() => setSidebar(true)}>
                <Toolbar />
                <Box sx={{ width: sidebarWidth, p: 2 }}>
                    <img src={dashboardLogo} alt="bh logo" style={{ width: '100%' }} />
                </Box>
                <Box sx={{ width: sidebarWidth }} role="presentation" onClick={() => setSidebar(false)} onKeyDown={() => setSidebar(false)}>
                    {sideNav}
                </Box>
            </SwipeableDrawer>
            <Outlet />
        </React.Fragment>
    );
}