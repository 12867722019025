import { pink } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
            light: '#8b9aff',
            dark: '#0c42a4'
        },
        secondary: {
            main: pink['A400']
        }
    }
});
