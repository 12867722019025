import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import { Grid, IconButton,InputLabel,Select,CircularProgress, MenuItem,Paper,FormControl,Stack, Typography,Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-54px',right:'-25px',position:'absolute'
    ,fontSize:'14px',color:'#fff',padding:'6px 12px',marginRight:'26px'}}
      />
    </GridToolbarContainer>
  );
}


export default function OpportunityByMember() {

  const { year, month, period, quarter, vendorIds } = useSelector(state => state.filter);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setMemberRows] = useState([]);
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);


  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, month, period, quarter, vendorId: vendorIds.map(row => row.dbaId) });
    if (vpName != '') {
      params.dropDown = [{ vendorName: [vpName] }];

    }
    else {
      params.dropDown = [];
    }
    Promise.all([
      axios.post('/api/corporate/conversion/getTerminatedVpOppVolumeOppByMember', { ...params }),

    ]).then(response => {
      setLoading(false);
      setMemberRows(response[0].data);
    });

  }, [year, month, period, quarter, vpName]);

  useEffect(() => {
    const params1 = {year}
    Promise.all([
      axios.post('/api/corporate/conversion/getTerminatedVpOppVendorNames', params1),

    ]).then(response => {
      setLoading(false);
      setVpNames(response[0].data.map((row, index) => ({ ...row, id: index })));


    });

  }, [year]);

  const columnMemberOpportunity = [
    { field: "memberName", headerName: "MEMBER NAME", flex: 1 },
    // { field: "stateName", headerName: "STATE", width: 180, align: 'right' },
    { field: "sales", headerName: "MEMBER PURCHASES", width: 200, align: 'right', renderCell: (params) => <FormatCurrency value={params.value} /> },

  ];
 
  const generateExportOppByMember=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("OppByMember", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${rows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Opportunity By Member";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["MEMBER NAME","MEMBER PURCHASES"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
     
    
      rows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.memberName ,
             '$'+Math.round(obj.sales)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 30;
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Opportunity By Member';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "OppByMember.xlsx");
    });
  }


  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={3} columns={2} >
        <Grid item xs={3} >
        <Stack direction='row' spacing={33}  >
        <div class="parent" style={{width:'100%'}}>
              <div class="block1" style={{width:'33.33%',float:'left'}}>
        <Typography variant='h6' sx={{ mb: 1 }}>Opportunity By Member 
           {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</Typography>
           </div>
            <div class="block2" style={{width:'33.33%',float:'left'}}>
            <FormControl>

              <InputLabel>Select Supplier</InputLabel>
              <Select
                labelId="vp-name-select-small"
                id="vp-name-select-small"
                value={vpName}
                label="Select Supplier"
                sx={{ minWidth: 200, marginBottom: 2, minHeight: 50, float: 'right' }}
                onChange={(event) => setVpName(event.target.value)}
                size='small'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>
            </div>
            <div class="block3" style={{width:'33.33%',float:'right'}}>
            <Button variant="contained" sx={{height:'42px',padding:'0px 24px',float:'right'}} onClick={() => generateExportOppByMember()}><FileDownloadOutlinedIcon/>Export</Button>
            </div>
            </div>
            </Stack>
            <div>
          <DataGrid sx={{ height: 500}} disableColumnMenu density='compact' loading={loading}
            rows={rows} columns={columnMemberOpportunity}            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
            rowsPerPageOptions={[10,50,100]}  disableSelectionOnClick
            getRowId={(row) => row.memberId}
            // components={{
            //   Toolbar: CustomToolbar,
            //  }}
            //  localeText={{
            //   toolbarExport:'export'
            // }}
          />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}