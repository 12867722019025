export const checkTermsAccepted = (termsAccepted) => {
    return !termsAccepted || (new Date(termsAccepted).getFullYear() < new Date().getFullYear());
};

export const modifyFiltersToOldFormat = (newParams) => {
    let oldParams = { ...newParams, quarter: 'qtr' + newParams.quarter, dropDown: [] };
    switch (oldParams.period) {
        case 'ytd':
            oldParams = { ...oldParams, 'ytd': 'true', 'mth': 'false', 'qtr': 'false' };
            break;
        case 'mth':
            oldParams = { ...oldParams, 'ytd': 'false', 'mth': 'true', 'qtr': 'false' };
            break;
        case 'qtr':
        default:
            oldParams = { ...oldParams, 'ytd': 'false', 'mth': 'false', 'qtr': 'true' };
    }
    return oldParams;
};

export const getInitials = name => {
    name = name.split('(')[0].split(' - ')[0];
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...name.matchAll(rgx)] || [];
    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
};

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const modifyFiltersToOldFormat1 = (newParams) => {
    let oldParams = { ...newParams };
    delete oldParams.period;
    switch (newParams.period) {
        case 'ytd':
            oldParams = { ...oldParams, 'ytd': "true", 'mth': "false", 'qtr': "false" };
            break;
        case 'mth':
            oldParams = { ...oldParams, 'ytd': "false", 'mth': "true", 'qtr': "false" };
            break;
        case 'qtr':
        default:
            oldParams = { ...oldParams, 'ytd': "false", 'mth': "false", 'qtr': "true" };
    }
    return oldParams;
};