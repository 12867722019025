import { Card, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormatCurrency, YoyChip } from './formatter';

function OverviewCard({ title = 'Card Title', value = 0, yoy = 0, updateDate = null }) {
    
    let date = '-NA-';
    
    if (updateDate && typeof updateDate === 'object') {
        date = updateDate.toLocaleDateString();
    } else if (updateDate && typeof updateDate === 'string') {
        date = updateDate;
    }
    
    return (
        <Card sx={{ p: 2, minWidth: '100%', textAlign: 'center' }} variant="outlined">
            <Typography variant="subtitle2">{title}</Typography>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2} alignItems='center' justifyContent="center" sx={{ my: 0.5 }}>
                <Typography variant='h6'>{FormatCurrency({ value })}</Typography>
                <YoyChip yoy={yoy} />
            </Stack>
            <Typography variant="body2">last updated on {date}</Typography>
        </Card>
    );
}

export default OverviewCard;