import { Box, Toolbar, Typography } from '@mui/material';
import axios from "axios";
import React, { useEffect } from 'react';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import AdminOpportunitySummary from '../adminOpportunity/admin-opportunity';
import AdminOpportunityGraphs from './admin-opportunity-graphs';
import AdminOpportunitySummaryCards from './admin-opportunity-summary-cards';

export default function AdminOpportunity() {

    useEffect(() => {
        axios.get('/track/' + 3);
    }, []);

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Typography variant='h5'>Missed Opportunity</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <AdminOpportunitySummaryCards />
            <AdminOpportunityGraphs />
            <AdminOpportunitySummary />
        </PageContent>
    );
}
