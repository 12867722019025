import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

export default function LinkDashboardModal({ id, userType = '', vendorList = [], memberList = [], onNewLink }) {

    const [open, setOpen] = useState(false);
    const [dbaId, setDbaId] = useState('');

    const handleDialogClose = () => {
        setOpen(false);
        setDbaId('');
    }

    const handleSaveAndDialogClose = (event) => {
        event.preventDefault();
        axios.post('/api/user/assign-dashboard', { id, dbaId }).then(resp => {
            handleDialogClose();
            onNewLink();
        });
    }

    let userGroupList = [];
    if (userType === 'VENDOR') {
        userGroupList = [...vendorList];
    } else if (userType === 'MEMBER') {
        userGroupList = [...memberList];
    }

    return (
        <Box>
            <IconButton size="small" onClick={() => setOpen(true)}><AddIcon sx={{ fontSize: 'inherit' }} /></IconButton>
            <Dialog open={open} onClose={handleDialogClose} component="form" onSubmit={handleSaveAndDialogClose}>
                <DialogTitle>Link New Dashboard ID</DialogTitle>
                <DialogContent sx={{ minWidth: 500 }}>
                    <FormControl fullWidth margin='normal'>
                        <InputLabel id="select-group">SELECT {userType}</InputLabel>
                        <Select labelId="select-group" id="group-select" value={dbaId} label={`SELECT ${userType}`} onChange={event => setDbaId(event.target.value)} required>
                            <MenuItem value={''}>SELECT {userType}</MenuItem>
                            {userGroupList.map(group => <MenuItem value={group.dbaId} key={`sel-group-${group.dbaId}`}>{group.dbaName}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" color="success" type='submit'>Assign Dashboard ID</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}