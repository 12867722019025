import { Avatar, Box, Chip, Toolbar,Grid,Paper } from '@mui/material';
import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import MemberOpportunity from './opportunity';
import MemberOverviewCards from './overview-summary-cards';
import axios from "axios";
import { useSnackbar } from 'notistack';

function MemberOverview() {
    
    const { memberIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/'+ 11).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);

    return (
        <PageContent ready={memberIds.length > 0}>
            <React.Fragment>
            <SecondaryAppBar>
                    <Toolbar>
                    <Grid container spacing={1}>
                    <Grid item xs={9}>
                    {memberIds.map(member => <Chip key={'dba-' + member.dbaId} avatar={<Avatar>M</Avatar>} label={member.dbaName} sx={{ mr: 2 ,height:'40px'}} />)}
                        </Grid>  
                        <Grid item xs={1} sx={{justifyContent:"end"}}>
                        <PageFilters />
                        </Grid>
                        </Grid>
                    </Toolbar>
                </SecondaryAppBar>
                <Paper elivation={1} sx={{ p: 2, mt: 4 }}>
                <MemberOverviewCards />
                </Paper>
                <MemberOpportunity />
            </React.Fragment>
        </PageContent>
    );
}

export default MemberOverview;
