import { Box, Grid, Paper, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import AdminOverviewMemberPerformance from './admin-overview-member-performance';
import AdminOverviewSummary from './admin-overview-summary';
import AdminOverviewVendorPerformance from './admin-overview-vendor-performance';
import axios from "axios";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


export default function AdminOverview() {

    const [parentRollup, setParentRollup] = useState(true);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const componentParams = { parentRollup, selectedVendors, setSelectedVendors, selectedMembers, setSelectedMembers };
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        axios.get('/track/'+ 1).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Typography variant='h5'>Corporate Overview</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <ToggleButtonGroup sx={{ mr: 2 }} value={parentRollup} exclusive onChange={(_event, value) => setParentRollup(value)}>
                        <ToggleButton value={true}>PARENT LEVEL</ToggleButton>
                        <ToggleButton value={false}>DBA LEVEL</ToggleButton>
                    </ToggleButtonGroup>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <AdminOverviewSummary />
            <Paper elivation={1} sx={{ p: 2 }}>
                <Grid container columns={2} spacing={3}>
                    <Grid item xs={1}>
                        <AdminOverviewVendorPerformance {...componentParams} />
                    </Grid>
                    <Grid item xs={1}>
                        <AdminOverviewMemberPerformance {...componentParams} />
                    </Grid>
                </Grid>
            </Paper>
        </PageContent>
    );
}
