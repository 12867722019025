import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Chip, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MaterialSearch from '../../common/material-search';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import AddUserModal from './add-user-modal';
import DashboardIdChip from './dashboard-id-chip';
import LinkDashboardModal from './link-dashboard-modal';
import UserStatusDialog from './user-status-dialog';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const RenderUserEmail = ({ row, value }) => {
    return (
        <Stack spacing={0.5} sx={{ pt: 0.5, pb: 0.5 }}>
            <Box>{value}</Box>
            <Box component='small'>{row.person}</Box>
        </Stack>
    );
};

const RenderUserAccessLevel = ({ row, value }) => {
    return (
        <Stack spacing={0.5} sx={{ pt: 0.5, pb: 0.5 }}>
            <Box>{value}</Box>
            <Box component='small'>{row.userType}</Box>
        </Stack>
    );
};

const RenderDashboardIds = ({ row }, { vendorList, memberList, getUsers }) => {
    if (row.userType !== 'CORPORATE') {
        return (
            <Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
                {row.mappedId && row.mappedId.length > 0 ? row.mappedId.map(id => (
                    <DashboardIdChip dashboardId={id} userType={row.userType} key={id + 'id'} />
                )) : null}
                <LinkDashboardModal vendorList={vendorList} memberList={memberList} {...row} onNewLink={getUsers} />
            </Stack>
        );
    } else {
        return '';
    }
};

const RenderUserTerms = ({ row }) => {
    if (row.termsAccepted) {
        return row.termsAccepted;
    } else if (row.termsDeclined) {
        return 'DECLINED';
    }
    return 'NOT ACCEPTED';
};

const RenderUserStatus = ({ row }, { newId, deleteUser, getUsers }) => {
    let statusList = [];
    if (!row.enabled) {
        statusList.push({ label: 'Disabled', color: 'error' });
    }
    if (!row.accountNonExpired) {
        statusList.push({ label: 'Account Expired', color: 'warning' });
    }
    if (!row.accountNonLocked) {
        statusList.push({ label: 'Account Locked', color: 'warning' });
    }
    if (!row.credentialsNonExpired) {
        statusList.push({ label: 'Credentials Expired', color: 'warning' });
    }
    if (statusList.length === 0) {
        statusList.push({ label: 'Active', color: 'success' });
    }
    if (newId === row.id) {
        statusList.push({ label: 'New', color: 'warning' });
    }
    return (
        <Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
            {statusList.map((status, index) => <Chip key={'st-' + row.id + index} label={status.label} color={status.color} size="small" />)}
            <UserStatusDialog {...row} onStatusChange={getUsers} />
            <IconButton color="error" size="small" onClick={event => deleteUser(row)}><DeleteForeverIcon sx={{ fontSize: 'inherit' }} /></IconButton>
        </Stack>
    );
};

export default function AdminDirectory() {

    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);
    const [newId, setNewId] = useState(null);

    const getUsers = () => {
        setLoading(true);
        axios.get("/api/user").then(result => {
            setLoading(false);
            setRows(result.data);
        }).catch(error => {
            setLoading(false);
            setRows([]);
        });
    };

    const deleteUser = ({ id, person, email }) => {
        if (window.confirm(`Delete ${person}<${email}> permanently?`)) {
            axios.post("/api/user/delete", { id }).then(getUsers);
        }
    };

    const handleUserCreate = (id) => {
        setNewId(id);
        getUsers();
    };

    // const exportUser = () => {
    //     axios.get('/api/user/export', { responseType: 'blob' }).then((response) => {
    //         const href = URL.createObjectURL(response.data);
    //         const link = document.createElement('a');
    //         link.href = href;
    //         link.setAttribute('download', `Directory - ${(new Date()).toLocaleDateString()}.xlsx`);
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         URL.revokeObjectURL(href);
    //     });
    // };

    const generateExportUserDirectory=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("UserDirectory", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${visibleRows.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK User Directory";
        const title1 = `Based on collective Purchasing Survey data`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["PERSON NAME","EMAIL","ACCESS LEVEL","COMPANY","CREATED ON","LINK DASHBOARDS"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");

        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
     
        
          visibleRows.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.person,obj.email, obj.role, obj.defaultGroupName,obj.createdOn,obj.defaultGroupId]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 40;
        worksheet.getColumn(3).width = 40;
        worksheet.getColumn(4).width = 25;
        worksheet.getColumn(5).width = 25;
        worksheet.getColumn(6).width = 25;

       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'User ';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "UserDirectory.xlsx");
        });
      
    }


    useEffect(() => {
        axios.get('/track/' + 9);
        getUsers();
        axios.post("/api/groups/dba", { groupType: 'V' }).then(result => setVendorList(result.data));
        axios.post("/api/groups/dba", { groupType: 'M' }).then(result => setMemberList(result.data));
        axios.get("/api/user/types").then(result => setUserTypeList(result.data));
    }, []);

    const columns = [
        { field: 'email', headerName: 'EMAIL', width: 250, renderCell: RenderUserEmail },
        { field: 'role', headerName: 'ACCESS LEVEL', width: 200, renderCell: RenderUserAccessLevel },
        { field: 'defaultGroupName', headerName: 'Company', width: 150 },
        { field: 'createdOn', headerName: 'CREATED ON', width: 120 },
        { field: 'defaultGroupId', headerName: 'LINKED DASHBOARDS', width: 500, renderCell: (params) => RenderDashboardIds(params, { vendorList, memberList, getUsers }) },
        { field: 'enabled', headerName: 'STATUS', width: 300, renderCell: (params) => RenderUserStatus(params, { newId, deleteUser, getUsers }) },
        { field: 'termsAccepted', headerName: 'TERMS', width: 150, renderCell: RenderUserTerms }

    ];

    const visibleRows = search ? rows.filter(row => String(row.email).toLowerCase().includes(search.toLowerCase()) || String(row.person).toLowerCase().includes(search.toLowerCase()) || String(row.role).toLowerCase().includes(search.toLowerCase())) : rows;

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Typography variant='h5'>Directory</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>
                </Toolbar>
            </SecondaryAppBar>
            <Paper elivation={3} sx={{ p: 3 }}>
                <Toolbar disableGutters sx={{ justifyContent: 'right' }}>
                    <MaterialSearch value={search} onChange={event => setSearch(event.target.value)} placeholder='Search Users' />
                    <AddUserModal vendorList={vendorList} memberList={memberList} userTypeList={userTypeList} onCreate={handleUserCreate} />
                    {/* <Button variant="outlined" color='warning' onClick={exportUser}>Export</Button> */}
                    <Button variant="contained"  onClick={() => generateExportUserDirectory()}><FileDownloadOutlinedIcon/>Export</Button>

                </Toolbar>
                <DataGrid autoHeight rows={visibleRows} columns={columns} getRowId={(row) => row.id} pageSize={pageSize} onPageSizeChange={setPageSize} rowsPerPageOptions={[10, 50, 100]}
                    density='compact' loading={loading} selectionModel={selectedUsers} onSelectionModelChange={setSelectedUsers} disableColumnMenu getRowHeight={() => 'auto'} />
            </Paper>
        </PageContent>
    );
}