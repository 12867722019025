import { Box, Toolbar } from '@mui/material';
import axios from "axios";
import React, { useEffect } from 'react';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import AdminDataQualitySummary from './admin-dataQuality';

function AdminDataQuality() {

    useEffect(() => {
        axios.get('/track/' + 8);
    }, []);

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <AdminDataQualitySummary />
        </PageContent>
    );
}

export default AdminDataQuality;