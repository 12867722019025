import { Avatar, Button, Checkbox, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MEMBER_IDS } from '../../../middleware/filter';

export default function MemberSelectionButton({ useVendorFilter }) {

    const dispatch = useDispatch();
    const { year, memberIds, vendorIds } = useSelector(state => state.filter);
    const [open, setOpen] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [checked, setChecked] = useState([]);
    const getDependencies = () => useVendorFilter ? [year, vendorIds] : [year];

    const getParams = () => {
        let params = { year };
        if (useVendorFilter) {
            params = { ...params, vendorId: vendorIds.map(row => row.dbaId) };
        }
        return params;
    };

    useEffect(() => {
        axios.post('api/vpv/members', getParams()).then(response => {
            dispatch({ type: SET_MEMBER_IDS, payload: [] });
            setMemberList(response.data);
        });
    }, getDependencies());

    useEffect(() => {
        setChecked(memberIds && memberIds.length > 0 ? memberIds.map(row => row.dbaId) : []);
    }, [memberIds]);

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClose = () => {
        dispatch({ type: SET_MEMBER_IDS, payload: memberList.filter(row => checked.includes(row.dbaId)) });
        setOpen(false);
    };

    const handleCancel = () => {
        setChecked(memberIds.map(row => row.dbaId));
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant='outlined' onClick={() => setOpen(true)}>Select MP</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Select Member Partner</DialogTitle>
                <List sx={{ width: '100%', maxWidth: 700, minWidth: 500, overflow: 'auto', maxHeight: 500, bgcolor: 'background.paper' }}>
                    {memberList.map((member) => {
                        const labelId = `checkbox-list-label-${member.dbaId}`;
                        return (
                            <ListItem key={labelId} disablePadding secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={() => handleToggle(member.dbaId)}
                                    checked={checked.indexOf(member.dbaId) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            }>
                                <ListItemButton role={undefined} onClick={() => handleToggle(member.dbaId)} dense>
                                    <ListItemAvatar><Avatar>VP</Avatar></ListItemAvatar>
                                    <ListItemText id={labelId} primary={member.dbaName} secondary={member.dbaId === member.parentId ? 'PARENT' : 'DBA'} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleClose}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
