import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER } from '../../../middleware/filter';

const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4, currentYear - 5, , currentYear - 6];

export default function FilterYear() {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.filter);

   
    const handleChange = (event) => {
        let month = 12;
        const year = parseInt(event.target.value);
        if (year === new Date().getFullYear()) {
            month = new Date().getMonth() + 1;
            console.log('filter-year',month)
        }
        dispatch({ type: SET_FILTER, payload: { ...filter, year, month, quarter: Math.ceil(month / 3) } });
    };
    return (
        <FormControl sx={{ minWidth: 120 }} size="small" variant="filled">
            <InputLabel id="year-select-small">Year</InputLabel>
            <Select labelId="year-select-small" id="year-select-small" value={filter.year} label="Year" onChange={handleChange}>
                {years.map(year => <MenuItem key={'year-' + year} value={year}>{year}</MenuItem>)}
            </Select>
        </FormControl>
    );
}
