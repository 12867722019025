import { Alert, Box, Button, CircularProgress, Container, Paper, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import dashboardLogo from '../../assets/images/bh.png';

export default function SetPassword() {

    const [error, setError] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [user, setUser] = useState(true);
    const [validationResult, setValidationResult] = useState();
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    const handlePasswordChange = event => setPassword(event.target.value);

    const handleConfirmPasswordChange = event => {
        const value = event.target.value;
        if (value.length >= password.length) {
            if (password === value) {
                setValidationResult({ severity: 'success', message: 'Password matched!' });
            } else {
                setValidationResult({ severity: 'error', message: 'Password not matched!' });
            }
        } else {
            setValidationResult(null);
        }
        setConfirmPassword(value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validationResult.severity === 'success') {
            axios.post('/reset-password', { token: params.token, password, confirmPassword }).then(res => {
                navigate("/login", { replace: true });
            }).catch(({ response }) => setError(response.data.message));
        } else {
            enqueueSnackbar('Please check entered password!');
        }
    };

    useEffect(() => {
        axios.post('/validate-token', { token: params.token }).then(response => setUser(response.data)).catch(({ response }) => setError(response.data.message));
    }, [params]);

    return (
        <Container sx={{ minHeight: '100vh', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
            <Paper elevation={4} sx={{ p: 4 }}>
                {user || error ? (
                    <Stack spacing={2} component={'form'} onSubmit={handleSubmit} sx={{ minWidth: 500 }}>
                        <Box><img src={dashboardLogo} alt="bh logo" style={{ height: '5rem' }} /></Box>
                        <Typography variant="h5">Set New Password</Typography>
                        {error ? (
                            <Alert severity="error">{error}</Alert>
                        ) : (
                            <React.Fragment>
                                <Typography variant="caption">{user.person} {`<${user.email}>`}</Typography>
                                <TextField fullWidth variant="outlined" size="small" name="password" label="Password" type="password" onChange={handlePasswordChange} />
                                <TextField fullWidth variant="outlined" size="small" name="confirmPassword" label="Confirm Password" type="password" onChange={handleConfirmPasswordChange} />
                                {validationResult && <Alert severity={validationResult.severity}>{validationResult.message}</Alert>}
                                <Button type="submit" variant='contained' color='success'>Set Password</Button>
                            </React.Fragment>
                        )}
                        <Button component={Link} to='/login' variant='contained' color='primary'>Back to Login</Button>
                    </Stack>
                ) : (
                    <CircularProgress color='secondary' variant="indeterminate" size={100} thickness={2} />
                )}
            </Paper>
        </Container>
    );
}
