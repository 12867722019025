import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { modifyFiltersToOldFormat } from '../../../../src/middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const open1 = false;

export default function OpportunityPODetails({ memberId }) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  let { groupIds } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [tableData, setTableData] = useState([])
  const [rows, setRows] = React.useState(tableData);

  const [value2, setValue] = React.useState('2022-01-01');
  const [value3, setValue1] = React.useState('2022-07-26');
  const { period, year, month, quarter, memberIds } = useSelector(state => state.filter);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('asc');
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);
  const [searched, setSearched] = React.useState("");
  const [branchList, setBranchList] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  //const [memberIds, setMemberIds] = React.useState(groupIds.split(',').map(id => parseInt(id)));


  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }
  const loadData = () => {
    if (localStorage.getItem('authToken')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
    }
    let postParamsData = { year, month, period, quarter, memberId: memberIds.map(row => row.dbaId) };

    if (vpName != "") {
      postParamsData.dropDown = [{ vpName: [vpName] }];
    }
    else {
      postParamsData.dropDown = [];

    }

    postParamsData = clean(postParamsData);
    postParamsData = modifyFiltersToOldFormat(postParamsData);
    postParamsData.quarter = "qtr" + quarter;

    console.log('check postParamsDat', postParamsData)
    axios.post('/api/member/member-conversion/getTerminatedVpOpportunitiesTerminatedVpOpportunity', postParamsData)
      .then((res) => {
        setTableData(res.data)
        setRows(res.data)
        //.then((res) => setRows(res.data))

        console.log('initial post data ', tableData)
        console.log('initial post data ', rows)


      })
  };
  let tableDataBackup = [...rows]
  useEffect(() => {
    loadData();
    if (year && memberIds && memberIds.length > 0) {
      let params = new URLSearchParams();
      params.append('year', year);
      const parameters = { year, memberId: memberIds.map(row => row.dbaId) };

      Promise.all([
        axios.post('/api/member/member-conversion/getTerminatedVpOpportunitiesVPNames', parameters),
      ]).then((values) => {
        setVpNames(values[0].data);
        // console.log('setVpnames', values[0].data.data.vendorName)

        axios.post('/api/groups/dba/member', { 'ids': groupIds.split(',').map(id => parseInt(id)) }).then(response => setBranchList(response.data));


      });

    }
  }, []);

  //   const columns = [
  //     { name: "Name", options: { filterOptions: { fullWidth: true } } },
  //     "Title",
  //     "Location"
  //   ];


  useEffect(() => {
    loadData();
  }, [year, month, period, quarter, memberIds, vpName])


  const columns = [
    {
      name: 'terminatedVendorPartner', label: 'TERMINATED VENDOR PARTNER', align: 'right', minWidth: 170, options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'productGroup', label: 'BH CATEGORY', align: 'right', minWidth: 100, options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'purchases',
      label: 'VOLUME',
      minWidth: 70,
      align: 'right',
      options: {

        customBodyRender: value => <span>{<FormatCurrency value={value} />}</span>,
        filter: true,
        sort: true

      }
    },
    {
      name: 'vendor1',
      label: 'VENDOR PARTNER',
      minWidth: 170,
      align: 'right',
      //   <FeedbackIcon label={vendor} color="primary" />
      options: {

        customBodyRender: value => {
          if (value) {

            return <span> {value} <IconButton sx={{ ml: 0.5 }} size="small">
              <OpenInNewIcon onClick={() => setOpen1(!open1, value)} fontSize='inherit' /></IconButton></span>
          }
          else {
            return <span> {value}</span>
          }
        },
        filter: true,
        sort: true

      }
    },
    {
      name: 'vendor2',
      label: 'VENDOR PARTNER',
      minWidth: 170,
      options: {

        customBodyRender: value => {
          if (value) {
            return <span> {value}               <IconButton sx={{ ml: 0.5 }} size="small">
              <OpenInNewIcon onClick={() => setOpen1(!open1)} fontSize='inherit' /></IconButton></span>
          }
          else {
            return <span> {value}</span>
          }
        },
        filter: true,
        sort: true

      }
    },
    {
      name: 'vendor3',
      label: 'VENDOR PARTNER',
      minWidth: 170,
      options: {

        customBodyRender: value => {
          if (value) {
            return <span> {value}
              <IconButton sx={{ ml: 0.5 }} size="small">

                <OpenInNewIcon onClick={() => setOpen1(!open1)} fontSize='inherit' /> </IconButton></span>
          }
          else {
            return <span> {value}</span>
          }
        },
        filter: true,
        sort: true

      }
    },
    {
      name: 'vendor4',
      label: 'VENDOR PARTNER',
      minWidth: 170,
      options: {

        customBodyRender: value => {
          if (value) {
            return <span> {value}
              <IconButton sx={{ ml: 0.5 }} size="small">
                <OpenInNewIcon onClick={() => setOpen1(!open1)} fontSize='inherit' /></IconButton></span>
          }
          else {
            return <span> {value}</span>
          }
        },

        filter: true,
        sort: true

      }
    }
  ];

  function showDocumentStatusInfo() {
    console.log('column info is printed');
  }
  const handleFormSubmit = e => {
    e.preventDefault();
    let data = {}
    // axios.post('', JSON.stringify(data))
    //     .then(res => {

    //         if (res) {
    enqueueSnackbar('Feedback submitted successfully');
    handleClose();
    //     }
    // })
    // .catch(err => {
    //     console.log('err forget password', err)
    // })

  }
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: false, // <===== will turn off checkboxes in rows
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
  ];

  const generateExport=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("TerminatedVendorPartnerOpportunity", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${tableData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Terminated Vendor Partner Opportunity";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["TERMINATED VENDOR PARTNER","BH CATEGORY","VOLUME","VENDOR PARTNER","VENDOR PARTNER",
    "VENDOR PARTNER","VENDOR PARTNER",];
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
    
    
    tableData.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.terminatedVendorPartner, obj.productGroup,
          '$'+Math.round(obj.purchases) ,obj.vendor1,obj.vendor2,obj.vendor3,obj.vendor4]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
   
 
   
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Terminated Vendor Partner Opportunity';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "TerminatedVendorPartnerOpportunity.xlsx");
    });
  }


  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={createTheme()}>
        <div>
        <Button variant="contained" sx={{ ml: 2,height:'42px',float:'right',marginTop:'20px',marginRight:'23px'}} onClick={() => generateExport()}><FileDownloadOutlinedIcon/>Export</Button>

          <MUIDataTable
            title={"Terminated Vendor Partner Opportunity"}
            data={tableData}
            columns={columns}
            options={options}
          />
          </div>
        </ThemeProvider>
      </CacheProvider>

      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction='column' spacing={4} marginBottom='5' >

            <Typography id="modal-modal-title" variant="h6" component="h2">
              Is { } wrong vendor partner for ?
            </Typography>
            <FormControl>

              <InputLabel>Select Supplier</InputLabel>
              <Select
                labelId="vp-name-select-large"
                id="vp-name-select-large"
                value={vpName}
                label="Select Supplier"
                sx={{ marginBottom: 2, minHeight: 55 }}
                onChange={(event) => setVpName(event.target.value)}
                size='large'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>
            <Stack direction='row' spacing={4} marginBottom='5' >
              <Button color="primary" onClick={handleFormSubmit}
                variant="contained" style={{ marginBottom: "0px" }}>
                Submit
              </Button>
              <Button color="secondary" onClick={handleClose}
                variant="contained" style={{ marginBottom: "0px" }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>

  );
}



