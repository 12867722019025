import { Avatar, Box, Chip, Grid, Paper, Stack, Toolbar } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import Map from './Map';
import SummaryMemberView from './summayMemberView';
import VendorOpportunityTrend from './vendor-opportunity-trend';
import VolumeOpportunityByMemberCategories from './volumeOpportunityByMemberCategories';

export default function Opportunity() {

    const { vendorIds } = useSelector(state => state.filter);
    const [stateCodes, setStateCodes] = useState([]);

    useEffect(() => {
        axios.get('/track/' + 16);
    }, []);

    return (
        <PageContent ready={vendorIds.length > 0}>
            <SecondaryAppBar>
                <Toolbar>
                    {vendorIds.map(vendor => <Chip key={'dba-' + vendor.dbaId} avatar={<Avatar>V</Avatar>} label={vendor.dbaName} sx={{ mr: 2 }} />)}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <Paper elivation={1} sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={5}>
                    <Grid item xs={7}>
                        <VendorOpportunityTrend />
                    </Grid>
                    <Grid item xs={5}>
                        <Map {...{ stateCodes, setStateCodes }} />
                    </Grid>
                </Grid>
            </Paper>
            <Stack direction='column' spacing={5}>
                <SummaryMemberView {...{ stateCodes, setStateCodes }} />
                <VolumeOpportunityByMemberCategories {...{ stateCodes, setStateCodes }} />
            </Stack>
        </PageContent>
    );
}
