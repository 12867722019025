import { Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import FilterMonth from './filter-month';
import FilterQuarter from './filter-quarter';
import FilterYear from './filter-year';


export default function PageFiltersCopy() {
    const selectedPeriod = useSelector(state => state.filter.period);
    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterYear />
            {/* {selectedPeriod === 'qtr' ? <FilterQuarter /> : <FilterMonth />} */}
        </Stack>
    );
}