import { TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

export const headCells = [
    { key: 'vendorName', label: 'VP Name DBA' },
    { key: 'memberName', label: 'Member DBA' },
    { key: 'programName', label: 'Program Name' },
    { key: 'payoutType', label: 'Calculated' },
    { key: 'tier', align: 'right', label: 'Active Tier' },
    { key: 'isRebate', align: 'right', label: 'Rebate' },
    { key: 'rebate', align: 'right', label: 'Rebate Total' },
    { key: 'attainment', align: 'right', label: 'Attainment' },
    { key: 'lastUpdated', align: 'right', label: 'Updated' }

    // { key: 'vendorName', label: 'VP Name DBA' },
    // { key: 'memberName', label: 'Member DBA' },
    // { key: 'programName', label: 'Program Name' },
    // { key: 'payoutType', label: 'Calculated' },
    // { key: 'tier',  label: 'Active Tier' },
    // { key: 'isRebate', label: 'Rebate' },
    // { key: 'rebate', label: 'Rebate Total' },
    // { key: 'attainment',  label: 'Attainment' },
    // { key: 'lastUpdated', label: 'Updated' }
];

export default function VpvTableHeader({ colDefs, order, orderBy, onRequestSort }) {

    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        // <div class="tableHeader">
        <TableHead>
            <TableRow>
                <TableCell style={{width:'4%'}}/>
                {colDefs.map(col => (
                    // <TableCell style={{width:'8%'}}key={col.key} align={col.align ? col.align : 'left'} sortDirection={orderBy === col.key ? order : false}>
                    //     <TableSortLabel active={orderBy === col.key} direction={orderBy === col.key ? order : 'asc'} onClick={createSortHandler(col.key)}>
                    //         {col.label}
                    //     </TableSortLabel>
                    // </TableCell>
                    <TableCell style={{width:'12%'}}key={col.key} align={col.align ? col.align : 'left'} sortDirection={orderBy === col.key ? order : false}>
                        <TableSortLabel active={orderBy === col.key} direction={orderBy === col.key ? order : 'asc'} onClick={createSortHandler(col.key)}>
                            {col.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
        // </div>
    );
}