import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Switch, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import { FormatCurrency } from '../../common/formatter';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ float: 'right' }}>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        sx={{
          backgroundColor: '#556cd6', '&:hover': {
            backgroundColor: "#556cd6",
          },
          top: '-52px', right: '-8px', position: 'absolute'
          , padding: '6px 12px', fontSize: '14px', color: '#fff', marginRight: '12px'
        }}
      />
    </GridToolbarContainer>
  );
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CategoryDetailsDialog({ open, handleClose }) {
  return (
    ''
  );
}

function CategoryFeedbackDialog({ open, handleClose }) {
  return (
    ''
  );
}


export default function ConversionOpportunities() {

  const { year, month, period, quarter, vendorIds } = useSelector(state => state.filter);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [categoryRows, setCategoryRows] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  const [categoryName, setCategoryName] = useState('');

  const [feedbackName, setFeedbackName] = useState('');
  const [productId, setProductId] = useState('');

  const [Liststotal, setCategoryListtotal] = React.useState(['']);
  const [ListsId1, setCategoryListId1] = React.useState(['']);
  const [productRows, setProductRows] = useState([]);
  const [vpName, setVpName] = React.useState('');
  const [vpNames, setVpNames] = React.useState(['']);

  const [Lists, setCategoryList] = React.useState(['']);
  const [Lists1, setCategoryList1] = React.useState(['']);
  const [removeToggle, setRemoveToggle] = useState(false);



  const handleCategoryDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }

  const handleFeedackDialogClose = (e) => {
    console.log('handleCategoryDialogClose')
  }

  const handleClose = () => {
    setOpenCategoryDialog(false);
    setOpenFeedbackDialog(false);

  };

  useEffect(() => {
    setLoading(true);
    const params = modifyFiltersToOldFormat({ year, month, period, quarter, vendorId: vendorIds.map(row => row.dbaId) });
    if (vpName != '') {
      params.dropDown = [{ vpName: [vpName] }];

    }
    else {
      params.dropDown = [];
    }

    Promise.all([
      axios.post('/api/vendor/conversion/getTerminatedVpOpportunityByCategories', { ...params }),
    ]).then(response => {
      setLoading(false);
      setCategoryRows(response[0].data.map((row, index) => ({ ...row, id: index })));
    });
  }, [year, month, period, quarter, vpName]);

  useEffect(() => {
    let params = { vendorId: vendorIds.map(row => row.dbaId) ,year};

    Promise.all([
      axios.post('/api/vendor/conversion/getTerminatedVpVendorNames', params),
    ]).then((values) => {
      setVpNames(values[0].data);
      console.log('setVpnames', values)


    });

  }, [year]);

  useEffect(() => {
    Promise.all([
      axios.post('/api/common/getBhCategoryList'),

    ]).then(response => {
      setLoading(false);
      setCategoryList(response[0].data.map((row, index) => ({ ...row, id: index })));


    });

  }, []);
  const columnCategoryOpportunity = [
    // { field: "categoryId", headerName: "CATEGORY ID", flex: 1 },
    { field: "category", headerName: "CATEGORY", flex: 1 },
    {
      field: "sales", headerName: "VOLUME", width: 200,
      renderCell: (params) => <FormatCurrency value={params.value} />
      // <LinearProgressWithVolume value={params.value} />
    },
    {
      field: "", headerName: "PRODUCTS", width: 90, renderCell: (params) => (
        <React.Fragment>
          {console.log(params.row.category)}
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenCategoryDialog(params.row.category)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      )
    },];


  const columnProductPurchases = [
    { field: "productDescription", headerName: "PRODUCT DESCRIPTION", width: 400, align: 'right' },
    {
      field: "", headerName: "FEEDBACK", flex: 1, renderCell: (params1) => (
        <React.Fragment>
          <IconButton sx={{ ml: 0.5 }} size="small"
            onClick={() => handleOpenfeedbackDialog(params1.row.productDescription, params1.row.productId)}><OpenInNewIcon fontSize='inherit' /></IconButton>
        </React.Fragment>
      )
    },];



  const handleOpenCategoryDialog = (bhCategoryName) => {
    setOpenCategoryDialog(true);
    console.log('open category dialog modal component', setCategoryName(bhCategoryName));
    setLoading(true);

    Promise.all([
      axios.post('/api/vendor/conversion/getProductsPurchasedByMembers', { bhCategory: bhCategoryName }),

    ]).then(response => {
      setLoading(false);
      setProductRows(response[0].data);
    });

  };

  const handleOpenfeedbackDialog = (desc, desc1) => {
    console.log(desc, desc1, " description ")
    setOpenFeedbackDialog(true);
    setProductId(desc1)
    console.log('open feedback dialog modal component', setFeedbackName(desc));
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    //     bhCategoryName: "Spiral Pipe and Fittings"
    // comments: ""
    // productDescription: "BFG45 14\" GASKETED 45deg ELBOW"
    // productId: "621421"
    // statusForProductsNotSold: false
    // statusForWrongClassification: true
    // suggestedBhCategoryId: 85
    // suggestedBhCategoryName: "Access Doors"
    // wrongCategory: 1

    let data = {
      bhCategoryName: categoryName,
      comments: "",
      productDescription: feedbackName,
      productId: productId,
      statusForProductsNotSold: false,
      statusForWrongClassification: true,
      suggestedBhCategoryId: ListsId1,
      suggestedBhCategoryName: Lists1,
      wrongCategory: 1,
    }
    axios.post('api/corporate/feedback/insertFeedbackWrongCategoryFeedback', JSON.stringify(data))
      .then(res => {

        if (res) {
          console.log('Feedback submitted successfully');
        }
      })
      .catch(err => {
        console.log('err forget password', err)
      })

  }

  const categoryset = (e) => {
    let text = e.target.value
    setCategoryListtotal(text);

    let key = text.split("#")[1];
    let value = text.split("#")[0];
    setCategoryList1(value);
    setCategoryListId1(key);
    console.log("valuevalue", value)
    console.log("keykey", key)


  }

  const generateOppByCategory=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("OppByCategory", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${categoryRows.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Opportunity By Category";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["CATEGORY","VOLUME"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });

    
    categoryRows.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.category,
             '$'+Math.round(obj.sales)]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
        });
    });
  
    
  
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 25;
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Opportunity By Category';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "OppByCategory.xlsx");
    });
  
}


  return (
    <Paper sx={{ p: 2, mt: 5 }}>
      <Grid container spacing={3} columns={2}>
        <Grid item xs={3}>
          <Stack direction='row' spacing={34} >
          <div class="parent" style={{width:'100%'}}>
          <div class="block1" style={{width:'33.33%',float:'left'}}>
            <Typography variant='h6' sx={{ mb: 1 }}>Opportunity By Category</Typography>
            </div>
            <div class="block2" style={{width:'33.33%',float:'left'}}>
            <FormControl>

              <InputLabel>Select Supplier</InputLabel>
              <Select
                labelId="vp-name-select-small"
                id="vp-name-select-small"
                value={vpName}
                label="Select Supplier"
                sx={{ minWidth: 200, marginBottom: 2, minHeight: 40, float: 'right' }}
                onChange={(event) => setVpName(event.target.value)}
                size='small'
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
              </Select>
            </FormControl>
            </div>
            <div class="block3" style={{width:'33.33%',float:'right'}}>
            <Button variant="contained" onClick={() => generateOppByCategory()} sx={{height:'24px',padding:'20px 10px',float:'right'}}><FileDownloadOutlinedIcon/>Export</Button>
          </div>
          </div>
          </Stack>
          <div>
          <DataGrid sx={{ height: 500 }} disableColumnMenu density='compact' loading={loading}

            rows={categoryRows} columns={columnCategoryOpportunity} pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]}
            getRowId={(row) => row.category}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            // localeText={{
            //   toolbarExport: "export"
            // }}
          />
          </div>
        </Grid>
       
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openCategoryDialog}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "50%",
                height: '100%',
                maxWidth: 'none'
              },
            },
          }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Products purchased in <strong>{categoryName}
              {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</strong>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid item xs={2}>
              {/* <Typography variant='h6' sx={{ mb: 1 }}></Typography> */}
              <DataGrid autoHeight disableColumnMenu density='compact' loading={loading} rows={productRows} columns={columnProductPurchases}
                getRowId={(row) => row.productId}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]}
                disableSelectionOnClick />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>Close</Button>
          </DialogActions>
        </BootstrapDialog>


      </Grid>
      <CategoryDetailsDialog open={openCategoryDialog} handleClose={value => handleCategoryDialogClose(value)} />


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openFeedbackDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "40%",
              height: '70%',
              maxWidth: 'none'
            },
          },
        }}
      >
        <Box sx={{ flexGrow: 1, p: 5 }}>

          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Product feedback for <strong>{categoryName}</strong>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid item xs={2}>
              <Box>
                <Stack direction='column' spacing={4} marginBottom='5' >

                  <Typography id="modal-modal-title" variant="body1" component="h2">
                    Is <strong>{feedbackName}</strong> wrongly classified as <strong>{categoryName}</strong>?
                    <FormControlLabel control={<Switch checked={removeToggle} onChange={() => setRemoveToggle(!removeToggle)} />} />

                  </Typography>
                  {removeToggle ? (
                    <FormControl>

                      <InputLabel>Select Category</InputLabel>
                      <Select
                        labelId="category-name-select-large"
                        id="category-name-select-large"
                        value={Liststotal}
                        label="Select category"
                        sx={{ marginBottom: 2, minHeight: 55 }}
                        onChange={(event) => categoryset(event)}
                        size='large'
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {Lists.map(vp => (<MenuItem key={vp.bhCategoryId} value={vp.bhCategoryName + "#" + vp.bhCategoryId}>{vp.bhCategoryName}</MenuItem>))}
                      </Select>
                    </FormControl>) : ''}


                  <Stack direction='row' spacing={4} marginBottom='5' >
                    <Button color="primary" onClick={handleFormSubmit}
                      variant="contained" style={{ marginBottom: "0px" }}>
                      Submit
                    </Button>
                    <Button color="secondary" onClick={handleClose}
                      variant="contained" style={{ marginBottom: "0px" }}>
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Box>

            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>Close</Button>
          </DialogActions>
        </Box>

      </BootstrapDialog>

      <CategoryFeedbackDialog open={openFeedbackDialog} handleClose={value => handleFeedackDialogClose(value)} />



    </Paper>
  );
}