import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, Typography,Button } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormatCurrency } from '../../common/formatter';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

export default function InvoiceDataTable({ memberId }) {
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [searchBtn, setSearchBtn] = useState(true);
    const [downloadBtn, setDownloadBtn] = useState(false);
    const [printBtn, setPrintBtn] = useState(true);
    const [viewColumnBtn, setViewColumnBtn] = useState(true);
    const [filterBtn, setFilterBtn] = useState(true);
    const [loading, setLoading] = React.useState(false);

    let { groupIds } = useParams();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(500);
    const [tableData, setTableData] = useState([])
    const [rows, setRows] = React.useState(tableData);

    // const [value2, setValue] = React.useState('2022-01-01');
    // const [value3, setValue1] = React.useState('2022-07-26');
    const [value2, setValue] = React.useState('2023-01-01');
  const [value3, setValue1] = React.useState('2023-05-31');
    const { period, year, month, quarter, memberIds } = useSelector(state => state.filter);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('asc');
    const [vpName, setVpName] = React.useState('');
    const [vpNames, setVpNames] = React.useState(['']);
    const [searched, setSearched] = React.useState("");
    const [branchList, setBranchList] = React.useState([]);
    //const [memberIds, setMemberIds] = React.useState(groupIds.split(',').map(id => parseInt(id)));


    const clean = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj
    }

    const loadData = async () => { // funtion to call post invoice api. and get data
        if (localStorage.getItem('authToken')) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
        }
        let postParamsData = { memberId: memberIds.map(row => row.dbaId) };

        let currentDate = new Date().toISOString().slice(0, 10).split('/').reverse().join('-');
        var date = new Date();
        console.log(currentDate)


        postParamsData.startDate = value2;
        postParamsData.endDate = value3;
        postParamsData.invoiceNumber = searched;
        if (vpName != "") {
            postParamsData.dropDown = [{ vpName: [vpName] }];
        }
        else {
            postParamsData.dropDown = [];

        }

        postParamsData.startRowNo = 0
        postParamsData = clean(postParamsData);
        console.log('check postParamsDat', postParamsData)
        await axios.post('/api/member/invoice/getInvoiceData', postParamsData)
            .then((res) => {
                setLoading(false);
                setTableData(res.data)
                setRows(res.data)

            })
    }
    let tableDataBackup = [...rows]


    useEffect(() => {
        setLoading(true);
        const params = ({ year, memberId: memberIds.map(row => row.dbaId) });
        Promise.all([
            axios.post('/api/common/getActiveVendorList', { ...params }),

        ]).then(response => {
            setLoading(false);
            setVpNames(response[0].data);

        });
    }, [year]);

    useEffect(() => {
        setLoading(true);

        loadData();


    }, [vpName, value2, value3])

    const columns = [
        {
            name: 'vendorName', label: 'VENDOR NAME', align: 'right', minWidth: 80, options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'invoiceNumber', label: 'INVOICE NO', align: 'right', minWidth: 70, options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'purchaseOrderNumber',
            label: 'PO NO',
            minWidth: 170,
            align: 'right',
            options: {
                filter: true,
                sort: true
            }
        },
        //format: (value) => value.toLocaleString('en-US'),
        {
            name: 'fullDate',
            label: 'INVOICE DATE',
            minWidth: 170,
            align: 'right',
            options: {
                filter: true,
                sort: true
            },
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            name: 'productDesc',
            label: 'PRODUCT',
            minWidth: 170,
            align: 'right',
            //format: (value) => value.toFixed(2),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'quantity',
            label: 'QUANTITY',
            minWidth: 170,
            align: 'right',
            //format: (value) => value.toFixed(2),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'pricePerUnit',
            label: 'PRICE/UNIT',
            minWidth: 170,
            align: 'right',
            options: {

                customBodyRender: value => <span>{<FormatCurrency value={value} />}</span>,
                filter: true,
                sort: true

            }
        }, {
            name: 'amount',
            label: 'AMOUNT',
            minWidth: 170,
            align: 'right',
            // format: (value) => <FormatCurrency value = {value}/>,
            options: {

                customBodyRender: value => <span>{<FormatCurrency value={value} />}</span>,
                filter: true,
                sort: true

            }

        }];

    function showDocumentStatusInfo() {
        console.log('column info is printed');
    }

    const options = {
        search: searchBtn,
        download: downloadBtn,
        print: printBtn,
        viewColumns: viewColumnBtn,
        filter: filterBtn,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        selectableRows: false,
        selectableRows: false, // <===== will turn off checkboxes in rows

        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    const data = [
    ];
    const generateExport=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("InvoiceTab", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${tableData.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Invoice Tab";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["VENDOR NAME","INVOICE NO","PO NO","INVOICE DATE",
        "PRODUCT","QUANTITY","PRICE/UNIT","AMOUNT"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");

        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
        
        
        tableData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.vendorName, obj.invoiceNumber, obj.purchaseOrderNumber,obj.fullDate,obj.productDesc,
                 obj.quantity,'$'+Math.round(obj.pricePerUnit) ,'$'+Math.round(obj.amount)]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 25;
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 40;
        worksheet.getColumn(6).width = 10;
        worksheet.getColumn(7).width = 10;
        worksheet.getColumn(8).width = 15;
     
       
      
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Invoice Tab';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "InvoiceTab.xlsx");
        });
      }

    return (
        <Box sx={{ mt: 2, mb: 2 }}>

            <Stack direction='row' spacing={4} marginBottom='10' >

                <FormControl>

                    <InputLabel>Select Supplier</InputLabel>
                    <Select
                        labelId="vp-name-select-small"
                        id="vp-name-select-small"
                        value={vpName}
                        label="Select Supplier"
                        sx={{ minWidth: 450, marginBottom: 2, minHeight: 55 }}
                        onChange={(event) => setVpName(event.target.value)}
                        size='small'
                    >
                        <MenuItem value="">
                            <em>All</em>
                        </MenuItem>
                        {vpNames.map(vp => (<MenuItem key={vp.vendorName} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        type="date"
                        value={value2}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <p>to</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <DatePicker
                        type="date"
                        label="End date"
                        value={value3}
                        onChange={(newValue1) => {
                            setValue1(newValue1);
                        }}

                        renderInput={(params1) => <TextField {...params1} helperText={null} />}
                    />
                </LocalizationProvider>
                <Button variant="contained" sx={{ ml: 2,height:'42px' }} onClick={() => generateExport()}><FileDownloadOutlinedIcon/>Export</Button>

            </Stack>
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={createTheme()}>

                    <MUIDataTable
                        title={
                            <Typography variant="h6">
                                Invoice Tab
                                {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                            </Typography>
                        }
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </CacheProvider>
        </Box>
    );

}

