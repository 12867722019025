import { Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import OverviewCard from '../../common/overview-card';

const defaultSalesData = [
    { total: 0, cardName: "Member Purchase", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Total Rebate Estimated", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Potential Purchase", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Potential Rebate", yoy: 0, updatedDate: null }
];

export default function MemberOverviewCards() {

    const { year, month, period, quarter, memberIds } = useSelector(state => state.filter);
    const [salesData, setSalesData] = useState(defaultSalesData);
    const [rebateData, setRebateData] = useState([]);

    useEffect(() => {
        let params = modifyFiltersToOldFormat({ period, year, month, quarter, memberId: memberIds.map(row => row.dbaId) });
        axios.post('/api/member/member-data/getNonRebateCards', { ...params }).then(response => setSalesData(response.data));
        axios.post('/api/member/member-data/getMemberDataRebateCards', { ...params }).then(response => setRebateData(response.data));
    }, [year, month, period, quarter, memberIds]);

    return (
        <Paper sx={{ mb: 2, p: 2 }} elevation={1}>
            <Typography variant='h6' sx={{ mb: 1 }}>Overall Volume and Rebate ( Member Data )</Typography>
            <Grid container spacing={2} columns={salesData.length}>
                {salesData.map(card => {
                    if (card.cardName === 'potentialPurchase') {
                        card.cardName = 'Potential Purchase';
                    }
                    if (card.cardName === 'potentialRebate') {
                        card.cardName = 'Potential Rebate';
                    }
                    return (
                        <Grid item xs={1} key={'sales-item-' + card.cardName}>
                            <OverviewCard value={card.total} yoy={card.yoy} updateDate={card.updatedDate} title={card.cardName} />
                        </Grid>
                    );
                })}
            </Grid>
            <Typography variant='h6' sx={{ mb: 1, mt: 1.5 }}>Rebate Estimates (Member Data)</Typography>
            <Grid container spacing={2} columns={rebateData.length}>
                {rebateData.map(card => (
                    <Grid item xs={1} key={'rebate-item-' + card.cardName}>
                        <OverviewCard value={card.total} yoy={card.yoy} updateDate={card.updatedDate} title={card.cardName} />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}