import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RuleIcon from '@mui/icons-material/Rule';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import AppLayout from '../common/app-layout';

const adminLinks = [
    { url: '/admin', label: 'Corporate Overview', icon: <DashboardIcon /> },
    { url: '/admin/rebateData', label: 'Rebate Data', icon: <MonetizationOnIcon /> },
    { url: '/admin/opportunities', label: 'Opportunities', icon: <PriceCheckIcon /> },
    { url: '/admin/feedback', label: 'Feedback', icon: <QuestionAnswerIcon /> },
    { url: '/admin/terminatedOpportunities', label: 'Terminated VP Opportunities', icon: <PriceCheckIcon /> },
    { url: '/admin/categoryDetails', label: 'Category Details', icon: <DashboardIcon /> },
    { url: '/admin/compliance', label: 'Compliance', icon: <RuleIcon /> },
    { url: '/admin/dataQuality', label: 'Data Quality', icon: <DashboardIcon /> },
    { url: '/admin/market-share', label: 'Market Share', icon: <DashboardIcon /> },
    { url: '/admin/directory', label: 'Directory', icon: <ManageAccountsIcon /> }
];

export default function Admin() {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(0);

    useEffect(() => {
        setActiveLink(adminLinks.findIndex(link => matchPath(link.url, location.pathname)));
    }, [location]);

    return (
        <React.Fragment>
            <AppLayout setBranchDialog={false} sideNav={
                <List component="nav" aria-label="main mailbox folders">
                    {adminLinks.map((link, linkIndex) => (
                        <ListItemButton component={Link} key={link.url} selected={activeLink === linkIndex} to={link.url}>
                            <ListItemIcon>{link.icon}</ListItemIcon>
                            <ListItemText primary={link.label} />
                        </ListItemButton>
                    ))}
                </List>
            } />
        </React.Fragment>
    );
}
