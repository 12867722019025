import { Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import FilterMonth from './filter-month';
import FilterPeriodVpv from './filter-period-vpv';
import FilterQuarter from './filter-quarter.js';
import FilterYear from './filter-year';


export default function PageFiltersVpv() {
    const selectedPeriod = useSelector(state => state.filter.period);
    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <FilterPeriodVpv />
            <FilterYear />
            {selectedPeriod === 'qtr' ? <FilterQuarter /> : <FilterMonth />}
        </Stack>
    );
}
