import { Avatar, Box, Chip, Toolbar, Grid, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageFiltersVpv from '../../common/filter/page-filters-vpv';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import VpvTable from '../../common/vpv-table';
import MemberVerifiedSummary from './member-verified-summary';
import axios from "axios";
import { useSnackbar } from 'notistack';

function MemberVerified() {
    const { enqueueSnackbar } = useSnackbar();
    const { memberIds } = useSelector(state => state.filter);

    useEffect(() => {
        axios.get('/track/' + 10).then((res) => {
            console.log('console create user post api data is ', res.data)
            if (res.data) {
                enqueueSnackbar('Corporate Overview tab');
            }
        })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar("Error Message", error.response.data.error);
                    // alert("Error Message",error.response.data.error);
                }
            });

    }, []);

    return (
        <PageContent ready={memberIds.length > 0}>
            <React.Fragment>
                <SecondaryAppBar>
                    <Toolbar>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>

                                {/* {memberIds.map(member =>
                    <Chip key={'dba-' + member.dbaId} avatar={<Avatar>M</Avatar>} label={member.dbaName} sx={{ mr: 2 ,height:'40px'}} />)} */}
                                {memberIds.map((member) => {
                                    //  member.dbaName === member.parentName ?
                                       return <Chip key={'dba-' + member.dbaId} avatar={<Avatar>M</Avatar>} label={member.dbaName} sx={{ mr: 2, height: '40px' }} />
                                })}
                            </Grid>
                            <Grid item xs={1} sx={{ justifyContent: "end" }}>
                                <PageFiltersVpv />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </SecondaryAppBar>
                <Paper elivation={1} sx={{ p: 2, mt: 4 }}>

                    <MemberVerifiedSummary />
                </Paper>
                <VpvTable />
            </React.Fragment>
        </PageContent>
    );
}

export default MemberVerified;