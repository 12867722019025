import { CssBaseline, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { appTheme } from './app-style';
import { store } from './middleware';
import RouterModule from './modules/router-module';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

if (process.env.REACT_APP_BASE_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}

export default function BluehawkApp() {
    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline enableColorScheme />
            <Provider store={store}>
                <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                    <HashRouter>
                        <RouterModule />
                    </HashRouter>
                </SnackbarProvider>
            </Provider>
        </ThemeProvider>
    );
}
