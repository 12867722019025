import { FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PERIOD } from '../../../middleware/filter';
import Tooltip from '@mui/material/Tooltip';


export default function FilterPeriodVpv() {
    const dispatch = useDispatch();
    const selectedPeriod = useSelector(state => state.filter.period);
    const handleChange = (event, value) => {
        if(value == 'ytd' || value == "ytd")
        {
            dispatch({ type: SET_PERIOD, payload: 'ytd' });

        }
        else {
        dispatch({ type: SET_PERIOD, payload: value });
        }
    };
    return (
        <React.Fragment>
              <Tooltip title="Quarter/Month filter are only for summary tiles (Overview boxes) , Its not applicable for 'Vendor Partner Volume' table " placement="bottom">

            <ToggleButtonGroup value={selectedPeriod} onChange={handleChange} exclusive>
                <ToggleButton value='ytd'>YTD</ToggleButton>
                <ToggleButton value='mth'>MTH</ToggleButton>
                <ToggleButton value='qtr'>QTR</ToggleButton>
            </ToggleButtonGroup>
            </Tooltip>

        </React.Fragment>
    );
}
