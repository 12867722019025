import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 2.5 }}>{children}</Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const emptyUserDetails = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    userType: 1,
    dbaId: 0
};

export default function AddUserModal({ vendorList, memberList, userTypeList, onCreate }) {

    const [user, setUser] = useState({ ...emptyUserDetails });
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const handleDialogClose = () => {
        setOpen(false);
        setUser({ ...emptyUserDetails });
        setActiveTab(0);
    }

    const handleSaveAndDialogClose = (event) => {
        event.preventDefault();
        if (user.userType !== 1 && !user.dbaId) {
            enqueueSnackbar('Please select vendor or member id');
            return;
        }
        axios.post('/api/user', { ...user }).then(resp => {
            onCreate(resp.data.userId);
            handleDialogClose();
            enqueueSnackbar('User added successfully');
        }).catch(() => {
            handleDialogClose();
            enqueueSnackbar('Failed to create user');
        });
    }

    const onChange = (event) => {
        console.log('Changed', event.currentTarget.name, event.currentTarget.value);
        setUser({ ...user, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleTabChange = (_event, newTabIndex) => {
        setActiveTab(newTabIndex);
        setUser({ ...user, userType: userTypeList[newTabIndex].id, dbaId: 0 });
    };

    return (
        <React.Fragment>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => setOpen(true)}>Add User</Button>
            <Dialog open={open} onClose={handleDialogClose} component="form" onSubmit={handleSaveAndDialogClose}>
                <DialogTitle>Create New User</DialogTitle>
                <DialogContent sx={{ minWidth: 500 }}>
                    <TextField fullWidth value={user.firstName} margin="normal" onChange={onChange} name="firstName" label="First Name" variant="outlined" size="small" required />
                    <TextField fullWidth value={user.lastName} margin="normal" onChange={onChange} name="lastName" label="Last Name" variant="outlined" size="small" />
                    <TextField fullWidth value={user.email} margin="normal" onChange={onChange} name="email" label="Email" variant="outlined" type="email" size="small" required />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-user-role">Select Role</InputLabel>
                        <Select labelId="select-user-role" id="user-role-select" value={user.role} label="Select Role" onChange={event => setUser({ ...user, role: event.target.value })} required>
                            <MenuItem value={''}>Select Role</MenuItem>
                            <MenuItem value={'Dashboard-SuperUsers'}>Super Users</MenuItem>
                            <MenuItem value={'Dashboard-DashboardOnly'}>Dashboard Only</MenuItem>
                            <MenuItem value={'Dashboard-Administration'}>Administration</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
                            <Tab label="CORPORATE" {...a11yProps(0)} />
                            <Tab label="VENDOR" {...a11yProps(1)} />
                            <Tab label="MEMBER" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTab} index={0}>
                        <Typography>Corporate use will have access to all vendors and members dashboard.</Typography>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <FormControl fullWidth>
                            <InputLabel id="select-vendor">Select Vendor</InputLabel>
                            <Select labelId="select-vendor" id="vendor-select" value={user.dbaId} label="Select Vendor" onChange={event => setUser({ ...user, dbaId: event.target.value })}>
                                <MenuItem value={0}>Select Vendor</MenuItem>
                                {vendorList && vendorList.length > 0 ? vendorList.map(vendor => <MenuItem value={vendor.dbaId} key={`sl-vendor-${vendor.dbaId}`}>{vendor.dbaName}</MenuItem>) : null}
                            </Select>
                        </FormControl>
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-member">Select Member</InputLabel>
                            <Select labelId="select-member" id="member-select" value={user.dbaId} label="Select Member" onChange={event => setUser({ ...user, dbaId: event.target.value })}>
                                <MenuItem value={0}>Select Member</MenuItem>
                                {memberList && memberList.length > 0 ? memberList.map(member => <MenuItem value={member.dbaId} key={`sl-member-${member.dbaId}`}>{member.dbaName}</MenuItem>) : null}
                            </Select>
                        </FormControl>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" color="success" type='submit'>Create User</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}