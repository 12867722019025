import DashboardIcon from '@mui/icons-material/Dashboard';
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SET_VENDOR_IDS } from '../../middleware/filter';
import AppLayout from '../common/app-layout';

const vendorLinks = [
    { url: '/vendor/:groupIds', label: 'Rebate Management ', icon: <DashboardIcon /> },
    { url: '/vendor/:groupIds/opportunity', label: 'Conversion Opportunity', icon: <DashboardIcon /> },
    { url: '/vendor/:groupIds/invoice', label: 'Invoices', icon: <DashboardIcon /> },
    { url: '/vendor/:groupIds/conversion', label: 'Terminated Supplier Opportunity', icon: <DashboardIcon /> },
    { url: '/vendor/:groupIds/market-share', label: 'Market Share', icon: <DashboardIcon /> }
];

export default function Vendor() {

    const { groupIds } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeLink, setActiveLink] = useState(0);
    const [branchList, setBranchList] = useState([]);
    const [openError, setOpenError] = useState(false);
    const [branchDialog, setBranchDialog] = useState(false);

    const dispatch = useDispatch();
    const { vendorIds } = useSelector(state => state.filter);
    const defaultSelectedIds = groupIds.split(',').map(id => parseInt(id));
    const defaultSelectedIds1= branchList.map(row => row.dbaId);


    useEffect(() => {
        axios.post('/api/groups/dba/vendor', { 'ids': defaultSelectedIds }).then(response => {
            setBranchList(response.data);
          //  dispatch({ type: SET_VENDOR_IDS, payload: response.data.filter(row => defaultSelectedIds.includes(row.dbaId)) });
          dispatch({ type: SET_VENDOR_IDS, payload: response.data });

        });
    }, []);

    useEffect(() => {
        setActiveLink(vendorLinks.findIndex(link => matchPath(link.url, location.pathname)));
    }, [location]);

    const setSelectedBranchs = (ids) => {
        const selectedIds = branchList.filter(row => ids.includes(row.dbaId));
        navigate(generatePath(vendorLinks[activeLink].url, { groupIds: ids }), { replace: true });
        dispatch({ type: SET_VENDOR_IDS, payload: selectedIds });
    }

    return (
        <React.Fragment>
            <AppLayout setBranchDialog={setBranchDialog} sideNav={
                <List component="nav" aria-label="main mailbox folders">
                    {vendorLinks.map((link, linkIndex) => (
                        <ListItemButton component={Link} key={'vendor-link-' + linkIndex} selected={activeLink === linkIndex} to={generatePath(link.url, { groupIds: vendorIds.map(row => row.dbaId) })}>
                            <ListItemIcon>{link.icon}</ListItemIcon>
                            <ListItemText primary={link.label} />
                        </ListItemButton>
                    ))}
                </List>
            } />
            <BranchSelectionDialog setOpenError={setOpenError} branchList={branchList} open={branchDialog} setOpen={setBranchDialog} defaultChecked={defaultSelectedIds1} setSelection={setSelectedBranchs} />
            <Snackbar open={openError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setOpenError(false)}>
                <Alert onClose={() => setOpenError(false)} severity="warning" sx={{ width: '100%' }}>Select a Member Branch</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

function BranchSelectionDialog({ open, setOpen, branchList, defaultChecked, setSelection, setOpenError }) {

    const [checked, setChecked] = useState(defaultChecked);

    const handleClose = () => {
        if (checked.length > 0) {
            setSelection(checked);
            setOpen(false);
        } else {
            setOpenError(true);
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select Vendor Branches</DialogTitle>
            <List sx={{ width: '100%', maxWidth: 700, minWidth: 500, overflow: 'auto', maxHeight: 300, bgcolor: 'background.paper' }}>
                {branchList.map((branch) => {
                    const labelId = `checkbox-list-label-${branch.dbaId}`;
                    return (
                        <ListItem key={labelId} disablePadding>
                            <ListItemButton role={undefined} onClick={handleToggle(branch.dbaId)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(branch.dbaId) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={branch.dbaName} secondary={branch.dbaId === branch.parentId ? 'PARENT' : 'DBA of ' + branch.parentName} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>
                <Button variant='outlined' onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant='contained' onClick={handleClose}>Select</Button>
            </DialogActions>
        </Dialog>
    );
}
