import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
  Stack
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import axios from "axios";
import { useSelector } from "react-redux";
import { modifyFiltersToOldFormat } from "../../../middleware/helper";
import { FormatCurrency, FormatPercent } from "../../common/formatter";
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-55px',right:'-7px',position:'absolute'
    ,padding:'7px 12px',fontSize:'14px',color:'#fff',marginRight:'10px'}}
      />
    </GridToolbarContainer>
  );
}


const AdminCategoryTable = () => {
  const { period, year, month, quarter } = useSelector((state) => state.filter);
  const [categoryDistribution, setCategoryDistributionData] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [supplierDropdown, setSupplierDropdown] = useState([]);
  const [sourceDropdown, setSourceDropdown] = useState([]);
  const [bhCategoryName, setCategoryName] = useState([]);
  const [supplierName, setSupplierName] = useState([]);
  const [source, setSourceName] = useState(['Member Transaction Data']);
  const [pageSize, setPageSize] = React.useState(20);


  const columns = [
    { field: 'bhCategory', headerName: 'CATEGORY', width: 250 },
    {
      field: 'vendorName',
      headerName: 'SUPPLIER NAME',
      width: 250,
      editable: true,
    },
    {
      field: 'supplierFlag',
      headerName: 'VP OR UNAPPROVED SUPPLIER',
      width: 230,
      editable: true,
    },
    {
      field: 'volumeCY',
      headerName: 'VOLUME 2022',
      type: 'number',
      width: 110,
      editable: true,
      renderCell: (params) => <FormatCurrency value={params.value} />,

    },
    {
      field: 'volumePY',
      headerName: 'VOLUME 2021',
      type: 'number',
      width: 110,
      editable: true,
      renderCell: (params) => <FormatCurrency value={params.value} />,

    },
    {
      field: 'differencePY',
      headerName: 'DIFFERENCE PY ',
      type: 'number',
      width: 160,
      editable: true,
      renderCell: (params) => <FormatCurrency value={params.value} />,

    },
    {
      field: 'rebatePercent',
      headerName: 'REBATE (%)',
      type: 'number',
      width: 160,
      editable: true,
      renderCell: (params) => <FormatPercent value={params.value} />,

    },
    {
      field: 'marketShare',
      headerName: 'MARKET SHARE',
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'overallRankCY',
      headerName: 'OVERALL RANK 2022',
      type: 'number',
      width: 170,
      editable: true,

    },
    {
      field: 'overallRankPY',
      headerName: 'OVERALL RANK 2021',
      type: 'number',
      width: 170,
      editable: true,

    },
    {
      field: 'overallRankInOutCY',
      headerName: 'RANK WITHIN BH CAT 2022',
      type: 'number',
      width: 200,
      editable: true,

    },
    {
      field: 'overallRankInOutPY',
      headerName: 'RANK WITHIN BH CAT 2021',
      type: 'number',
      width: 200,
      editable: true,

    },
    {
      field: 'source',
      headerName: 'SOURCE',
      width: 220,
      editable: true,
      // renderCell: (params) => {params.value == "Member Transaction Data" ? 'Member Trans Data' :
      // params.value == "VP Quarterly Data"?'VP Verified Data':params.value 
      // == 'Vendor Transaction Data'?'VP Trans Data':params.value},
      renderCell: (params) => (
        <React.Fragment>
          {params.value == "Member Transaction Data" ? 'Member Trans Data' :
       params.value == "VP Quarterly Data"?'VP Verified Data':params.value 
       == 'Vendor Transaction Data'?'VP Trans Data':params.value}
        </React.Fragment>
      )

    },
  ];

  const rows = [];

  const clearAll = () =>
  {
    setCategoryName([])
    setSupplierName([])
    setSourceName([])
  }

  useEffect(() => {
    let params = modifyFiltersToOldFormat({ year, month, period, quarter, dropDown: [] });
    if (bhCategoryName != "" || source != "" || supplierName != "") {
      params.dropDown = [{ bhCategoryName: [bhCategoryName] }, { source: [source] }, { supplierName: [supplierName] }];
    }
    params.page = 1;
    axios.post("/api/corporate/category-details/getCorporateCategoryDistributionData", params)
      .then(response => setCategoryDistributionData(response.data.data.map((row, idx) => ({ ...row, id: idx }))
        , console.log('CATEGORY details',response.data)))
  }, [year, month, period, quarter, bhCategoryName, supplierName, source]);

  useEffect(() => {
    let paramsNow = { year };

    axios.post("/api/corporate/category-details/getCorporateCategoryDetailsBhCatList", paramsNow)
      .then(response => setCategoryDropdown(response.data.map((row, idx) => ({ ...row, id: idx })))
,)
    axios.post("/api/corporate/category-details/getCorporateCategoryDetailsSupplierNames", paramsNow)
      .then(response => setSupplierDropdown(response.data.map((row, idx) => ({ ...row, id: idx })))
,)
    axios.post("/api/corporate/category-details/getCorporateCategoryDetailsSourceNames", paramsNow)
      .then(response => setSourceDropdown(response.data.map((row, idx) => ({ ...row, id: idx })))
,)
  }, [year]);

  const generateExportCategoryDetails=()=>{
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("CategoryDetails", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            printArea: `A1:F${categoryDistribution.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
          
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK Category Details";
    const title1 = `Based on collective Purchasing Survey data: ${year}`;
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["CATEGORY","SUPPLIER NAME","VP OR UNAPPROVED SUPPLIER","VOLUME 2022",
    "VOLUME 2021","DIFFERENCE PY","REBATE (%)","MARKET SHARE","OVERALL RANK 2022","OVERALL RANK 2021",
  "RANK WITHIN BH CAT 2022","RANK WITHIN BH CAT 2021","SOURCE"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");
    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };


    });
     
    
    categoryDistribution.forEach(function (obj) {
        let addedRow = worksheet.addRow([obj.bhCategory ,obj.vendorName,obj.supplierFlag,
             '$'+Math.round(obj.volumeCY),'$'+Math.round(obj.volumePY),'$'+Math.round(obj.differencePY),(obj.rebatePercent == 'null' ?obj.rebatePercent:'0.0%'),
            obj.marketShare,obj.overallRankCY,obj.overallRankPY,obj.overallRankInOutCY,obj.overallRankInOutPY,
          obj.source]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            if (number === 7) {
              cell.numFmt = '0.0%';
             
          }
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
  
   
  
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Category Details';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "CategoryDetails.xlsx");
    });
  }


  return (<React.Fragment>
    {/* <Paper elivation={1} sx={{p:2,mb:3 ,mt:3}}> */}
    {/* <Grid container spacing={12} sx={{ p: 2 }}>
        <Grid item xs={12} sm={12}> */}
    <Paper style={{ height: 'auto', width: '100%', padding: '20px' }} >
      <Stack direction='row' spacing={2}>

        <Typography variant="h6" style={{ marginRight: '30px', display: 'inline-block' }}>Category Details</Typography>
        <Stack direction='row' spacing={2} sx={{ float: 'right', marginLeft: '20px' }}>
          <FormControl>
            <InputLabel>Select Category</InputLabel>
            <Select
              labelId="category-name-select-small"
              id="category-name-select-small"
              value={bhCategoryName}
              label="Select category"
              sx={{ minWidth: 250 }}
              onChange={(event) => setCategoryName(event.target.value)}
              size='small'
            >
              <MenuItem value={0} selected>All</MenuItem>
              {categoryDropdown.map((category, index) => <MenuItem key={index} value={category.bhCategoryName}>{category.bhCategoryName}</MenuItem>)}
            </Select>
          </FormControl>


          <FormControl>
            <InputLabel>Select Supplier</InputLabel>
            <Select
              labelId="supplier-name-select-small"
              id="supplier-name-select-small"
              value={supplierName}
              label="Select supplier"
              sx={{ minWidth: 250 }}
              onChange={(event) => setSupplierName(event.target.value)}
              size='small'
            >
              <MenuItem value={0} selected>All</MenuItem>
              {supplierDropdown.map((supplier, index) => <MenuItem key={index} value={supplier.supplierName}>{supplier.supplierName}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Select Source</InputLabel>
            <Select
              labelId="source-name-select-small"
              id="source-name-select-small"
              value={source}
              label="Select source"
              sx={{ minWidth: 250 }}
              onChange={(event) => setSourceName(event.target.value)}
              size='small'
            >
              {/* <MenuItem value={0} selected>All</MenuItem> */}
              {sourceDropdown.map((source, index) => <MenuItem key={index} value={source.source}>{source.source == 
              "Member Transaction Data" ? 'Member Trans Data' :
              source.source == "VP Quarterly Data"?'VP Verified Data':source.source == 
              'Vendor Transaction Data'?'VP Trans Data':source.source}</MenuItem>)}
            </Select>
          </FormControl>
          <Stack>

            <Button variant="contained" sx={{ float: 'right', height: '20' }} onClick={() => clearAll()}>Clear All</Button>
          </Stack>
        </Stack>
        <Button variant="contained" sx={{padding:'0px 24px',float:'right'}} onClick={() => generateExportCategoryDetails()}><FileDownloadOutlinedIcon/>Export</Button>

      </Stack>
      {/* <input  onChange={requestMemberDataSearch} type="text" placeholder="Search" style={{float:'right' ,marginRight:'50px',width:'300px',height:'35px',fontSize:'16px'}}></input>
            <input  onChange={requestVendorDataSearch} type="text" placeholder="Search" style={{float:'right' ,marginRight:'50px',width:'300px',height:'35px',fontSize:'16px'}}></input> */}
      <Box sx={{ mt: 2, height: '600px' }}>
        <DataGrid
          rows={categoryDistribution}
          columns={columns}
          getRowId={(row) => row.id}
          density='compact'
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}    
          rowsPerPageOptions={[20,50,100]} 
          disableSelectionOnClick
          // components={{
          //   Toolbar: CustomToolbar,
          //  }}
          //  localeText={{
          //   toolbarExport: "export"
          // }}
        />
      </Box>
    </Paper>
    {/* </Grid>
        </Grid>
        </Paper> */}



  </React.Fragment>
  )
}

export default AdminCategoryTable;