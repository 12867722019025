import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LinearProgress } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormatCurrency, FormatValue, RebateAttainment, RebateCritera, RebateTier } from '../formatter';
import { headCells } from './vpv-table-header';
import VpvTableRowDetail from './vpv-table-row-details';
import './vpv.css' 


export default function VpvTableRow({ row }) {

    const { period, year, month, quarter } = useSelector(state => state.filter);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [programData, setProgramData] = useState({});

    const handleOpen = () => {
        setOpen(true);
        setLoading(true);
        const params = { period, year, month, quarter, memberId: row.memberId, vendorId: row.vendorId, source: row.source, programName: row.programName };
        axios.post('/api/vpv/program-detail', { ...params }).then(response => {
            setLoading(false);
            const data = response.data;
            let grouped = {};
            data.forEach(row => {
                if (grouped[row.tier]) {
                    grouped[row.tier].push(row);
                } else {
                    grouped[row.tier] = [row];
                }
            });
            setProgramData(grouped);
        });
    };

    useEffect(() => {
        setOpen(false);
        setProgramData({});
    }, [row]);

    return (
        <React.Fragment>
           
            <TableRow sx={{ '& > *': { borderBottom: 'unset', bgcolor: open ? '#BBDEFB' : '#fff' }}}>
                <TableCell style={{width:'4%'}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => open ? setOpen(false) : handleOpen()}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{width:'12%'}} >{row.vendorName}</TableCell>
                <TableCell  style={{width:'12%'}}>{row.memberName}</TableCell>
                <TableCell style={{width:'12%'}}>{row.programName}</TableCell>
                <TableCell style={{width:'12%'}}>{row.payoutType}</TableCell>
                <TableCell align="right"  style={{width:'12%',textAlign:'right'}}><RebateTier tier={row.tier} /></TableCell>
                <TableCell align="right" style={{width:'12%',textAlign:'right'}}><FormatValue value={row.payoutValue} format={row.payoutSetting} /></TableCell>
                <TableCell align="right" style={{width:'12%',textAlign:'right'}}><FormatCurrency value={row.rebate} /></TableCell>
                {/* <TableCell><RebateCritera criteriaName={row.criteriaName} criteriaSetting={row.criteriaSetting} min={row.min} criteriaCount={row.criteriaCount} /></TableCell>
                <TableCell align="right"><FormatCurrency value={row.target} /></TableCell> */}
                <TableCell  align="right" style={{width:'12%',textAlign:'right'}}><RebateAttainment attainment={row.attainment} /></TableCell>
                <TableCell align="right" style={{width:'12%',textAlign:'right'}} >{row.lastUpdated}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={headCells.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {loading ? <LinearProgress sx={{ height: 10 }} /> : <VpvTableRowDetail row={row} programData={programData} />}
                    </Collapse>
                </TableCell>
            </TableRow>
           
        </React.Fragment>
    );
}