import { Avatar, Chip, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER, SET_MEMBER_IDS, SET_REBATE_TYPES, SET_SOURCES, SET_VENDOR_IDS } from '../../../middleware/filter';

const notVendorRoute = pathname => !String(pathname).startsWith('/vendor');
const notMemberRoute = pathname => !String(pathname).startsWith('/member');

export default function VpvTableAppliedFilterManager({ pathname }) {

    const dispatch = useDispatch();
    const filter = useSelector(state => state.filter);
    const { vendorIds, memberIds, rebateTypes, sources } = useSelector(state => state.filter);

    const handleVendorDelete = vendorDbaId => {
        const currentIndex = vendorIds.indexOf(vendorDbaId);
        let payload = [...vendorIds];
        if (currentIndex >= -1) {
            payload.splice(currentIndex, 1);
            dispatch({ type: SET_VENDOR_IDS, payload });
        }
    };

    const handleMemberDelete = memberDbaId => {
        const currentIndex = memberIds.indexOf(memberDbaId);
        let payload = [...memberIds];
        if (currentIndex >= -1) {
            payload.splice(currentIndex, 1);
            dispatch({ type: SET_MEMBER_IDS, payload });
        }
    };

    const handleRebateTypeDelete = rebateType => {
        const currentIndex = rebateTypes.indexOf(rebateType);
        let payload = [...rebateTypes];
        if (currentIndex >= -1) {
            payload.splice(currentIndex, 1);
            dispatch({ type: SET_REBATE_TYPES, payload });
        }
    };

    const handleSourceDelete = source => {
        const currentIndex = sources.indexOf(source);
        let payload = [...sources];
        if (currentIndex >= -1) {
            payload.splice(currentIndex, 1);
            dispatch({ type: SET_SOURCES, payload });
        }
    };

    const clearAll = () => {
        let payload = { ...filter, rebateTypes: [], sources: [] };
        if (notVendorRoute(pathname)) {
            payload = { ...payload, vendorIds: [] };
        }
        if (notMemberRoute(pathname)) {
            payload = { ...payload, memberIds: [] };
        }
        dispatch({ type: SET_FILTER, payload });
    };

    if ((notVendorRoute(pathname) && filter.vendorIds && filter.vendorIds.length > 0) || (notMemberRoute(pathname) && filter.memberIds && filter.memberIds.length > 0)
        || (filter.rebateTypes && filter.rebateTypes.length > 0) || (filter.sources && filter.sources.length > 0)) {
        return (
            <React.Fragment>
                <Paper sx={{ mb: 2 }} elevation={0}>
                    <Stack direction={'row'} spacing={2}>
                        {notVendorRoute(pathname) ? filter.vendorIds.map(vendor => (
                            <Chip key={'vendor-dba-' + vendor.dbaId} avatar={<Avatar>VP</Avatar>} label={vendor.dbaName} variant="outlined" onDelete={() => handleVendorDelete(vendor.dbaId)} />
                        )) : null}
                        {notMemberRoute(pathname) ? filter.memberIds.map(member => (
                            <Chip key={'member-dba-' + member.dbaId} avatar={<Avatar>MP</Avatar>} label={member.dbaName} variant="outlined" onDelete={() => handleMemberDelete(member.dbaId)} />
                        )) : null}
                        {filter.rebateTypes.map(rebate => (
                            <Chip key={'rebate-type-' + rebate} avatar={<Avatar>RT</Avatar>} label={rebate ==='Quarterly Flat'? 'Guaranteed':rebate} variant="outlined" onDelete={() => handleRebateTypeDelete(rebate)} />
                        ))}
                        {filter.sources.map(source => (
                            <Chip key={'data-source-' + source} avatar={<Avatar>DS</Avatar>} label={source == "Member" ? 'Member Trans Data' :
                            source == "VP Quarterly Data"?'VP Verified Data':source == 'VP Transaction Data'?'VP Trans Data':source} variant="outlined" onDelete={() => handleSourceDelete(source)} />
                        ))}
                        <Chip label="Clear All" color='primary' onClick={() => clearAll()} />
                    </Stack>
                </Paper>
            </React.Fragment>
        );
    } else {
        return null;
    }
}