import { Avatar, Box, Chip, Toolbar } from '@mui/material';
import axios from "axios";
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_FILTER } from '../../../middleware/filter';
// import FilterYear from '../../common/filter/filter-year';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import MarketShareCategories from './marketShareCategories';


function MarketShare() {

    const { filter } = useSelector(state => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const [dataLoaded, setDataLoaded] = useState(false);
    const marketShareYear=localStorage.getItem("year")
    const [selectedYear, setSelectedYear]=useState(marketShareYear || 2021);
   
    useEffect(() => {
        axios.get('/track/' + 22);
        // dispatch({ type: SET_FILTER, payload: { ...filter, year: 2021, month: 12, quarter: 4 } });
        setDataLoaded(true); 
    }, []);

    
const handleYearChange = (year) => {
    localStorage.setItem("year",year)
    setSelectedYear(year);
  };


    const isCorporateUser = String(location.pathname).startsWith('/admin');
    console.log('isCorporateUser',isCorporateUser)
    const ready = isCorporateUser || filter.vendorIds.length > 0;
    return (
        <PageContent ready={ready}>
            <React.Fragment>
                <SecondaryAppBar>
                    <Toolbar>
                    {ready && dataLoaded && filter.vendorIds.map(vendor => <Chip key={'dba-' + vendor.dbaId} avatar={<Avatar>V</Avatar>} label={vendor.dbaName} sx={{ mr: 2 }} />)}
                        <Box sx={{ flexGrow: 1 }}></Box>
                         {/* <FilterYear/> */}
                    </Toolbar>
                </SecondaryAppBar>
                {dataLoaded && <MarketShareCategories yearSelected={selectedYear} onYearChange={handleYearChange} vendorIds={filter.vendorIds} isCorporateUser={isCorporateUser} />}
            </React.Fragment>
         </PageContent>
    );
}

export default MarketShare;