import { Avatar, Box, Chip, Container, Paper, Toolbar,Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageFilters from '../../common/filter/page-filters';
import PurchaseOrderTable from './purchasedemo';
import axios from "axios";
import { useSnackbar } from 'notistack';
import SecondaryAppBar from '../../common/secondary-app-bar';


function PurchaseOrder() {
    
    const { memberIds } = useSelector(state => state.filter);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/'+ 12).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);


    return (
            <Container maxWidth="false" sx={{ bgcolor: 'rgb(243, 246, 249)', pt: 2, pb: 5 }}>
            {memberIds.length > 0 ? (
                <React.Fragment>
                                        <SecondaryAppBar>

                    <Toolbar>
                    <Grid container spacing={1}>
                    <Grid item xs={9}>
                    {memberIds.map(member => <Chip key={'dba-' + member.dbaId} avatar={<Avatar>M</Avatar>} label={member.dbaName} sx={{ mr: 2 ,height:'40px'}} />)}
                        </Grid>  
                        <Grid item xs={1} sx={{justifyContent:"end"}}>
                        </Grid>
                        </Grid>
                    </Toolbar>
                    </SecondaryAppBar>

                <Paper elivation={1} sx={{ p: 2, mt: 4 }}>
                    <PurchaseOrderTable />
                    </Paper>
                </React.Fragment>
            ) : (
                <Paper elevation={1}>Select a member to view dashboard</Paper>
            )}
        </Container>
           
    );
}

export default PurchaseOrder;