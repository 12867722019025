export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const SSO_SIGNIN = 'SSO_SIGNIN';

export const authentication = (state = { user: null, token: null }, action = { type: null, payload: null }) => {
    switch (action.type) {
        case SIGNIN:
            return { ...action.payload };
        case SIGNOUT:
            return {};
        case SSO_SIGNIN:
            return { ...action.payload };
        default:
            return state;
    }
};
