export const TOKEN_ITEM_STORAGE_KEY = 'jwt';

export const ifBearerTokenExists = () => {
    const token = localStorage.getItem(TOKEN_ITEM_STORAGE_KEY);
    return typeof token === 'string' && token !== '';
};

export const setBearerToken = (token) => {
    localStorage.setItem(TOKEN_ITEM_STORAGE_KEY, token);
};

export const getBearerToken = () => {
    return `Bearer ${localStorage.getItem(TOKEN_ITEM_STORAGE_KEY)}`;
};
