import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export default function HttpError({ code, message }) {
    return (
        <Card sx={{ mt: 10, width: 1 / 2, ml: 'auto', mr: 'auto', textAlign: 'center' }} elevation={10}>
            <CardContent>
                <Typography variant='h3'>{code}!</Typography>
                <Typography>{message}</Typography>
            </CardContent>
        </Card>
    );
}
