import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_REBATE_TYPES } from '../../../middleware/filter';
import { RebateTabsMap, tabs } from './vpv-table-helper';

export default function VpvTableTabs({ enabledTabs, rebateList }) {

    const dispatch = useDispatch();
    const [aciveTab, setActiveTab] = useState(0);

    const handleTabChange = (_event, newSelectedTabIndex) => {
        const tabName = tabs[newSelectedTabIndex];
        setActiveTab(newSelectedTabIndex);
        let selectedProgramTypes = [];
        Object.keys(RebateTabsMap).forEach(row => {
            if (RebateTabsMap[row] === tabName && rebateList.includes(row)) {
                selectedProgramTypes.push(row);
            }
        });
        dispatch({ type: SET_REBATE_TYPES, payload: selectedProgramTypes });
    };

    return (
        <Tabs value={aciveTab} onChange={handleTabChange} aria-label="rebate tabs example">
            {tabs.map(tabName => <Tab key={tabName} label={tabName} disabled={!enabledTabs.includes(tabName)} />)}

        </Tabs>
    );
};