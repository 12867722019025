import { Box, Toolbar } from '@mui/material';
import React, { useState,useEffect } from 'react';
import PageFiltersVpv from '../../common/filter/page-filters-vpv';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import VpvTable from '../../common/vpv-table-corporate';
import axios from "axios";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


function AdminRebateData() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/'+ 2).then((res) => {
            console.log('console create user post api data is ', res.data) 
            if(res.data){
             enqueueSnackbar('Corporate Overview tab');
            }
          })
          .catch(error => {
            if (error.response) {
              enqueueSnackbar("Error Message",error.response.data.error);
              // alert("Error Message",error.response.data.error);
            }
          });
    }, []);

    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFiltersVpv />
                </Toolbar>
            </SecondaryAppBar>
            <VpvTable />
        </PageContent>
    );
}

export default AdminRebateData;