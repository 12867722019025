import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Divider, Stack, TableFooter, TablePagination, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
// import { makeStyles } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, IconButton, Paper, TableSortLabel, Typography, Tooltip  } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { getComparator, stableSort } from '../../../middleware/helper';
import * as XLSX from 'xlsx';
import MaterialSearch from '../../common/material-search';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Percent} from "@mui/icons-material";
import {FormatPercent} from "../../common/formatter";
import WarningIcon from '@mui/icons-material/Warning';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CategoryDetailsDialog({ open, handleClose }) {
    return (
        ''
    );
}

function CategoryFeedbackDialog({ open, handleClose }) {
    return (
        ''
    );
}


// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });
// function createData(name, calories, fat, carbs, protein, price) {
//   return {
//     name,
//     calories,
//     fat,
//     carbs,
//     protein,
//     price,
//     history: [
//       { date: '2020-01-05', customerId: '11091700', amount: 3 },
//       { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
//     ],
//   };
// }
function Row(props) {
    const { row } = props;
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [tableDataSummaryView, setTableDataSummaryView] = useState([]);
    

    const [open, setOpen] = React.useState(false);

    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);


    const handleOpen = (row) => {
        setOpen(true);
        setLoading(true);
        let params = { period, year, month, quarter, memberId: row.memberId, vendorId: row.vendorId };
        let paramsvalue = params;
        //        paramsvalue.append('bhCategoryId', row.categoryId)

        axios.post('/api/vendor/opportunity/vendor-category-overview', { year, month, period, quarter,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                setTableDataSummaryView(response.data);
                
            })
            setLoading(true)
    };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };
    // const handleChangePageSummaryView = (event, newPage) => {
    //     setPageCategory(newPage);
    // };

    // const handleChangeRowsPerPageSummaryView = (event) => {
    //     setRowsPerPageSummaryView(parseInt(event.target.value, 10));
    //     setPageCategory(0);
    // };
    const dollarUSLocale = (min, max) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: min, maximumFractionDigits: max ? max : min });


    const FormatCurrency = ({ value }) => {
        return dollarUSLocale(0).format(value);
    }


    return (
        <React.Fragment>
            <TableRow row={row} sx={{ '& > *': { borderBottom: 'unset', bgcolor: open ? '#BBDEFB' : '#fff' } }}>
                <TableCell component="th" scope="row">{row.bhCategory}</TableCell>
                <TableCell component="th" scope="row">{row.vendorName}</TableCell>
                <TableCell component="th" scope="row">
                    <FormatCurrency value={row.totalAmount} />
                    <IconButton aria-label="expand row" size="small" sx={{ ml: 1 }} onClick={() => open ? setOpen(false) : handleOpen(row)}>
                        {open ? <KeyboardArrowUpIcon sx={{ fontSize: 'inherit' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: 'inherit' }} />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">  <FormatPercent value ={row.market_share}/> </TableCell>
                <TableCell component="th" scope="row">{row.current_rank}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ p: 0, bgcolor: '#E3F2FD' }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                            <Stack direction="row" sx={{ bgcolor: '#fff', py: 1, px: 5, borderRadius: 2 }} spacing={5} divider={<Divider orientation="vertical" flexItem />}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption">Volume PY</Typography>
                                    <Typography><FormatCurrency value={row.totalAmountPy} /></Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption">Volume CY </Typography>
                                    <Typography><FormatCurrency value={row.totalAmount} /></Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="caption">Volume Diff</Typography>
                                    <Typography>{<FormatCurrency value={row.totalAmount - row.totalAmountPy} />}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export default function SummaryMemberView({ stateCodes, setStateCodes }) {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [order1, setOrder1] = useState('asc');
    const [orderBy1, setOrderBy1] = useState('');
    
    const [tableData1, setTableData1] = useState([]);
    const [allData, setAllData] = useState([]);
    const [allDataMember, setAllDataMember] = useState([]);
    const [allDataMemberExclude,setAllDataMemberExclude]=useState([]);


    const [tableData2, setTableData2] = useState([]);
    const [pageMemberView, setPageMemberView] = useState(0);
    const [rowsPerPageMemberView, setRowsPerPageMemberView] = useState(10);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const textInput = React.useRef(null);
    const textInput1 = React.useRef(null);

    const [tableDataBackup, setTableBackup] = useState("");
    const [tableDataBackup1, setTableBackup1] = useState("");
    const [alignment, setAlignment] = React.useState('exclude');
    const [alignmentMember, setAlignmentMember] = React.useState('exclude');
    const [includeOther,setincludeOther]=useState(true)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const { period, year, month, quarter, memberIds, vendorIds, bhCategoryId } = useSelector(state => state.filter);

    useEffect(() => {
        let params = { period, year, month, quarter, vendorId: vendorIds.map(row => row.dbaId) };
        if (stateCodes == "" || stateCodes == [] || stateCodes.length == 0 || stateCodes == null) {
            console.log('hiiii')
            params = params;
        }
        else {
            console.log('helooowwwwwwwww')
            params.region = stateCodes;
        }
        setLoading(true);
        vendorCategory()
    }, [period, year, month, quarter, vendorIds]);
   
    const vendorCategory = () => {
        let params = { period, year, month, quarter,vendorId: vendorIds.map(row => row.dbaId) };

        axios.post('/api/vendor/opportunity/vendor-category-overview', { year, month, period, quarter,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                console.log("vendor-category-overview", response.data)
                setTableData1(response.data)
                
                setTableBackup(response.data);
                setAllData(response.data)
            })


        axios.post('/api/vendor/opportunity/vendor-member-category-overview', params)
            .then(response => {
                setLoading(false);
                console.log("vendor-category-overview-member", response.data)
                setTableData2(response.data)
                setTableBackup1(response.data);
                setAllDataMember(response.data)
            })
    }

    const handleChange1 = (event, newAlignment) => {
        setAlignment(newAlignment);
        console.log('handlechnage call', (newAlignment))
        if (newAlignment === 'include') {
            axios.post('/api/vendor/opportunity/vendor-category-overview', { year, month, period, quarter,includeOthers:includeOther,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                setTableData1(response.data)
                setAllData(response.data)
            })
            setLoading(true)
        }
        else if (newAlignment === 'exclude') {
            axios.post('/api/vendor/opportunity/vendor-category-overview', { year, month, period, quarter,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                setTableData1(response.data)
                setAllData(response.data)
            })
            setLoading(true)
        }
    }

    const handleChange1Member = (event, newAlignment) => {
        setAlignmentMember(newAlignment);
        console.log('handlechnage call', (newAlignment))
        if (newAlignment === 'include') {
            axios.post('/api/vendor/opportunity/vendor-member-category-overview', { year, month, period, quarter,includeOthers:includeOther,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                setTableData2(response.data)
                setAllDataMember(response.data)
            })
            setLoading(true)
        }
        else if (newAlignment === 'exclude') {
            axios.post('/api/vendor/opportunity/vendor-member-category-overview', { year, month, period, quarter,vendorId: vendorIds.map(row => row.dbaId) })
            .then(response => {
                setLoading(false);
                setTableData2(response.data)
                setAllDataMember(response.data)
            })
            setLoading(true)
        }
    }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePageMemberView = (event, newPage1) => {
        setPageMemberView(newPage1);
    };

    const handleChangeRowsPerPageMemberView = (event) => {
        setRowsPerPageMemberView(parseInt(event.target.value, 10));
        setPageMemberView(0);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const handleRequestSort1 = (event, property) => {
        const isAsc = orderBy1 === property && order1 === 'asc';
        setOrder1(isAsc ? 'desc' : 'asc');
        setOrderBy1(property);
    };
    const createSortHandler1 = (property) => (event) => handleRequestSort1(event, property);


    const SummaryView = [
        { key: 'bhCategory', label: 'Category' },
        { key: 'vendorName', label: 'Vendor/Unapproved Supplier' },
        { key: 'totalAmount', label: `Volume(${year})`},
        { key: 'market_share', label: 'MarketShare' },
        { key: 'current_rank', label: 'Overall Current Rank' }
    ];
    const MemberView = [
        { key: 'memberName', label: 'Member' },

        { key: 'bhCategory', label: 'Category' },
        { key: 'vendorName', label: 'Vendor/Unapproved Supplier' },
        { key: 'state', label: 'State' },
        { key: 'vendorRank', label: '1st,2nd,3rd or 4th supplier' },
        { key: 'memberBucket', label: 'Rank' },

    ];

    const clearAll = () => {
        setTableData1(allData);
        textInput.current.value = '';
      
    }
    const clearAll1 = () => {
        
        setTableData2(allDataMember);
        textInput1.current.value = '';
    }

    const requestSearch = (searchedVal) => {
        if (searchedVal == '') {
            setTableData1(allData);
        }
        setTableData1(allData);
        const filteredRows = allData.filter((row) => {
            return row.bhCategory.toLowerCase().includes(searchedVal)
                || row.vendorName.toLowerCase().includes(searchedVal);
        });
        console.log(filteredRows, "filteredRows")
        setTableData1(filteredRows);
    }

    const requestSearch1 = (searchedVal1) => {
        if (searchedVal1 == '') {
            setTableData2(allDataMember);
        }
        setTableData2(allDataMember);
        const filteredRows1 = allDataMember.filter((row1) => {
            return row1.memberName.toLowerCase().includes(searchedVal1)
                || row1.bhCategory.toLowerCase().includes(searchedVal1)
                || row1.vendorName.toLowerCase().includes(searchedVal1);
        });
        console.log(filteredRows1, "filteredRows")
        setTableData2(filteredRows1);
    }


    // const handleExport1 = () => {
    //     var wb = XLSX.utils.book_new(),
    //         ws = XLSX.utils.json_to_sheet(tableData1);
    //     XLSX.utils.book_append_sheet(wb, ws, "summaryview")
    //     XLSX.writeFile(wb, "summaryview.xlsx")
    // }

    const generateExportSummaryView=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("SummaryView", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:E${tableData1.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Summary View";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["CATEGORY","VENDOR/UNAPPROVED SUPPLIER",`Volume(${year})`,"Market Share","Rank"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "D1:E3");

        worksheet.mergeCells("A1:C2");
        worksheet.mergeCells("D1:E2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    
        
        tableData1.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.bhCategory, obj.vendorName, 
                 '$'+Math.round(obj.totalAmount), Math.round(obj.market_share,2)+'%',
                obj.current_rank
            ]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 40;
        worksheet.getColumn(3).width = 25;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(3).width = 15;


       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Summary View';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "SummaryView.xlsx");
        });
      
    }
    
    const generateExportMemberView=()=>{
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("MemberView", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${tableData2.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false
            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{  showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });
      
        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Member View";
        const title1 = `Based on collective Purchasing Survey data: ${year}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["MEMBER","CATEGORY","VENDOR/UNAPPROVED SUPPLIER","STATE","1ST,2ND,3RD OR 4TH SUPPLIER","RANK"];
      
        const titleRow = worksheet.addRow([str]);
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");

        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);
      
      
        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);
      
        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    
        
        tableData2.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.memberName, obj.bhCategory,obj.vendorName,obj.state,obj.vendorRank,obj.memberBucket]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });
      
        
      
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 40;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 10;
        worksheet.getColumn(6).width = 10;
       
      
        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Member View';
      
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "MemberView.xlsx");
        });
      
    }

    // const handleExport2 = () => {
    //     var wb = XLSX.utils.book_new(),
    //         ws = XLSX.utils.json_to_sheet(tableData2);
    //     XLSX.utils.book_append_sheet(wb, ws, "memberview")
    //     XLSX.writeFile(wb, "memberview.xlsx")
    // }

    return (
        <>
            <TableContainer component={Paper}>

                <Typography variant='h6' sx={{ m: 2 }}>Category Overview   {loading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}</Typography>

                {/* <Paper square elivation={1} > */}
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <AppBar position="static">
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={8}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="half"
                                    aria-label="half width tabs example"
                                >
                                    <Tab label="Summary View" {...a11yProps(0)} />
                                    <Tab label="Member View" {...a11yProps(1)} />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Stack sx={{ mb: 3, alignItems: 'center', justifyContent: 'right' }} direction="row" spacing={3}>
                                <Box component="form">
                                    <TextField inputRef={textInput} id="standard-basic" variant="standard" placeholder="Search" onChange={(searchVal) => requestSearch(searchVal.target.value)} />
                                    <IconButton type="submit" aria-label="search"><SearchIcon /></IconButton>
                                </Box>
                                <Button variant="contained" onClick={() => clearAll()}>Clear All</Button>
                                <Button variant="contained" onClick={() => generateExportSummaryView()}><FileDownloadOutlinedIcon/>Export</Button>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignment}
                                    exclusive
                                    size="small"
                                    onChange={handleChange1}
                                >
                                    <ToggleButton sx={{
                                        "&.MuiToggleButton-root.Mui-selected": {
                                            color: '#283891',
                                            borderBottom: '1px solid #f50057'
                                        },
                                    }} value="include" >Include Others</ToggleButton>
                                    <ToggleButton sx={{
                                        "&.MuiToggleButton-root.Mui-selected": {
                                            color: '#283891',
                                            borderBottom: '1px solid #f50057'
                                        },
                                    }} value="exclude">Exclude Others</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Paper elivation={1} >
                            <div class="tablebody" style={{height:'463px',overflowY:'auto'}}>
                                <Table aria-label="collapsible table" size="small" stickyHeader square >
                                
                                    <TableHead>
                                        
                                        <TableRow>
                                        
                                            {SummaryView.map(headerCell => (
                                                
                                                <TableCell
                                                    key={headerCell.key}
                                                    align={headerCell.align ? headerCell.align : 'left'}
                                                    sortDirection={orderBy === headerCell.key ? order : false}
                                                >
                                                    <TableSortLabel active={orderBy === headerCell.key} direction={orderBy === headerCell.key ? order : 'asc'} onClick={createSortHandler(headerCell.key)}>
                                                        {headerCell.label}
                                                        {(headerCell.key === 'totalAmount' && year == 2023  )? <Tooltip title="The data for the current year is not inclusive of all members."   placement="top" followCursor><WarningIcon sx={{color:'red'}}></WarningIcon></Tooltip> : ''}
                                                    </TableSortLabel>
                                                 </TableCell>
                                            
                                            ))}
                                           
                                        </TableRow>
                                        
                                    </TableHead>
                                   
                                    <TableBody >
                                         {(rowsPerPage > 0 ? stableSort(tableData1, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : tableData1).map((row, rowIdx) => (
                                                <Row key={row.bhCategory + rowIdx} row={row} />
                                               )) }  
                                    </TableBody>
                                 
                            
                            
                                    </Table>
                                    </div>
                                    
                                    <TableFooter style={{float:'right'}}>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                                colSpan={SummaryView.length}
                                                count={tableData1.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            // ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                    
                            </Paper>
                        </TabPanel>

                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Stack sx={{ mb: 3, alignItems: 'center', justifyContent: 'right' }} direction="row" spacing={3}>
                                <Box>
                                    <TextField inputRef={textInput1} id="standard-basic1" variant="standard" placeholder="Search" onChange={(searchVal1) => requestSearch1(searchVal1.target.value)} />
                                    <IconButton type="submit" aria-label="search"><SearchIcon /></IconButton>
                                </Box>
                                <Button variant="contained" onClick={() => clearAll1()}>Clear All</Button>
                                <Button variant="contained" onClick={() => generateExportMemberView()}><FileDownloadOutlinedIcon/>Export</Button>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignmentMember}
                                    exclusive
                                    size="small"
                                    onChange={handleChange1Member}
                                >
                                    <ToggleButton sx={{
                                        "&.MuiToggleButton-root.Mui-selected": {
                                            color: '#283891',
                                            borderBottom: '1px solid #f50057'
                                        },
                                    }} value="include" >Include Others</ToggleButton>
                                    <ToggleButton sx={{
                                        "&.MuiToggleButton-root.Mui-selected": {
                                            color: '#283891',
                                            borderBottom: '1px solid #f50057'
                                        },
                                    }} value="exclude">Exclude Others</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Paper  elivation={1}>
                            <div class="tablebody1" style={{height:'371px',overflowY:'auto'}}>
                                <Table aria-label="collapsible table" size='small' stickyHeader square >
                                    <TableHead>
                                        <TableRow>
                                            {MemberView.map(MemberViewheaderCell => (
                                                <TableCell key={MemberViewheaderCell.key} align={MemberViewheaderCell.align ? MemberViewheaderCell.align : 'left'} sortDirection={orderBy1 === MemberViewheaderCell.key ? order1 : false}>
                                                    <TableSortLabel active={orderBy1 === MemberViewheaderCell.key} direction={orderBy1 === MemberViewheaderCell.key ? order1 : 'asc'} onClick={createSortHandler1(MemberViewheaderCell.key)}>
                                                        {MemberViewheaderCell.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPageMemberView > 0 ? stableSort(tableData2, getComparator(order1, orderBy1)).
                                            slice(pageMemberView * rowsPerPageMemberView, pageMemberView * rowsPerPageMemberView + rowsPerPageMemberView)
                                            : tableData2).map((MemberView, rowIdx) => (
                                                <TableRow key={MemberView.memberId + rowIdx}>
                                                    <TableCell component="th" scope="row" align="left">
                                                        {(MemberView.memberName)}
                                                    </TableCell>

                                                    <TableCell align="left">{MemberView.bhCategory}</TableCell>
                                                    <TableCell align="left">{MemberView.vendorName}</TableCell>
                                                    <TableCell align="left">{MemberView.state}</TableCell>
                                                    <TableCell align="left">{MemberView.vendorRank}</TableCell>
                                                    <TableCell align="left">{MemberView.memberBucket}</TableCell>

                                                </TableRow>
                                            ))}

                                    </TableBody>
                                    </Table>
                                    </div>
                                    <TableFooter style={{float:'right'}}>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                                colSpan={MemberView.length}
                                                count={tableData2.length}
                                                rowsPerPage={rowsPerPageMemberView}
                                                page={pageMemberView}
                                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                                onPageChange={handleChangePageMemberView}
                                                onRowsPerPageChange={handleChangeRowsPerPageMemberView}
                                            // ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                
                            </Paper>
                        </TabPanel>

                    </SwipeableViews>
                </Box>
                {/* </Paper> */}
            </TableContainer>
        </>
    );
}