import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OverviewCard from '../../common/overview-card';

const defaultData = { updateDate: null, bh_sales: 0, bh_sales_yoy: 0, bh_rebate: 0, bh_rebate_yoy: 0 };

export default function MemberVerifiedSummary() {

    const { period, year, month, quarter, memberIds } = useSelector(state => state.filter);
    const [vendorList, setVendorList] = useState([]);
    const [rebateList, setRebateList] = useState([]);
    const [vendorId, setVendorId] = useState(0);
    const [rebateType, setRebateType] = useState(0);
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        if (year && memberIds && memberIds.length > 0) {
            let params = new URLSearchParams();
            params.append('year', year);
            const parameters = { year, memberId: memberIds.map(row => row.dbaId) };
            
            Promise.all([
                axios.post('/api/member/overview/getVpNames', parameters),
                axios.post('/api/member/overview/getRebateNames', parameters)
            ]).then((values) => {
                setVendorList(values[0].data);
                setRebateList(values[1].data);
                console.log('23',parameters,values)
            });
        }
    }, [year,memberIds]);



    useEffect(() => {
        let postParamsData = { year, month, period, quarter, memberId: memberIds.map(row => row.dbaId) };
        if (vendorId) {
            postParamsData = { ...postParamsData, vendorId };
        }
        if (rebateType) {
            postParamsData = { ...postParamsData, rebateType };
        }
        axios.post('/api/member/overview', postParamsData).then((res) => setData(res.data));
    }, [year, month, period, quarter, memberIds, vendorId, rebateType])

    function handleVendorChange(event) {
        setVendorId(event.target.value);
        console.log('54', event.target.value);
      }

    return (
        <Paper elivation={1} sx={{ p: 2, mb: 2 }}>
            <Toolbar sx={{ mb: 1 }} disableGutters>
                <Typography variant='h6' sx={{ mr: 3 }}>Overall Volume and Rebate (VP Verified Data)</Typography>
                <Stack direction='row' spacing={2}>
                    <FormControl>
                        <InputLabel id='vp-label'>Select Supplier</InputLabel>
                        {/* <Select labelId='vp-label' value={vendorId} label="Select Supplier" sx={{ minWidth: 250 }} onChange={(event) => {setVendorId(event.target.value)}} size='small'> */}
                        <Select labelId='vp-label' value={vendorId} label="Select Supplier" sx={{ minWidth: 250 }} onChange={handleVendorChange} size='small'>
                            <MenuItem value={0} selected>All</MenuItem>
                            {vendorList.map(vp => <MenuItem key={'vendor-' + vp.dbaId} value={vp.dbaId}>{vp.dbaName}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Select Program Type</InputLabel>
                        <Select
                            labelId="rebate-name-select-small"
                            id="rebate-name-select-small"
                            value={rebateType}
                            label="Select Program Type"
                            sx={{ minWidth: 250 }}
                            onChange={(event) => setRebateType(event.target.value)}
                            size='small'
                        >
                            <MenuItem value={0} selected>All</MenuItem>
                            {rebateList.map((rebate) => <MenuItem key={rebate}
                                value={rebate}>{rebate === 'Quarterly Flat' ? 'Guaranteed' : rebate}</MenuItem>)}
                                {/* // value={rebate}>{rebate}</MenuItem>)} */}
                        </Select>
                    </FormControl>
                </Stack>
            </Toolbar>
            <Grid container spacing={4}>
                <Grid item xs={6} md={6}>
                    <OverviewCard title='Total Transactions (VP Verified Data)' value={data.bh_sales} yoy={data.bh_sales_yoy} updateDate={data.updateDate} />
                </Grid>
                <Grid item xs={6} md={6}>
                    <OverviewCard title='Total Rebate (VP Verified Data)' value={data.bh_rebate} yoy={data.bh_rebate_yoy} updateDate={data.updateDate} />
                </Grid>
            </Grid>
        </Paper>
    );
}
