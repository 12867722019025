/* eslint-disable no-undef */
import React, { useState ,useEffect} from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import {Tabs,Tab,Button,TextField} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport,RowId} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";


function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{float:'right'}}>
      <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      sx={{backgroundColor:'#556cd6','&:hover': {
        backgroundColor: "#556cd6",
     },
      top:'-50px',right:'-8px',position:'absolute'
    ,padding:'6px 12px',fontSize:'14px',color:'#fff',marginRight:'10px'}}
      />
    </GridToolbarContainer>
  );
}


export default function AdminFeedbackSummary() {

const [value,setValue]=useState(0);
const [dialogOpenApprove, setDialogOpenApprove] = React.useState(false);
const [dialogOpenReject, setDialogOpenReject] = React.useState(false);
const [dialogOpenApproveFavorable, setDialogOpenApproveFavorable] = React.useState(false);
const [dialogOpenRejectFavorable, setDialogOpenRejectFavorable] = React.useState(false);

const [dialogOpenApproveUnapproved, setDialogOpenApproveUnapproved] = React.useState(false);
const [dialogOpenRejectUnapproved, setDialogOpenRejectUnapproved] = React.useState(false);

const [wrongCategoryData,setWrongCategoryData]=useState([])
const [productsNotSoldData,setProductsNotSoldData]=useState([])
const [unapprovedSupplierData,setUnapprovedSupplierData]=useState([])
const [favorableSupplierData,setFavorableSupplierData]=useState([])

const [productsNotSoldVendorData,setProductsNotSoldVendor]=useState([])

// const [feedBackData,setFeedBackData]=useState('')
const [rowId,setRowId]=useState('');
const { enqueueSnackbar } = useSnackbar();
const [rowIds,setRowIds]=useState([]);
let [selectedRows1,setSelectedRows1]=useState([])
const [wrongCategoryExportData,setWrongCategoryExportData]=useState(()=>{})

 
  const onRowsSelectionHandler = (ids) => {
      const selectedIDs = new Set(ids);
     if(value==0){
                const selectedRows = wrongCategoryData.filter((row) =>
               selectedIDs.has(row.id),
              );
              setSelectedRows1(selectedRows)
              console.log('onRowsSelectionHandler0',value,selectedIDs,selectedRows1)
               const dataRowIds = selectedRows.map((item) => item.rowId)
               setRowIds(dataRowIds)
               console.log('setRowIds',dataRowIds)
              }     
              else if(value==1){
                const selectedRows = productsNotSoldData.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows1(selectedRows)
                console.log('onRowsSelectionHandler1',value)
                 const dataRowIds = selectedRows.map((item) => item.rowId)
                 setRowIds(dataRowIds)
                }         
                else if(value==2){
                  const selectedRows = unapprovedSupplierData.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectedRows1(selectedRows)
                  console.log('onRowsSelectionHandler2',value)
                   const dataRowIds = selectedRows.map((item) => item.rowId)
                   setRowIds(dataRowIds)
                  }
                  else if(value==3){
                    const selectedRows = favorableSupplierData.filter(
                      (row) =>selectedIDs.has(row.id)
                    );
                    setSelectedRows1(selectedRows)
                    console.log('onRowsSelectionHandler3',value)
                     const dataRowIds = selectedRows.map((item) => item.rowId)
                     setRowIds(dataRowIds)
                    }
                    else if(value==4){
                      const selectedRows = productsNotSoldVendorData.filter((row) =>
                        selectedIDs.has(row.id)
                      );
                      setSelectedRows1(selectedRows)
                      console.log('onRowsSelectionHandler4',value)
                       const dataRowIds = selectedRows.map((item) => item.rowId)
                       setRowIds(dataRowIds)
                      }    
  }


  

  useEffect(() => {
    console.log('useEffet rowIds',rowIds)

 },[rowIds]);
const handleClickOpenApprove = (rowId) => {
  
setDialogOpenApprove(true);
setRowId(rowId)
console.log('handleClickOpenApprove',rowId)
}
const handleCloseApprove = () => setDialogOpenApprove(false);
const handleClickOpenReject = (rowId) => {
  setDialogOpenReject(true);setRowId(rowId)

};
const handleCloseReject = () => setDialogOpenReject(false);


const handleClickOpenApproveFavorable = (rowId) => {
  
  setDialogOpenApproveFavorable(true);
  setRowId(rowId)
  console.log('checking rowID',rowId)
  }
  const handleCloseApproveFavorable = () => setDialogOpenApproveFavorable(false);
  const handleClickOpenRejectFavorable = (rowId) => {
    setDialogOpenRejectFavorable(true);setRowId(rowId)
  };
  const handleCloseRejectFavorable = () => setDialogOpenRejectFavorable(false);
  
 
  const handleClickOpenApproveUnapproved = (rowId) => {
  
    setDialogOpenApproveUnapproved(true);
    setRowId(rowId)
    }
    const handleCloseApproveUnapproved = () => setDialogOpenApproveUnapproved(false);
    const handleClickOpenRejectUnapproved = (rowId) => {
      setDialogOpenRejectUnapproved(true);setRowId(rowId)
    };
    const handleCloseRejectUnapproved = () => setDialogOpenRejectUnapproved(false);
  

function getwrongCategoryData(params) {
   
    return(
     `In ${params.row.bhCategoryName || ''} suggested supplier is
         ${params.row.productDescription || ''}
      Current supplier is ${params.row.suggestedBhCategoryName || ''}
         From ${params.row.createdBy || ''} on ${params.row.createdOn || ''}`
      )   
  }

 

  function getfavorableSupplierData(params) {
    return  (`In ${params.row.bhCategoryName || ''} suggested supplier is
         ${params.row.favorableSupplierNameSuggested || ''}
Current supplier is ${params.row.favorableSupplierName || ''}
         From  ${params.row.email || ''} ${params.row.createdBy || ''} on ${params.row.createdOn || ''}`)
  }

  function getproductNotSold(params) {
    return  (`${params.row.bhCategoryName || ''} is Not sold by any vendor inside BH
         From  ${params.row.email || ''} ${params.row.createdBy || ''} on ${params.row.createdOn || ''}`)
  }

  function getunapprovedSupplierData(params) {
    return  (`${params.row.unapprovedSupplierName || ''} is wrongly tagged correct supplier is ${params.row.suggestedSupplierName || ''}
         From  ${params.row.email || ''} ${params.row.createdBy || ''} on ${params.row.createdOn || ''}`)
  }

  function getproductCategoryNotSoldByVendor(params){
    return (`${params.row.productDescription || ''} belongs to category ${params.row.bhCategoryName || ''} is not sold by
    ${params.row.vendorName || ''} From ${params.row.createdBy || ''} on ${params.row.createdOn || ''}`)
  }

const wrongCategoryColumn = [
    {
        field: 'rowId',
        headerName: 'ID',
        width: 70,
        hide: true,
      },
      {
        field: 'productDescription',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'suggestedBhCategoryName',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'createdBy',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'createdOn',
        headerName:'' ,
        width: 50,
        hide:true
      },
    {
      field: 'bhCategoryName',
      headerName: 'Comment Details',
      width: 1400,
      valueGetter: getwrongCategoryData,
      
     
    },
    {
      field: 'comments',
      headerName: 'Approve/Reject',
      width: 300,
      renderCell: (params) => {
        return (
            <div>
          <Button variant="contained" color="primary" sx={{mr:3}}
          onClick={() => handleClickOpenApprove(params.row.rowId)} value="approved">Approve</Button>
        <Button variant="contained" color="error"
        onClick={() => handleClickOpenReject(params.row.rowId)} value="rejected">Reject</Button>
        </div>
        );
      }
    }
   
]

const favorableSupplierDataColumn = [
    {
        field: 'rowId',
        headerName: 'ID',
        width: 70,
        hide: true,
      },
      {
        field: 'productDescription',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'favorableSupplierName',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'favorableSupplierNameSuggested',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'email',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'createdBy',
        headerName:'' ,
        width: 50,
        hide:true
      },
      {
        field: 'createdOn',
        headerName:'' ,
        width: 50,
        hide:true
      },
    {
      field: 'bhCategoryName',
      headerName: 'Comment Details',
      width: 1400,
      valueGetter: getfavorableSupplierData
    },
    {
      field: 'comments',
      headerName: 'Approve/Reject',
      width: 300,
      renderCell: (cellValues) => {
        return (
            <div>
          <Button variant="contained" color="primary" sx={{mr:3}}
          onClick={() => handleClickOpenApproveFavorable(cellValues.row.rowId)}>Approve</Button>
        <Button variant="contained" color="error"
        onClick={() => handleClickOpenRejectFavorable(cellValues.row.rowId)}>Reject</Button>
        </div>
        );
      }
    }
   
]


const productsNotSoldDataColumn=[
    {
        field: 'rowId',
        headerName: 'ID',
        width: 70,
        hide: true,
      },
      {
        field: 'bhCategoryName',
        headerName: 'Comment Details',
        width: 1400,
        valueGetter: getproductNotSold
      },
      {
        field: 'comments',
        headerName: 'Approve/Reject',
        width: 300,
        renderCell: (cellValues) => {
          return (
              <div>
            <Button variant="contained" color="primary" sx={{mr:3}}
            onClick={() => handleClickOpenApprove(cellValues.row.rowId)}>Approve</Button>
          <Button variant="contained" color="error"
          onClick={() => handleClickOpenReject(cellValues.row.rowId)}>Reject</Button>
          </div>
          );
        }
      }
]
const unapprovedSupplierDataColumn=[
    {
        field: 'rowId',
        headerName: 'ID',
        width: 70,
        hide: true,
      },
      {
        field: 'bhCategoryName',
        headerName: 'Comment Details',
        width: 1400,
        valueGetter:getunapprovedSupplierData
      },
      {
        field: 'comments',
        headerName: 'Approve/Reject',
        width: 300,
      renderCell: (cellValues) => {
        return (
            <div>
          <Button variant="contained" color="primary" sx={{mr:3}}
          onClick={() => handleClickOpenApproveUnapproved(cellValues.row.rowId)}>Approve</Button>
        <Button variant="contained" color="error"
        onClick={() => handleClickOpenRejectUnapproved(cellValues.row.rowId)}>Reject</Button>
        </div>
        );
      }
      }
]

const productsNotSoldVendorDataColumn=[
    {
        field: 'rowId',
        headerName: 'ID',
        width: 70,
        hide: true,
      },
      {
        field: 'bhCategoryName',
        headerName: 'Comment Details',
        width: 1400,
        valueGetter:getproductCategoryNotSoldByVendor
      },
      {
        field: 'comments',
        headerName: 'Approve/Reject',
        width: 300,
      renderCell: (cellValues) => {
        return (
            <div>
          <Button variant="contained" color="primary" sx={{mr:3}}
          onClick={() => handleClickOpenApprove(cellValues.row.rowId)}>Approve</Button>
        <Button variant="contained" color="error"
        onClick={() => handleClickOpenReject(cellValues.row.rowId)}>Reject</Button>
        </div>
        );
      }
    }
  ]

  function wrongCategoryDataCall(){
    axios.post("/api/corporate/feedback/getFeedbackWrongCategory" )
    .then(response => setWrongCategoryData(response.data.map((row, idx) => ({...row, id: idx})))
    ,console.log('wrongCategoryData response',wrongCategoryData))
  }
  const wrongCategoryDataCallApproveReject=()=>{
    const updatedData=wrongCategoryData.filter(row=>!selectedRows1.includes(row))
    setWrongCategoryData(updatedData)
  }
 
  function productsNotSoldDataCall(){
    axios.post("/api/corporate/feedback/getFeedbackProductsNotSold" )
    .then(response => setProductsNotSoldData(response.data.map((row, idx) => ({...row, id: idx})))
    ,console.log('productsNotSoldData response',productsNotSoldData))
  }

  const productsNotSoldDataCallApproveReject=()=>{
    const updatedData=productsNotSoldData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldData(updatedData)

  }

  function unapprovedSupplierDataCall(){
    axios.post("/api/corporate/feedback/getFeedbackUnapprovedSupplierMapping" )
    .then(response => setUnapprovedSupplierData(response.data.map((row, idx) => ({...row, id: idx})))
    ,console.log('unapprovedSupplierData response',unapprovedSupplierData))
  }

  const unapprovedSupplierDataCallApproveReject=()=>{
    const updatedData=unapprovedSupplierData.filter(row=>!selectedRows1.includes(row))
    setUnapprovedSupplierData(updatedData)
  }

  const favorableSupplierDataCall=async()=>{
    await axios.post("/api/corporate/feedback/getFeedbackFavorableSupplierFeedback" )
    .then(response => setFavorableSupplierData(response.data.map((row, idx) => ({...row, id: idx})))
    ,console.log('favorableSupplierData response',favorableSupplierData))
  }

  const favorableSupplierDataCallApproveReject=()=>{
    const updatedData=favorableSupplierData.filter(row=>!selectedRows1.includes(row))
    setFavorableSupplierData(updatedData)

  }
  function productsNotSoldVendorDataCall(){
    axios.post("/api/corporate/feedback/getFeedbackProductsNotSoldByVendor" )
    .then(response => setProductsNotSoldVendor(response.data.map((row, idx) => ({...row, id: idx})))
    ,console.log('productsNotSoldVendorData response',productsNotSoldVendorData))
  }
 
  const productsNotSoldVendorDataCallApproveReject=()=>{
    const updatedData=productsNotSoldVendorData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldVendor(updatedData)
    // setTimeout(()=>{
    //   axios.post(`api/corporate/feedback/getFeedbackProductsNotSoldByVendor/`).then(res=>{
    //   setProductsNotSoldVendor(res.data.map((row,idx)=>({...row,id:idx}),
    //   setRowIds([]),
    //   ))      
    // })
    // },500)
  }
  useEffect(() => {
    wrongCategoryDataCall();
    productsNotSoldDataCall();
    unapprovedSupplierDataCall();
    favorableSupplierDataCall();
    productsNotSoldVendorDataCall();
    //  axios.post("/api/corporate/feedback/getFeedbackWrongCategory" )
    // .then(response => setWrongCategoryData(response.data.map((row, idx) => ({...row, id: idx})))
    // ,console.log('wrongCategoryData response',wrongCategoryData))
    // axios.post("/api/corporate/feedback/getFeedbackProductsNotSold" )
    // .then(response => setProductsNotSoldData(response.data.map((row, idx) => ({...row, id: idx})))
    // ,console.log('productsNotSoldData response',productsNotSoldData))
    // axios.post("/api/corporate/feedback/getFeedbackUnapprovedSupplierMapping" )
    // .then(response => setUnapprovedSupplierData(response.data.map((row, idx) => ({...row, id: idx})))
    // ,console.log('unapprovedSupplierData response',unapprovedSupplierData))
    // axios.post("/api/corporate/feedback/getFeedbackFavorableSupplierFeedback" )
    // .then(response => setFavorableSupplierData(response.data.map((row, idx) => ({...row, id: idx})))
    // ,console.log('favorableSupplierData response',favorableSupplierData))
    // axios.post("/api/corporate/feedback/getFeedbackProductsNotSoldByVendor" )
    // .then(response => setProductsNotSoldVendor(response.data.map((row, idx) => ({...row, id: idx})))
    // ,console.log('productsNotSoldVendorData response',productsNotSoldVendorData))


    
  },[]);

const handleTabs=(e,val)=>{
 console.log('outside if val',val)
 setValue(val)
 
}

const onClickApprove=()=>{
  let params={updateRowIds:[{approvalStatus:"approved",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackWrongCatAndProdNotSold",params )
  .then(res => {
    if (res.status == 200) {

    enqueueSnackbar(res.data.message);
    setDialogOpenApprove(false);
    const updatedData=wrongCategoryData.filter(row=>!selectedRows1.includes(row))
    setWrongCategoryData(updatedData)
    const updatedData1=productsNotSoldData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldData(updatedData1)
    const updatedData2=productsNotSoldVendorData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldVendor(updatedData2)
    }
  }    )
}

const onClickReject=()=>{
  let params={updateRowIds:[{approvalStatus:"rejected",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackWrongCatAndProdNotSold",params )
  .then(res => {
    if (res.status == 200) {
    enqueueSnackbar(res.data.message);
    setDialogOpenReject(false);
    const updatedData=wrongCategoryData.filter(row=>!selectedRows1.includes(row))
    setWrongCategoryData(updatedData)
    const updatedData1=productsNotSoldData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldData(updatedData1)
    const updatedData2=productsNotSoldVendorData.filter(row=>!selectedRows1.includes(row))
    setProductsNotSoldVendor(updatedData2)
  }
}
    )
}

const onClickApproveFavorable=()=>{
  let params={updateRowIds:[{approvalStatus:"approved",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackFavorableSupplierFeedback",params )
  .then(res => {
    if (res.status == 200) {

    enqueueSnackbar(res.data.message);
    setDialogOpenApproveFavorable(false);
    const updatedData=favorableSupplierData.filter(row=>!selectedRows1.includes(row))
    setFavorableSupplierData(updatedData)
    }
  }    )
}
//updateFeedbackUnapprovedSupplierMapping
const onClickRejectFavorable=()=>{
  let params={updateRowIds:[{approvalStatus:"rejected",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackFavorableSupplierFeedback",params )
  .then(res => {
    if (res.status == 200) {
    enqueueSnackbar(res.data.message);
    setDialogOpenRejectFavorable(false);
    const updatedData=favorableSupplierData.filter(row=>!selectedRows1.includes(row))
    setFavorableSupplierData(updatedData)
  }
}
    )
}

const onClickApproveUnapproved=()=>{
  let params={updateRowIds:[{approvalStatus:"approved",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackUnapprovedSupplierMapping",params )
  .then(res => {
    if (res.status == 200) {

    enqueueSnackbar(res.data.message);
    setDialogOpenApproveUnapproved(false);
    const updatedData=unapprovedSupplierData.filter(row=>!selectedRows1.includes(row))
    setUnapprovedSupplierData(updatedData)
    }
  }    )
}
//updateFeedbackUnapprovedSupplierMapping
const onClickRejectUnapproved=()=>{
  let params={updateRowIds:[{approvalStatus:"rejected",rowId:rowId,comments:""}]}
  console.log('params',params)
  axios.post("/api/corporate/feedback/updateFeedbackUnapprovedSupplierMapping",params )
  .then(res => {
    if (res.status == 200) {
    enqueueSnackbar(res.data.message)
    setDialogOpenRejectUnapproved(false);
    const updatedData=unapprovedSupplierData.filter(row=>!selectedRows1.includes(row))
    setUnapprovedSupplierData(updatedData)
  }
}
    )
}


const handleClickApproveAll=()=>{
  let params={approvalStatus:"Approved",comments:"",rowIds:rowIds}
  if(value == 0){ 
        console.log('params approve ',value,rowIds)

  axios.post("/api/corporate/feedback/updateAllFeedbackWrongCategoryFeedback",params )
  .then(res => {
    console.log('approved all 0',res)
    if (res.status == 200) {
    enqueueSnackbar(res.data.message)
    wrongCategoryDataCallApproveReject();
  }
}
    )
} 
else if( value == 1 )
    {
      console.log('params approve',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackProductNotSoldFeedback",params )
      .then(res => {
        console.log('approved all 1',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        productsNotSoldDataCallApproveReject()

      }
    }
        )
 
    }

    else if( value == 2 )
    {
      console.log('params approve',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackUnapprovedSupplierFeedback",params )
      .then(res => {
        console.log('approved all 2',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        unapprovedSupplierDataCallApproveReject()

      }
    }
        )
 
    }


    else if( value == 3 )
    {
      console.log('params approve',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackFavorableSupplierFeedback",params )
      .then(res => {
        console.log('approved all 3',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        favorableSupplierDataCallApproveReject();
        
      }
    }
        )
 
    }


    else if(value == 4){
      console.log('params approve',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllProductNotSoldByVendorFeedback",params )
      .then(res => {
        console.log('approved all 4',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        productsNotSoldVendorDataCallApproveReject();

      }
    }
        )
 
    }
 
}

const handleClickRejectAll=()=>{
  let params={approvalStatus:"Rejected",comments:"",rowIds:rowIds}
     console.log('parms',params)
  if(value == 0){ 
        console.log('rejected 0',value,rowIds)

  axios.post("/api/corporate/feedback/updateAllFeedbackWrongCategoryFeedback",params )
  .then(res => {
    console.log('rejected all 0',res)
    if (res.status == 200) {
    enqueueSnackbar(res.data.message)
    wrongCategoryDataCallApproveReject();
  }
}
    )

} 
else if( value == 1 )
    {
      console.log('rejected 1',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackProductNotSoldFeedback",params )
      .then(res => {
        console.log('rejected all 1',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        productsNotSoldDataCallApproveReject()
      }
    }
        )
 
    }

    else if( value == 2 )
    {
      console.log('rejected 2',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackUnapprovedSupplierFeedback",params )
      .then(res => {
        console.log('rejected all 2',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        unapprovedSupplierDataCallApproveReject()
      }
    }
        )
 
    }

    else if( value == 3 )
    {
     
      console.log('rejected 3',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllFeedbackFavorableSupplierFeedback",params )
      .then(res => {
        console.log('rejected all 3',res)
        if (res.status == 200) {
          enqueueSnackbar(res.data.message)
          favorableSupplierDataCallApproveReject();
      }
    }
        )
 
    }

    else if(value == 4){
      console.log('rejected 4',value,rowIds)
      axios.post("/api/corporate/feedback/updateAllProductNotSoldByVendorFeedback",params )
      .then(res => {
        console.log('rejected all 4',res)
        if (res.status == 200) {
        enqueueSnackbar(res.data.message)
        productsNotSoldVendorDataCallApproveReject();
      }
    }
        )
 
    }
}

const handleExport=()=>{

  if(value == 0){ 
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("WrongCategoryTab", {
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
            // printArea: `A1:F${wrongCategoryData.length + 4}`,
            printTitlesRow: '1:4',
            horizontalCentered: true,
            showGridLines: false
        },
        // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
        views: [{  showGridLines: false, showRowColHeaders: false }],

        headerFooter: {
            oddFooter: "Page &P of &N"
        }
    });
  
    // Add Image
    const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
    const title = "BLUE HAWK";
    const title1 = "Based On Collective Purchasing Survey Wrong Category Feedback";
    const str = title + "\n" + title1;
    const sheetHeaderRow = ["COMMENT DETAILS"];
  
    const titleRow = worksheet.addRow([str]);
    titleRow.eachCell((cell, number) => {
        cell.alignment = { wrapText: true, vertical: 'middle' };
    });
    titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
    worksheet.addRow([]);
    // Add Image in "E1:F2" cell range
    // worksheet.addImage(insertedLogo, {
    //     tl: { col: 4.2, row: 0.2 },
    //     br: { col: 5.8, row: 1.8 }
    // });
    worksheet.addImage(insertedLogo, "E1:F3");

    worksheet.mergeCells("A1:D2");
    worksheet.mergeCells("E1:F2");
    worksheet.addRow([]);
  
  
    // Add Header Row
    const headerRow = worksheet.addRow(sheetHeaderRow);
  
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
        cell.fill = {
            type: "pattern",
            pattern: "",
            fgColor: { argb: "FFAA00" },
            bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thick" },
            right: { style: "thin" },
        };
        // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

    });
    
    
    wrongCategoryData.forEach(function (obj) {
        let addedRow = worksheet.addRow(['In'+' '+obj.bhCategoryName +' '+'suggest supplier is'+' '+
        obj.productDescription +' '+'Current supplier is'+' '+ obj.suggestedBhCategoryName +' '+'From' + ' '+obj.createdBy
      +' '+'on'+ ' '+obj.createdOn]);
        addedRow.eachCell((cell, number) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

        });
    });
  
    
  
    worksheet.getColumn(1).width = 100;
    // Add some meta data in excel file
    workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
    workbook.company = 'Tredence Inc.';
    workbook.category = 'Wrong Category';
  
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "WrongCategoryTab.xlsx");
    });
}  

else if( value == 1 )
    {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("ProductsNotSold", {
          pageSetup: {
              paperSize: 9,
              orientation: 'landscape',
              // printArea: `A1:F${wrongCategoryData.length + 4}`,
              printTitlesRow: '1:4',
              horizontalCentered: true,
              showGridLines: false
          },
          // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
          views: [{  showGridLines: false, showRowColHeaders: false }],

          headerFooter: {
              oddFooter: "Page &P of &N"
          }
      });
    
      // Add Image
      const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
      const title = "BLUE HAWK";
      const title1 = "Based On Collective Purchasing Survey Products Not Sold Feedback";
      const str = title + "\n" + title1;
      const sheetHeaderRow = ["COMMENT DETAILS"];
    
      const titleRow = worksheet.addRow([str]);
      titleRow.eachCell((cell, number) => {
          cell.alignment = { wrapText: true, vertical: 'middle' };
      });
      titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
      worksheet.addRow([]);
      // Add Image in "E1:F2" cell range
      // worksheet.addImage(insertedLogo, {
      //     tl: { col: 4.2, row: 0.2 },
      //     br: { col: 5.8, row: 1.8 }
      // });
      worksheet.addImage(insertedLogo, "E1:F3");

      worksheet.mergeCells("A1:D2");
      worksheet.mergeCells("E1:F2");
      worksheet.addRow([]);
    
    
      // Add Header Row
      const headerRow = worksheet.addRow(sheetHeaderRow);
    
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
          cell.fill = {
              type: "pattern",
              pattern: "",
              fgColor: { argb: "FFAA00" },
              bgColor: { argb: "FF0000FF" },
          };
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thick" },
              right: { style: "thin" },
          };
          // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
      
      
      productsNotSoldData.forEach(function (obj) {
          let addedRow = worksheet.addRow([obj.bhCategoryName +' '+'is Not sold by any vendor inside BH From'+' '+
          obj.email +' '+ obj.createdBy +' '+'on'+ ' '+obj.createdOn]);
          addedRow.eachCell((cell, number) => {
              cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
              };
              cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
  
          });
      });

       
    
      worksheet.getColumn(1).width = 100;
      // Add some meta data in excel file
      workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
      workbook.company = 'Tredence Inc.';
      workbook.category = 'Products Not Sold';
    
      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "ProductsNotSold.xlsx");
      });
    }

else if( value == 2 )
    {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("UnapprovedSupplierMapping", {
          pageSetup: {
              paperSize: 9,
              orientation: 'landscape',
              // printArea: `A1:F${wrongCategoryData.length + 4}`,
              printTitlesRow: '1:4',
              horizontalCentered: true,
              showGridLines: false
          },
          // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
          views: [{  showGridLines: false, showRowColHeaders: false }],

          headerFooter: {
              oddFooter: "Page &P of &N"
          }
      });
    
      // Add Image
      const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
      const title = "BLUE HAWK";
      const title1 = "Based On Collective Purchasing Survey Unapproved Supplier Mapping Feedback";
      const str = title + "\n" + title1;
      const sheetHeaderRow = ["COMMENT DETAILS"];
    
      const titleRow = worksheet.addRow([str]);
      titleRow.eachCell((cell, number) => {
          cell.alignment = { wrapText: true, vertical: 'middle' };
      });
      titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
      worksheet.addRow([]);
      // Add Image in "E1:F2" cell range
      // worksheet.addImage(insertedLogo, {
      //     tl: { col: 4.2, row: 0.2 },
      //     br: { col: 5.8, row: 1.8 }
      // });
      worksheet.addImage(insertedLogo, "E1:F3");

      worksheet.mergeCells("A1:D2");
      worksheet.mergeCells("E1:F2");
      worksheet.addRow([]);
    
    
      // Add Header Row
      const headerRow = worksheet.addRow(sheetHeaderRow);
    
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
          cell.fill = {
              type: "pattern",
              pattern: "",
              fgColor: { argb: "FFAA00" },
              bgColor: { argb: "FF0000FF" },
          };
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thick" },
              right: { style: "thin" },
          };
          // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
      
      
      unapprovedSupplierData.forEach(function (obj) {
          let addedRow = worksheet.addRow([obj.unapprovedSupplierName +' '+'is wrongly tagged correct supplier is'+' '+obj.suggestedSupplierName+' '+
          'From'+ ' '+obj.email +' '+ obj.createdBy +' '+'on'+ ' '+obj.createdOn]);
          addedRow.eachCell((cell, number) => {
              cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
              };
              cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
  
          });
      });

    
      worksheet.getColumn(1).width = 100;
      // Add some meta data in excel file
      workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
      workbook.company = 'Tredence Inc.';
      workbook.category = 'Unapproved Supplier Mapping';
    
      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "UnapprovedSupplierMapping.xlsx");
      });
    }

else if( value == 3 )
    {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("FavorableSupplierFeedback", {
          pageSetup: {
              paperSize: 9,
              orientation: 'landscape',
              // printArea: `A1:F${wrongCategoryData.length + 4}`,
              printTitlesRow: '1:4',
              horizontalCentered: true,
              showGridLines: false
          },
          // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
          views: [{  showGridLines: false, showRowColHeaders: false }],

          headerFooter: {
              oddFooter: "Page &P of &N"
          }
      });
    
      // Add Image
      const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
      const title = "BLUE HAWK";
      const title1 = "Based On Collective Purchasing Survey Favorable Supplier Feedback";
      const str = title + "\n" + title1;
      const sheetHeaderRow = ["COMMENT DETAILS"];
    
      const titleRow = worksheet.addRow([str]);
      titleRow.eachCell((cell, number) => {
          cell.alignment = { wrapText: true, vertical: 'middle' };
      });
      titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
      worksheet.addRow([]);
      // Add Image in "E1:F2" cell range
      // worksheet.addImage(insertedLogo, {
      //     tl: { col: 4.2, row: 0.2 },
      //     br: { col: 5.8, row: 1.8 }
      // });
      worksheet.addImage(insertedLogo, "E1:F3");

      worksheet.mergeCells("A1:D2");
      worksheet.mergeCells("E1:F2");
      worksheet.addRow([]);
    
    
      // Add Header Row
      const headerRow = worksheet.addRow(sheetHeaderRow);
    
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
          cell.fill = {
              type: "pattern",
              pattern: "",
              fgColor: { argb: "FFAA00" },
              bgColor: { argb: "FF0000FF" },
          };
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thick" },
              right: { style: "thin" },
          };
          // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
      
      
      favorableSupplierData.forEach(function (obj) {
          let addedRow = worksheet.addRow(['In'+' '+obj.bhCategoryName +' '+'suggested supplier is'+' '+obj.favorableSupplierNameSuggested+' '+
          'Current supplier is'+' '+obj.favorableSupplierName+' '+'From'+ ' '+obj.email +' '+ obj.createdBy +' '+'on'+ ' '+obj.createdOn]);
          addedRow.eachCell((cell, number) => {
              cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
              };
              cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
  
          });
      });

    
      worksheet.getColumn(1).width = 100;
      // Add some meta data in excel file
      workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
      workbook.company = 'Tredence Inc.';
      workbook.category = 'Favorable Supplier Feedback';
    
      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "FavorableSupplierFeedback.xlsx");
      });
    }

else if( value == 4)
    {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("ProductCategoryNotSoldByVendor", {
          pageSetup: {
              paperSize: 9,
              orientation: 'landscape',
              // printArea: `A1:F${wrongCategoryData.length + 4}`,
              printTitlesRow: '1:4',
              horizontalCentered: true,
              showGridLines: false
          },
          // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
          views: [{  showGridLines: false, showRowColHeaders: false }],

          headerFooter: {
              oddFooter: "Page &P of &N"
          }
      });
    
      // Add Image
      const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
      const title = "BLUE HAWK";
      const title1 = "Based On Collective Purchasing Survey Product Category Not Sold By Vendor";
      const str = title + "\n" + title1;
      const sheetHeaderRow = ["COMMENT DETAILS"];
    
      const titleRow = worksheet.addRow([str]);
      titleRow.eachCell((cell, number) => {
          cell.alignment = { wrapText: true, vertical: 'middle' };
      });
      titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
      worksheet.addRow([]);
      // Add Image in "E1:F2" cell range
      // worksheet.addImage(insertedLogo, {
      //     tl: { col: 4.2, row: 0.2 },
      //     br: { col: 5.8, row: 1.8 }
      // });
      worksheet.addImage(insertedLogo, "E1:F3");

      worksheet.mergeCells("A1:D2");
      worksheet.mergeCells("E1:F2");
      worksheet.addRow([]);
    
    
      // Add Header Row
      const headerRow = worksheet.addRow(sheetHeaderRow);
    
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
          cell.fill = {
              type: "pattern",
              pattern: "",
              fgColor: { argb: "FFAA00" },
              bgColor: { argb: "FF0000FF" },
          };
          cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thick" },
              right: { style: "thin" },
          };
          // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

      });
      
      
      productsNotSoldVendorData.forEach(function (obj) {
          let addedRow = worksheet.addRow([obj.productDescription +' '+'belongs to category'+' '+obj.bhCategoryName+' '+
          'is not sold by'+' '+obj.vendorName+' '+'From'+ ' '+ obj.createdBy +' '+'on'+ ' '+obj.createdOn]);
          addedRow.eachCell((cell, number) => {
              cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
              };
              cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
  
          });
      });

     
    
      worksheet.getColumn(1).width = 100;
      // Add some meta data in excel file
      workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
      workbook.company = 'Tredence Inc.';
      workbook.category = 'Product Category Not Sold By Vendor';
    
      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "ProductCategoryNotSoldByVendor.xlsx");
      });
    }
}






  return (
    <Box sx={{ width: "100%" ,height:"100vh",backgroundColor:"#fff",p:2,mt:'10px'}}>
         <Tabs value={value} onChange={handleTabs}>
         <Tooltip title="Feedback for all incorrectly categorized products" 
         placement="top" ><Tab label="Wrong Category"></Tab></Tooltip>
         <Tooltip title="Feedback for all product categorized which are not sold within BH Group" 
         placement="top" ><Tab label="Products Not Sold"></Tab></Tooltip>
         <Tooltip title="Feedback for incorrectly mapped vendor partners and unapproved suppliers" 
         placement="top" ><Tab label="Unapproved Supplier Mapping"></Tab></Tooltip>
         <Tooltip title="Feedback for incorrectly mapped favorable vendor partners" 
         placement="top"><Tab label="Favorable Supplier Feedback"></Tab></Tooltip>
         <Tooltip title="Feedback for all product categories which are not sold by Vendor" 
         placement="top"><Tab label="Product Category not Sold by Vendor"></Tab></Tooltip>
          <Button variant="contained" color="primary" sx={{mr:3,mb:'10px'}}
          onClick={() => handleClickApproveAll()}>Approve All</Button>
              <Button variant="contained" color="error" sx={{mr:3,mb:'10px'}}
          onClick={() => handleClickRejectAll()} value="approved">Reject All</Button>
          <Button variant="contained" color="primary" sx={{mr:3,mb:'10px',float:'right'}}
          onClick={() => handleExport()} ><FileDownloadOutlinedIcon/>EXPORT</Button>
             </Tabs>
            
     <TabPanel value={value} index={0} columns={wrongCategoryColumn}
     rows={wrongCategoryData} onRowsSelectionHandler1={onRowsSelectionHandler}> 
      </TabPanel>
      <TabPanel value={value} index={1} columns={productsNotSoldDataColumn}
     rows={productsNotSoldData} onRowsSelectionHandler1={onRowsSelectionHandler}> 
      </TabPanel>
      <TabPanel value={value} index={2} columns={unapprovedSupplierDataColumn}
     rows={unapprovedSupplierData} onRowsSelectionHandler1={onRowsSelectionHandler}> 
      </TabPanel>
       <TabPanel value={value} index={3} columns={favorableSupplierDataColumn}
     rows={favorableSupplierData} onRowsSelectionHandler1={onRowsSelectionHandler}> 
      </TabPanel>
      <TabPanel value={value} index={4} columns={productsNotSoldVendorDataColumn}
     rows={productsNotSoldVendorData} onRowsSelectionHandler1={onRowsSelectionHandler}> 
      </TabPanel>
  
      <Dialog open={dialogOpenApprove} onClose={handleCloseApprove} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Approve?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to approve selected feedback ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApprove} variant="outlined">Cancel</Button>
          <Button onClick={onClickApprove} variant="contained" color="error">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpenReject} onClose={handleCloseReject} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Reject?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Comment for rejecting feedback (Optional)
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReject} variant="outlined">Cancel</Button>
          <Button onClick={onClickReject} variant="contained" color="error">Submit</Button>
        </DialogActions>
      </Dialog>

      {/* // For Favorable  */}

      <Dialog open={dialogOpenApproveFavorable} onClose={handleCloseApproveFavorable} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Approve?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to approve selected feedback ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveFavorable} variant="outlined">Cancel</Button>
          <Button onClick={onClickApproveFavorable} variant="contained" color="error">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpenRejectFavorable} onClose={handleCloseRejectFavorable} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Reject?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Comment for rejecting feedback (Optional)
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectFavorable} variant="outlined">Cancel</Button>
          <Button onClick={onClickRejectFavorable} variant="contained" color="error">Submit</Button>
        </DialogActions>
      </Dialog>
      {/* Fvorable end */}

      {/* Unapproved  */}
      <Dialog open={dialogOpenApproveUnapproved} onClose={handleCloseApproveUnapproved} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Approve?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to approve selected feedback ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveUnapproved} variant="outlined">Cancel</Button>
          <Button onClick={onClickApproveUnapproved} variant="contained" color="error">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpenRejectUnapproved} onClose={handleCloseRejectUnapproved} >
        <DialogTitle style={{textAlign:'center',fontSize:'30px'}}>Reject?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Comment for rejecting feedback (Optional)
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectUnapproved} variant="outlined">Cancel</Button>
          <Button onClick={onClickRejectUnapproved} variant="contained" color="error">Submit</Button>
        </DialogActions>
      </Dialog>
     
    </Box>
  );
}

function TabPanel(props){
  const [rowIds,setRowIds]=useState();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    console.log('check',rowIds)
  
 },[rowIds]);


    return (
            <div>
            {props.value===props.index &&(
                  <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
            rows={props.rows}
            columns={props.columns}
            onSelectionModelChange={props.onRowsSelectionHandler1}
            checkboxSelection
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
        // components={{
        //       Toolbar: CustomToolbar,
        //      }}
        //      localeText={{
        //       toolbarExport: "export"
        //     }}
            />
            </div>
            )
            }


            </div>
    )

          
}
