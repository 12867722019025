import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Chip, Stack } from '@mui/material';
import React from 'react';

const dollarUSLocale = (min, max) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: min, maximumFractionDigits: max ? max : min });
const percentUSLocale = (min, max) => Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: min, maximumFractionDigits: max ? max : min });

const formatType = (value, format) => {
    if (typeof format === 'string' && format.toLowerCase().includes('percent')) {
        return percentUSLocale(2).format(value / 100);
    } else {
        return dollarUSLocale(0).format(value);
    }
};

const formatTypecriteria = (value, format, criteriaName) => {
    if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('sales')) {
        if (typeof format === 'string' && format.toLowerCase().includes('percent')) {
            return percentUSLocale(2).format(value / 100);
        } else {
            return dollarUSLocale(0).format(value);
        }
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('pounds')) {
        if (typeof format === 'string' && format.toLowerCase().includes('percent')) {
            return percentUSLocale(2).format(value / 100);
        } else {
            return (

                Number(value).toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' lbs'

            );
        }
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('units')) {
        if (typeof format === 'string' && format.toLowerCase().includes('percent')) {
            return percentUSLocale(2).format(value / 100);
        } else {
            return (
                // value ? <span className={value < 0 ? 'text-danger' : ''}>
                //   {
                Number(value).toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' units'
                // }
                // </span> : <span className="text-muted">-</span>
            );
        }

    }
};

export function RebateTier({ tier }) {
    return <Chip label={tier} size='small' color="primary" />
}

export function RebateAttainment({ attainment }) {
    let color = 'primary';
    if (attainment >= 100) {
        color = 'success';
    } else if (attainment < 100 && attainment >= 75) {
        color = 'warning'
    } else if (attainment < 75) {
        color = 'error';
    }
    return <Chip label={percentUSLocale(2).format(attainment / 100)} size='small' color={color} />
}

export function RebateCritera({ criteriaName, criteriaSetting, min = 1, max, criteriaCount = false }) {
    return (
        <Stack direction='row' spacing={1}>
            <Chip label={criteriaName} size='small' />
            <Chip label={'Min: ' + formatTypecriteria(min, criteriaSetting, criteriaName)} variant="outlined" size='small' />
            {criteriaCount && criteriaCount > 1 ? <Chip size='small' color='info' label={'+' + (criteriaCount - 1)} /> : null}
        </Stack>
    );
}

export function FormatCurrency({ value }) {
    return dollarUSLocale(0).format(value);
}

export function FormatPercent({ value }) {
    return percentUSLocale(2).format(value / 100);
}

export function FormatValue({ value, format }) {
    return formatType(value, format);
}

export function FormatCurrencyCriteria({ value, criteriaName }) {
    if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('sales')) {
        return dollarUSLocale(0).format(value);
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('pounds')) {
        return (
            value ? <span className={value < 0 ? 'text-danger' : ''}>
                {Number(value).toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' lbs'}
            </span> : <span className="text-muted">-</span>
        );
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('units')) {
        return (
            value ? <span className={value < 0 ? 'text-danger' : ''}>
                {Number(value).toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' units'}
            </span> : <span className="text-muted">-</span>
        );
    }
}

export function FormatCurrencyCriteriaMeasurePy({ value, criteriaName }) {
    if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('sales')) {
        return dollarUSLocale(0).format(value);
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('pounds')) {
        return (
            value ? <span className={value < 0 ? 'text-danger' : ''}>
                {Number(value).toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) + ' lbs'}
            </span> : <span className="text-muted">-</span>
        );
    } else if (typeof criteriaName === 'string' && criteriaName.toLowerCase().includes('units')) {
        return (
            // value ? <span className={value < 0 ? 'text-danger' : ''}>
            //   {Number(value).toLocaleString('en-US', {
            //     style: 'decimal',
            //     minimumFractionDigits: 0,
            //     maximumFractionDigits: 0,
            //   }) + ' units'}
            // </span> :

            <span className="text-muted">-</span>
        );
    }
}

function getYoyProps(yoy) {
    const icon = yoy <= 0 ? <TrendingDownIcon /> : <TrendingUpIcon />;
    let color = 'primary';
    color = yoy <= 0 ? 'error' : 'success';
    return { color, icon };
}

export function YoyChip({ yoy }) {
    yoy = yoy > 200 ? 200 : yoy;
    yoy = yoy < -200 ? -200 : yoy;
    const yoyProps = getYoyProps(yoy);
    return <Chip variant="outlined" color={yoyProps.color} size="small" label={percentUSLocale(0, 1).format(yoy < 0 ? (yoy * -1) / 100 : yoy / 100)} 
    icon={yoyProps.icon} />;
}
 


// export const tabTrackingValue = {
//     1= corporate overview
//     2= corporate rebate data 
//     3= admin opportunity
//     4= admin feedback
//     5= admin terminated opportunity
//     6= admin category categoryDetails
//     7= admin compliance
//     8= admin dataQuality
//     9= admin Directory
//     10= member verified        
//     11= member overview
//     12= member purchase order
//     13= member invoice
//     14= member terminated opportunity
//     15= vendor overview
//     16= vendor opportunity
//     17= vendor invoices
//     18= vendor terminated opportunity
//     19 =  Login
//     20= Reset Password
//     21= Forgot password
//     22= marketshare  
// };
