import { Box, Toolbar } from '@mui/material';
import axios from "axios";
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import PageFilters from '../../common/filter/page-filters';
import PageContent from '../../common/page-content';
import SecondaryAppBar from '../../common/secondary-app-bar';
import AdminFeedbackSummary from './admin-feedback';

function AdminFeedback() {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axios.get('/track/' + 4).then((res) => {
            console.log('console create user post api data is ', res.data)
            if (res.data) {
                enqueueSnackbar('Corporate Overview tab');
            }
        })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar("Error Message", error.response.data.error);
                }
            });
    }, []);
    
    return (
        <PageContent ready={true}>
            <SecondaryAppBar>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <PageFilters />
                </Toolbar>
            </SecondaryAppBar>
            <AdminFeedbackSummary />

        </PageContent>
    );
}

export default AdminFeedback;