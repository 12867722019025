import { Box, Button, FormControlLabel, IconButton, LinearProgress, Paper, Stack, Switch, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getComparator, stableSort } from '../../../middleware/helper';
import MaterialSearch from '../material-search';
import MemberSelectionButton from './member-selection-button';
import VendorSelectionButton from './vendor-selection-button';
import VpvTableAppliedFilterManager from './vpv-table-applied-filter-manager';
import VpvTableFilterButton from './vpv-table-filter-button';
import VpvTableHeader, { headCells } from './vpv-table-header';
import { getRouteTypeOptions, RebateTabsMap, searchFn } from './vpv-table-helper';
import TablePaginationActions from './vpv-table-pagination-actions';
import VpvTableRow from './vpv-table-row';
import VpvTableTabs from './vpv-table-tabs';
import IosShareIcon from '@mui/icons-material/IosShare';
import * as XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { logoDataUrl } from "../../../export-logo";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { tab } from '@testing-library/user-event/dist/tab';
import { FormatValue } from '../formatter';


export default function VpvTable() {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [removeZero, setRemoveZero] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [rebateList, setRebateList] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const { period, year, month, quarter, memberIds, vendorIds, rebateTypes, sources } = useSelector(state => state.filter);
    const { routeType, useMemberFilter, useVendorFilter } = getRouteTypeOptions({ pathname: location.pathname });
    
    const percentUSLocale = (min, max) => Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: min, maximumFractionDigits: max ? max : min });
   

    const getDependencies = () => {
        let dependencies = [year];
        if (useMemberFilter) {
            dependencies.push(memberIds);
        }
        if (useVendorFilter) {
            dependencies.push(vendorIds);
        }
        return dependencies;
    };

    const getParams = () => {
        let params = { year };
        if (useMemberFilter) {
            params = { ...params, memberId: memberIds.map(row => row.dbaId) };
        }
        if (useVendorFilter) {
            params = { ...params, vendorId: vendorIds.map(row => row.dbaId) };
        }
        return params;
    };

    useEffect(() => {
        const params = getParams();
        Promise.all([
            axios.post('/api/vpv/rebates', { ...params }),
            axios.post('/api/vpv/sources', { ...params })
        ]).then(([rebateResponse, sourceResponse]) => {
            setRebateList(rebateResponse.data);
            setSourceList(sourceResponse.data);
        });
    }, getDependencies());

    // var array1 = rebateList;

    // function update(array, index, newValue) {
    //   array[index] = newValue;
    // }
    // const currentIndex = rebateList.indexOf('Quarterly Flat');

    // let newVal = "Guaranteed";
    // update(array1, currentIndex, newVal);
    // console.log(array1,"array11111111111111111111");
    

    useEffect(() => {
        let params = { period, year, month, quarter, removeZero };
        if (memberIds && memberIds.length > 0) {
            params = { ...params, memberId: memberIds.map(row => row.dbaId) };
        }
        if (vendorIds && vendorIds.length > 0) {
            params = { ...params, vendorId: vendorIds.map(row => row.dbaId) };
        }
        if (rebateTypes && rebateTypes.length > 0) {
            params = { ...params, rebateType: rebateTypes };
        }
        if (sources && sources.length > 0) {
            params = { ...params, source: sources };
        }
        setLoading(true);
        axios.post('/api/vpv/active-tiers-incentive', { ...params }).then(response => {
            setLoading(false);
            setTableData(response.data);
        });
    }, [period, year, month, memberIds, vendorIds, rebateTypes, sources, removeZero]);

    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const clearAll = () => {
        setSearchQuery('');
    }

    const filteredRecords = searchQuery ? tableData.filter(row => searchFn(row, searchQuery)) : tableData;
    const visibleRows = rowsPerPage > 0 ? stableSort(filteredRecords, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRecords;
    const enabledTabs = ['All'].concat(Array.from(new Set(rebateList.map(row => RebateTabsMap[row]))));


    // const handleExport = () => {
    //     var wb = XLSX.utils.book_new(),
    //     ws = XLSX.utils.json_to_sheet(tableData);
    //     XLSX.utils.book_append_sheet(wb, ws, "vpv")
    //     XLSX.writeFile(wb, "vpv.xlsx")
    //     console.log('handleExport',tableData)
    //     }

        const generateExport=()=>{
            console.log('generateExport',tableData)
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Vendor Partner Volume", {
                pageSetup: {
                    paperSize: 9,
                    orientation: 'landscape',
                    printArea: `A1:F${tableData.length + 4}`,
                    printTitlesRow: '1:4',
                    horizontalCentered: true,
                    showGridLines: false,
                    fitToPage:true,
                    fitToHeight:0
    
                },
                // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
                views: [{  showGridLines: false, showRowColHeaders: false }],

                headerFooter: {
                    oddFooter: "Page &P of &N"
                }
            });
          
            // Add Image
            const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
            const title = "BLUE HAWK Vendor Partner Volume";
            const title1 = `Based on collective Purchasing Survey data: ${year}`;
            const str = title + "\n" + title1;
            const sheetHeaderRow = [ "VP Name DBA",
            "Member DBA",
            "Program Name",
            "Calculated",
            "Active Tier",
            "Rebate",
            "Rebate Total",
            "Attainment",
            "Updated",
            "Volume CY",
            "Volume PY Total",
            "Source",
            "Target",
            "Gap"];
          
            const titleRow = worksheet.addRow([str]);
            titleRow.eachCell((cell, number) => {
                cell.alignment = { wrapText: true, vertical: 'middle' };
            });
            titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
            worksheet.addRow([]);
            // Add Image in "E1:F2" cell range
            // worksheet.addImage(insertedLogo, {
            //     tl: { col: 4.2, row: 0.2 },
            //     br: { col: 5.8, row: 1.8 }
            // });
            worksheet.addImage(insertedLogo, "E1:F3");
            worksheet.mergeCells("A1:D2");
            worksheet.mergeCells("E1:F2");
            worksheet.addRow([]);
          
          
            // Add Header Row
            const headerRow = worksheet.addRow(sheetHeaderRow);
          
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: "pattern",
                    pattern: "",
                    fgColor: { argb: "FFAA00" },
                    bgColor: { argb: "FF0000FF" },
                };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thick" },
                    right: { style: "thin" },
                };
                // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
           
            
            tableData.forEach(function (obj) {
                let addedRow = worksheet.addRow([obj.vendorName, obj.memberName,obj.programName,obj.payoutType,
                    obj.activeTier, ((obj.payoutSetting =="Percentage" ?(obj.payoutValue.toFixed(2))+'%':'$'+(obj.payoutValue))),'$'+Math.round(obj.rebate), 
                    percentUSLocale(0, 2).format(obj.attainment < 0 ? (obj.attainment * -1) / 100 : obj.attainment / 100),obj.lastUpdated,'$'+Math.round(obj.volumeCY),'$'+Math.round(obj.salesPYTotal),
                    obj.source
                ,'$'+Math.round(obj.target),'$'+Math.round(obj.gap)]);
                addedRow.eachCell((cell, number) => {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                    if (number === 6) {
                        cell.numFmt = '0.00%';
                       
                    }
                    cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

                });
            });
          
            
          
            worksheet.getColumn(1).width = 40;
            worksheet.getColumn(2).width = 30;
            worksheet.getColumn(3).width = 40;
            worksheet.getColumn(4).width = 20;
            worksheet.getColumn(5).width = 5;
            worksheet.getColumn(6).width = 10;
            worksheet.getColumn(7).width = 10;
            worksheet.getColumn(8).width = 10;
            worksheet.getColumn(9).width = 15;
            worksheet.getColumn(10).width = 15;
            worksheet.getColumn(11).width = 15;
            worksheet.getColumn(12).width = 15;
            worksheet.getColumn(13).width = 15;
            worksheet.getColumn(14).width = 15;
           
          
            // Add some meta data in excel file
            workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
            workbook.company = 'Tredence Inc.';
            workbook.category = 'Vendor Partner Volume';
          
            // Generate Excel File with given name
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "Vendor Partner Volume.xlsx");
            });
          
        }
    
    return (
        <Paper sx={{ padding: '18px 16px 70px 16px'}} >
            <Toolbar disableGutters>
                <VpvTableTabs enabledTabs={enabledTabs} rebateList={rebateList === 'Quarterly Flat'? 'Guaranteed':rebateList} />
                <Box sx={{ flexGrow: 1 }} />
                <FormControlLabel control={<Switch checked={removeZero} onChange={() =>
                     setRemoveZero(!removeZero)} />} label="Remove CY/PY 0" />
                <IconButton onClick={() => clearAll()}></IconButton>
            </Toolbar>
            <Toolbar disableGutters>
                <Typography variant='h6'>Vendor Partner Volume</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction='row'>
                    <VpvTableFilter routeType={routeType} />
                    <MaterialSearch value={searchQuery} onChange={event => setSearchQuery(event.target.value)} placeholder='Search VPV' />
                    <VpvTableFilterButton rebateList={rebateList === 'Quarterly Flat'? 'Guaranteed':rebateList} sourceList={sourceList} />
                    {/* <VpvTableFilterButton rebateList={rebateList} sourceList={sourceList} /> */}
                    <Button variant="outlined" onClick={() => clearAll()}>Clear All</Button>
                    <Button variant="contained" sx={{ float: 'right', marginLeft: '5px' }} 
                    onClick={generateExport}><FileDownloadOutlinedIcon/>Export</Button>

                </Stack>
            </Toolbar>
            <VpvTableAppliedFilterManager pathname={location.pathname} />
            <LoadingProgress loading={loading} />
            
            <TableContainer component={Paper} elevation={2} sx={{ mb: 1 }}>
                <Table aria-label="collapsible table" size="small">
                <div>
                    <VpvTableHeader colDefs={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    </div>
                    <div class="tablebody" style={{height:'400px',overflowY:'auto'}}>

                    {/* <TableBody> */}
                        {visibleRows.map(row => <VpvTableRow key={'vpv-row-' + row.id} row={row} />)}
                    {/* </TableBody> */}
                    </div>
                    </Table>
            </TableContainer>
                    <TableFooter style={{float:'right'}}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]} colSpan={headCells.length + 1}
                                count={tableData.length}
                                 rowsPerPage={rowsPerPage}
                                  page={page}
                                SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}

                                 onPageChange={(_event, newPage) => setPage(newPage)}
                                 onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
        </Paper >
    );
}

function VpvTableFilter({ routeType, useMemberFilter, useVendorFilter }) {
    switch (routeType) {
        case 'MEMBER':
            return <VendorSelectionButton useMemberFilter={useMemberFilter} />;
        case 'VENDOR':
            return <MemberSelectionButton useVendorFilter={useVendorFilter} />;
        default:
            return (
                <React.Fragment>
                    <VendorSelectionButton useMemberFilter={useMemberFilter} />
                    <MemberSelectionButton useVendorFilter={useVendorFilter} />
                </React.Fragment>
            );
    }
}

function LoadingProgress({ loading }) {
    return loading ? (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    ) : null
}