import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SIGNIN, SIGNOUT } from '../../middleware/authentication';
import { getBearerToken, ifBearerTokenExists } from '../../utils/storage';

/**
 * Homepage Component for BLUE HAWK dashboard application. 
 * The component will render rest routes only when the user is authenticated, else user will be redirected to Login Page.
 * All Dashboard Components will be rendred inside BluehawkHome component.
 * @returns 
 */
export default function BluehawkHome() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    /**
     * One time call when the dashboard home page is loaded. Performs following taks:
     * 1. Sets jwt token globally in axios.
     * 2. Gets login details.
     */
    useEffect(() => {
        if (ifBearerTokenExists()) {
            axios.defaults.headers.common['Authorization'] = getBearerToken();
            // Redirect for Login Page if any API returns 401 Unauthorized Response.
            axios.interceptors.response.use(response => response, error => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type: SIGNOUT });
                } else {
                    throw error;
                }
            });
            axios.get('/user-detail').then(response => dispatch({ type: SIGNIN, payload: response.data })).catch(error => {
                dispatch({ type: SIGNOUT });
                navigate('/login', { state: { from: location }, replace: true });
            });
        } else {
            navigate('/login', { state: { from: location }, replace: true });
        }
    }, []);

    /**
     * Redirect user to default location after login details change and only when user is on root URL.
     */
    useEffect(() => {
        if (auth.id && auth.userType === 'VENDOR' && !location.pathname.startsWith('/vendor')) {
            navigate(generatePath('/vendor/:groupIds', { groupIds: auth.branches }), { state: { from: location }, replace: true });
        } else if (auth.id && auth.userType === 'MEMBER' && !location.pathname.startsWith('/member')) {
            navigate(generatePath('/member/:groupIds', { groupIds: auth.branches }), { state: { from: location }, replace: true });
        } else if (auth.id && auth.userType === 'CORPORATE' && location.pathname === '/') {
            navigate('/admin', { state: { from: location }, replace: true });
        }
    }, [auth]);


    if (auth.id && auth.id > 0) {
        return <Outlet />;
    } else {
        return (
            <Backdrop invisible sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color='secondary' variant="indeterminate" size={100} thickness={2} />
            </Backdrop>
        );
    }
}
