import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modifyFiltersToOldFormat } from '../../../middleware/helper';
import OverviewCard from '../../common/overview-card';

const defaultSalesData = [
    { total: 0, cardName: "Vendor Partner Sales (VP Verified Data)", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Vendor Partner Rebate (VP Verified Data)", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Vendor Partner Sales (VP Transactional Data)", yoy: 0, updatedDate: null },
    { total: 0, cardName: "Vendor Partner Rebate (VP Transactional Data)", yoy: 0, updatedDate: null }
];

export default function VendorOverviewVerifiedSummary() {

    const { period, year, month, quarter, vendorIds } = useSelector(state => state.filter);
    const [rebateList, setRebateList] = useState([]);
    const [rebateType, setRebateType] = useState(0);
    const [salesData, setSalesData] = useState(defaultSalesData);

    useEffect(() => {
        if (year && vendorIds && vendorIds.length > 0) {
            const params = { year, vendorId: vendorIds.map(row => row.dbaId) };
            Promise.all([
                axios.post('/api/vendor/overview/getOverviewRebateTypeNames', params)
            ]).then((values) => {
                setRebateList(values[0].data);
            });
        }
    }, [year,vendorIds]);


    useEffect(() => {
        let params = modifyFiltersToOldFormat({ period, year, month, quarter, vendorId: vendorIds.map(row => row.dbaId) });
        if (rebateType != "") {
            params.dropDown = [{ rebate: [rebateType] }];
        } else {
            params.dropDown = [];
        }
        axios.post('/api/vendor/overview/getOverviewRebateCards', { ...params }).then(response => setSalesData(response.data));
    }, [year, month, period, quarter, vendorIds, rebateType]);


    const setRebate = (val) => {
        if (val == 'Guaranteed') {
            setRebateType('Quarterly Flat')
        }
        else {
            setRebateType(val)

        }
    }

    return (
        <Paper elivation={1} sx={{ p: 2, mb: 2 }}>
            <Toolbar sx={{ mb: 1 }} disableGutters>
                <Typography variant='h6' sx={{ mr: 3 }}>Overall Volume and Rebate (VP Data)</Typography>
                <Stack direction='row' spacing={2}>
                    <FormControl>
                        <InputLabel>Select Program Type</InputLabel>
                        <Select
                            labelId="rebate-name-select-small"
                            id="rebate-name-select-small"
                            value={rebateType}
                            label="Select Program Type"
                            sx={{ minWidth: 250 }}
                            onChange={(event) => setRebate(event.target.value)}
                            size='small'
                        >
                            <MenuItem value={0} selected>All</MenuItem>
                            {rebateList.map((rebate, index) => <MenuItem key={index}
                                value={rebate.rebateTypeName}>{rebate.rebateTypeName === 'Quarterly Flat' ? 'Guaranteed' : rebate.rebateTypeName}</MenuItem>)}
                                {/* value={rebate.rebateTypeName}>{rebate.rebateTypeName }</MenuItem>)}  */}
                        </Select>
                    </FormControl>
                </Stack>
            </Toolbar>
            <Grid container spacing={2} columns={salesData.length}>
                {salesData.map(card => (
                    <Grid item xs={1} key={'sales-item-' +
                    card.cardName == "Vendor Partner Sales (VP Quarterly Data)"?'Vendor Partner Sales (VP Verified Data)':
                    card.cardName == "Vendor Partner Rebate (VP Quarterly Data)"?'Vendor Partner Rebate (VP Verified Data)':
                    card.cardName == "Vendor Partner Sales (VP Transactional Data)"?'Vendor Partner Sales (VP Trans Data)':
                    card.cardName == 'Vendor Partner Rebate (VP Transactional Data)'?'Vendor Partner Rebate (VP Trans Data)':card.cardName}>
                        <OverviewCard value={card.total} yoy={card.yoy} updateDate={card.updateDate} title={ card.cardName == "Vendor Partner Sales (VP Quarterly Data)"?'Vendor Partner Sales (VP Verified Data)':
                    card.cardName == "Vendor Partner Rebate (VP Quarterly Data)"?'Vendor Partner Rebate (VP Verified Data)':
                    card.cardName == "Vendor Partner Sales (VP Transactional Data)"?'Vendor Partner Sales (VP Trans Data)':
                    card.cardName == 'Vendor Partner Rebate (VP Transactional Data)'?'Vendor Partner Rebate (VP Trans Data)':card.cardName} />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}
