import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import HideOnScroll from './hide-on-scroll';

export default function SecondaryAppBar({ children }) {
    
    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar sx={{ bgcolor: 'white', top: 48, color: '#000' }} elevation={1}>
                    {children}
                </AppBar>
            </HideOnScroll>
            <Toolbar />
        </React.Fragment>
    );
}