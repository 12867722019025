import FilterListIcon from '@mui/icons-material/FilterList';
import { Avatar, Button, Card, Checkbox, Fade, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Paper, Popper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_REBATE_TYPES, SET_SOURCES } from '../../../middleware/filter';

export default function VpvTableFilterButton({ rebateList, sourceList }) {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedProgramTypes, setSelectedProgramTypes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const { rebateTypes } = useSelector(state => state.filter);

    useEffect(() => {
        setSelectedProgramTypes(rebateTypes);
    }, [rebateTypes]);

    const handleProgramTypeToggle = (value) => {
    //     if(value == 'Guaranteed')
    //     {
    //      //setSelectedProgramTypes("Quarterly Flat");
    //     const currentIndex = selectedProgramTypes.indexOf(value);
    //     const newChecked = [...selectedProgramTypes];
        
    //     if (currentIndex === -1) {
    //         newChecked.push(value);
    //     } else {
    //         newChecked.splice(currentIndex, 1);
    //     }

    //     var array2 = newChecked;
    //     const currentIndex111 = newChecked.indexOf('Guaranteed');

    //     function update(array, index, newValue) {
    //         array[index] = newValue;
    //       }
          
    //       let newVal = "Quarterly Flat";
    //       update(array2, currentIndex111, newVal);
    //       console.log(newChecked,"newCheckednewCheckednewChecked")
  
    //     setSelectedProgramTypes(newChecked);
    // }
    // else {
        const currentIndex = selectedProgramTypes.indexOf(value);
        const newChecked = [...selectedProgramTypes];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedProgramTypes(newChecked);

    //}
    };

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleConfirm = () => {
        dispatch({ type: SET_REBATE_TYPES, payload: selectedProgramTypes });
        dispatch({ type: SET_SOURCES, payload: checked });
        setOpen(false);
    };

    const handleCancel = () => {
        setSelectedProgramTypes(rebateTypes);
        setOpen(false);
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick('bottom-end')} sx={{ mr: 2 }}><FilterListIcon /></IconButton>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 2 }}>
                            <Stack direction='row' spacing={2}>
                                <Card variant='outlined'>
                                    <List sx={{ width: '100%', maxWidth: 700, minWidth: 350, overflow: 'auto', maxHeight: 500, bgcolor: 'background.paper' }}
                                        subheader={<ListSubheader component="div" 
                                        id="nested-list-subheader">Select Program Type
                                        </ListSubheader>}>
                                        {rebateList.map((rebate) => {
                                            const labelId = `checkbox-list-label-${rebate}`;
                                            return (
                                                <ListItem key={labelId} disablePadding secondaryAction={<Checkbox edge="end"
                                                 onChange={() => handleProgramTypeToggle(rebate)} 
                                                 checked={selectedProgramTypes.indexOf(rebate) !== -1} 
                                                 inputProps={{ 'aria-labelledby': labelId }} />}>
                                                    <ListItemButton role={undefined} onClick={() => handleProgramTypeToggle(rebate)} dense>
                                                        <ListItemAvatar><Avatar>PT</Avatar></ListItemAvatar>
                                                        <ListItemText id={labelId} primary={rebate === 'Quarterly Flat' ? 'Guaranteed':rebate} />
                                                        {/* <ListItemText id={labelId} primary={rebate} /> */}
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Card>
                                <Card variant='outlined'>
                                    <List sx={{ width: '100%', maxWidth: 700, minWidth: 300, overflow: 'auto', maxHeight: 500, bgcolor: 'background.paper' }}
                                        subheader={<ListSubheader component="div" id="nested-list-subheader">Select Source</ListSubheader>}>
                                        {sourceList.map((source) => {
                                            const labelId = `checkbox-list-label-${source}`;
                                            return (
                                                <ListItem key={labelId} disablePadding secondaryAction={<Checkbox edge="end" onChange={() =>
                                                 handleToggle(source)} checked={checked.indexOf(source) !== -1} inputProps={{ 'aria-labelledby': source }} />}>
                                                    <ListItemButton role={undefined} onClick={() => handleToggle(source)} dense>
                                                        <ListItemAvatar><Avatar>DS</Avatar></ListItemAvatar>
                                                        <ListItemText id={labelId} primary={source == "Member" ? 'Member Trans Data' :
                                                         source == "VP Quarterly Data"?'VP Verified Data':source == 'VP Transaction Data'?'VP Trans Data':source} />
                                                          {/* <ListItemText id={labelId} primary={source} /> */}
                                                    </ListItemButton> 
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Card>
                            </Stack>
                            <Stack direction='row' spacing={2} sx={{ mt: 2, justifyContent: 'end' }}>
                                <Button variant='contained' onClick={handleCancel}>Cancel</Button>
                                <Button variant='contained' onClick={handleConfirm}>Apply</Button>
                            </Stack>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </React.Fragment >
    );
}